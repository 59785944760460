import { authInstance } from "../shared/Request";
import * as Sentry from "@sentry/react";

export const fetchHome = async () => {
  try {
    const response = await authInstance.get("/member/home");
    //console.log("fetchHome success: ", response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.clear();
      return null;
    } else {
      Sentry.captureException(error);
      console.clear();
    }
  }
};

export const fetchProfile = async () => {
  return await authInstance.get("/member/profile").then((res) => res.data);
};
export const patchProfile = async (newUserInfo) => {
  return await authInstance
    .patch(`member/profile`, newUserInfo)
    .then((res) => res.data);
};

export const getSetting = async () => {
  try {
    const response = await authInstance.get("member/setting");
    //console.log("getSetting", response.data);

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    //console.error("Error get setting data:", error);
  }
};

//patch Subscribe
export const patchSubscribeSetting = async (subscribe) => {
  try {
    const response = await authInstance.patch("member/setting/subscribe", {
      subscribe,
    });
    console.log("patchSubscribeSetting", response);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    //console.error("Error updating subscribe setting:", error);
  }
};
