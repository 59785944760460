import React from "react";
import styled from "@emotion/styled";
import { copyToClipboard } from "../../utils/copyToClipboard";
import copyIcon from "../../assets/icons/Copy_alt.svg";
import closeIcon from "../../assets/icons/closeIcon.svg";

export const AddressCard = ({
  addressName,
  roadAddressName,
  setToastMessage,
  setAddressIsOpen,
}) => {
  return (
    <CardWrapper>
      <StoreContentDiv>
        <SquareBox>
          <p>도로명</p>
        </SquareBox>
        {roadAddressName !== "" ? (
          <>
            <StoreContent>{roadAddressName}</StoreContent>
            <CopyIcon
              onClick={() => copyToClipboard(roadAddressName, setToastMessage)}
              src={copyIcon}
              alt="copyIcon"
            />
          </>
        ) : (
          <StoreContent>정보가 없습니다</StoreContent>
        )}
      </StoreContentDiv>
      <StoreContentDiv>
        <SquareBox>
          <p>지번</p>
        </SquareBox>
        {addressName !== "" ? (
          <>
            <StoreContent>{addressName}</StoreContent>
            <CopyIcon
              onClick={() => copyToClipboard(addressName, setToastMessage)}
              src={copyIcon}
              alt="copyIcon"
            />
          </>
        ) : (
          <StoreContent>정보가 없습니다</StoreContent>
        )}
      </StoreContentDiv>
      <CloseBtn>
        <img
          src={closeIcon}
          alt="close"
          onClick={() => setAddressIsOpen(false)}
        />
      </CloseBtn>
    </CardWrapper>
  );
};

const CloseBtn = styled.button`
  position: absolute;
  top: 11px;
  right: 11px;
  img {
    opacity: 0.3;

    &:hover {
      opacity: 0.2;
      transition: 0.2s;
      cursor: pointer;
    }
  }
`;
const SquareBox = styled.div`
  width: 33px;
  height: 12px;
  border: 1px solid var(--gray, #aaa);
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: var(--gray, #aaa);
    font-family: "Wanted Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const CardWrapper = styled.div`
  width: 242px;
  height: 50px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 40px 6px 11px;
  overflow: hidden;
`;

const StoreContentDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 7px;
  margin-bottom: 8px;
`;

const StoreContent = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const CopyIcon = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 80%;
  }
`;
