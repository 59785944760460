import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThumUpBtn } from "../ThumUpBtn";
import { authInstance } from "../../shared/Request";
import { useNavigate } from "react-router-dom";

import CountryFlag from "react-country-flag";
import { breakpoints } from "../../styles/breakpoints";
import DeleteReviewNoti from "../notification-popups/DeleteReviewNoti";

export const ReviewCard = ({
  review,
  setReviews,
  isMypage,
  setIsEditing,
  setEditingReviewId,
}) => {
  const navigate = useNavigate();
  const [isMyReview, setIsMyReview] = useState(false);
  const [isImgReview, setIsImgReview] = useState("");

  const [isDeletePopupVisible, setisDeletePopupVisible] = useState(false);
  const [areYouDelete, setAreYouDelete] = useState(false);

  useEffect(() => {
    if (review.reviewReader.memberId === review.reviewWriter.memberId) {
      setIsMyReview(true);
    }
    if (review.reviewContent.imgLink !== "") {
      setIsImgReview(review.reviewContent.imgLink);
    }
    if (areYouDelete) {
      handleDeleteReview();
      setAreYouDelete(false);
    }
  }, [review, areYouDelete]);

  const handleDeletePopUp = () => {
    setisDeletePopupVisible(true);
  };
  const handleDeleteReview = async () => {
    try {
      const response = await authInstance.delete(
        `/member/reviews/${review.reviewContent.domainReviewId}`
      );

      console.log(review.reviewContent.domainReviewId, "Review 삭제완료");
      setReviews((prevReviews) => {
        return prevReviews.filter(
          (prevReview) =>
            prevReview.reviewContent.domainReviewId !==
            review.reviewContent.domainReviewId
        );
      });
    } catch (error) {
      //("Error sending Delete Favorite Food to server:", error);
    }
  };

  const handleUpdateReview = () => {
    if (!isMypage) {
      navigate("update", {
        state: { domainReviewId: review.reviewContent.domainReviewId },
      });
    } else {
      setEditingReviewId([
        review.reviewContent.domainId,
        review.reviewContent.domainReviewId,
      ]);
      setIsEditing(true);
    }
  };
  return (
    <Container>
      {isDeletePopupVisible && (
        <DeleteReviewNoti
          onClose={() => setisDeletePopupVisible(false)}
          setAreYouDelete={setAreYouDelete}
        />
      )}

      <ReviewWriter>
        <UserProfileImg>
          <UserNationality>
            {review.reviewWriter.nationality === "NA" ? null : (
              <CountryFlag
                countryCode={review.reviewWriter.nationality}
                svg
                style={{
                  height: 12,
                  borderRadius: 2,
                  boxShadow: "0.5px 1px 1px 0px rgba(0, 0, 0, 0.15)",
                }}
              />
            )}
          </UserNationality>
          <img src={review.reviewWriter.profileCharacter} alt="profile-img" />
        </UserProfileImg>
        <UserProfile>
          <Nickname>{review.reviewWriter.memberNickname}</Nickname>
          <div>
            <ReviewCount>
              {review.reviewWriter.reviewNumberOfMember + " reviews"}
            </ReviewCount>
            <p className="middle-point">·</p>
            <Date>{review.reviewContent.reviewDate}</Date>
          </div>
        </UserProfile>
        {isMyReview ? (
          <EditBtnContainer>
            <DeleteBtn onClick={handleDeletePopUp}>delete</DeleteBtn>
            <UpdateBtn onClick={handleUpdateReview}>update</UpdateBtn>
          </EditBtnContainer>
        ) : null}
      </ReviewWriter>

      <ReviewContent>
        {isImgReview ? (
          <ReviewImgContainer
            img={review.reviewContent.imgLink}
          ></ReviewImgContainer>
        ) : null}

        <Comment>
          {isMypage ? (
            <FoodName>
              <p>{review.reviewContent.domainName}</p>
            </FoodName>
          ) : null}

          <p>{review.reviewContent.comment}</p>
        </Comment>
        <KeywordBox>
          {review.reviewContent.memberSelectTags.map((tag) => (
            <Keyword key={review.reviewContent.domainReviewId + tag.tagValue}>
              {tag.emoji + tag.tagValue}
            </Keyword>
          ))}
        </KeywordBox>

        {/* 모바일 키워드 제한 */}
        <KeywordBoxMobile>
          {review.reviewContent.memberSelectTags.slice(0, 2).map((tag) => (
            <Keyword key={review.reviewContent.domainReviewId + tag.tagValue}>
              {tag.emoji + tag.tagValue}
            </Keyword>
          ))}
          {review.reviewContent.memberSelectTags.length <= 2 ? null : (
            <ExtraCount>
              +{review.reviewContent.memberSelectTags.length - 2}
            </ExtraCount>
          )}
        </KeywordBoxMobile>
        <ThumUpBtn
          review={review}
          ReaderThumbUpId={review.reviewReader.thumbUpId}
        />
      </ReviewContent>
    </Container>
  );
};
const UserNationality = styled.div`
  position: absolute;
  z-index: 2;
  left: 26px;
  bottom: -3px;
  transform: scale(0.7);
`;
const FoodName = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14.99px;
  border: 1px solid var(--light-gray, #d3d3d3);
  background: #fff;
  margin: 0 5px 0 0;
  p {
    color: #000;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const ReviewImgContainer = styled.div`
  width: 252px;
  height: 160px;
  background-image: url(${(props) => `${props.img}`});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 8px;
`;

const DeleteBtn = styled.button`
  display: inline-flex;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: #e7e7e7;
  margin-left: 5px;

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;
const UpdateBtn = styled(DeleteBtn)``;
const EditBtnContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 4px;
`;
const Container = styled.div`
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

const Keyword = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 6px;
  align-items: center;
  border-radius: 3px;
  background: #e7e7e7;
  margin-right: 5px;

  font-family: "Wanted Sans";
  color: var(--tag, #7a7a7a);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    color: var(--tag, #6e6e6e);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    transition: ease-in-out 0.2s;
  }
`;

const ExtraCount = styled(Keyword)`
  padding: 4px 5px 4px 5px;
  cursor: pointer;
`;
const KeywordBox = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;
const KeywordBoxMobile = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    margin-top: 5px;
    align-items: center;
  }
`;

const ReviewContent = styled.div`
  position: relative;
`;
const UserProfile = styled.div`
  width: 300px;

  position: relative;
  div {
    display: flex;
    align-items: center;
  }
  line-height: 10px;
  .middle-point {
    font-size: 18px;
    color: #000000;
    margin: 0 5px;
  }
`;

const UserProfileImg = styled.div`
  background-color: #ffffff;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  margin-right: 10px;
  overflow: hidden;
  border: 1px solid var(--light-gray, #d3d3d3);
  img {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    margin-top: 3px;
    transform: scale(1.4);
    overflow: hidden;
  }
`;
const ReviewWriter = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
`;

const Nickname = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const ReviewCount = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Date = styled(ReviewCount)``;

const Comment = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  height: fit-content;
  padding: 4px 0;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: normal; /* Ensures text wraps to the next line */
    word-wrap: break-word; /* Breaks long words to fit within the container */
    word-break: break-all;
  }
`;
