import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";

import { useSelector } from "react-redux";
import { getNickname } from "./modules/user";

// ** page ** //
import Login from "./pages/login/Login";
import SocialLoginCallback from "./pages/login/callback/SocialLoginCallback";
import SocialSignUpCallback from "./pages/login/callback/SocialSignUpCallback";

import { Main } from "./pages/main/Main";
import { KfoodGuide } from "./pages/k-food/KfoodGuide";
import { KplaceGuide } from "./pages/k-place/KplaceGuide";
import { NearbyStore } from "./pages/nearby-store/NearbyStore";
import { DetailPage } from "./pages/detail-page/DetailPage";
//import CountrySeletor from "./pages/detail-page/CountrySeletor";

import { Mypage } from "./pages/mypage/Mypage";
import { ProfileEdit } from "./pages/mypage/ProfileEdit";
import { AccountDelete } from "./pages/mypage/AccountDelete";
import { NicknameEdit } from "./pages/mypage/profile-edit-components/NicknameEdit";
import { NationalityEdit } from "./pages/mypage/profile-edit-components/NationalityEdit";
import { ProfileImgEdit } from "./pages/mypage/profile-edit-components/ProfileImgEdit";

import { FavoriteFoods } from "./pages/favorite/FavoriteFoods";
import { FavoritePlaces } from "./pages/favorite/FavoritePlaces";
import { FavoriteReviews } from "./pages/favorite/FavoriteReviews";
import { MyReviews } from "./pages/mypage/MyReviews";

import ProtectedLayout from "./ProtectedLayout";
import DeletedMember from "./pages/login/callback/DeletedMember";
import SignupTypeSelection from "./pages/login/SignupTypeSelection";
import SignupProfileDetails from "./pages/login/SignupProfileDetails";
import SignupCredentials from "./pages/login/SignupCredentials";
import PasswordManager from "./pages/mypage/change-password/PasswordManager";
import ResetPassword from "./pages/login/ResetPassword.jsx/ResetPassword";
import Setting from "./pages/mypage/Setting";

//import TTest from "./TTest";

const AppRoutes = () => {
  const nickname = useSelector(getNickname);

  const isAuthorized = () => {
    if (nickname) return true;
    else return false;
  };

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* main domain */}
        <Route path="/" element={<Main />} />
        <Route path="/k-food-guide" element={<KfoodGuide />} />
        <Route path="/k-place-guide" element={<KplaceGuide />} />

        {/* my */}
        <Route element={<ProtectedLayout />}>
          <Route
            path="/mypage"
            element={isAuthorized() ? <Mypage /> : <Navigate to="/" replace />}
          />
          <Route path="/mypage/profile-edit" element={<ProfileEdit />} />
          <Route path="/mypage/setting" element={<Setting />} />
          <Route
            path="/mypage/profile-edit/delete"
            element={
              isAuthorized() ? <AccountDelete /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/mypage/profile-edit/nickname/:nickname"
            element={<NicknameEdit />}
          />
          <Route
            path="/mypage/profile-edit/nationality/:nationality"
            element={<NationalityEdit />}
          />
          <Route
            path="/mypage/profile-edit/profile-img"
            element={<ProfileImgEdit />}
          />

          <Route path="/mypage/favorite/foods" element={<FavoriteFoods />} />
          <Route path="/mypage/favorite/places" element={<FavoritePlaces />} />
          <Route
            path="/mypage/favorite/reviews"
            element={<FavoriteReviews />}
          />
          <Route path="/mypage/reviews/*" element={<MyReviews />} />
          <Route
            path="/mypage/profile-edit/change-password"
            element={<PasswordManager />}
          />
        </Route>

        {/* auth */}
        <Route path="/auth/sign-in" element={<Login />} />
        <Route path="/auth/sign-up" element={<SignupTypeSelection />} />
        <Route path="/auth/sign-up/email" element={<SignupCredentials />} />
        <Route path="/auth/sign-up/:code" element={<SignupProfileDetails />} />
        <Route
          path="/auth/callback/sign-in"
          element={<SocialLoginCallback />}
        />
        <Route
          path="/auth/callback/sign-up"
          element={<SocialSignUpCallback />}
        />
        <Route
          path="/auth/callback/deleted-member"
          element={<DeletedMember />}
        />
        <Route path="/auth/reset-password" element={<ResetPassword />} />

        {/* detail, map */}
        <Route path="/:domain/:domainId/*" element={<DetailPage />} />
        <Route path="/foods/:id/nearby" element={<NearbyStore />} />

        {/* test 
        <Route path="/tt" element={<CountrySeletor />} />
        <Route path="/dashboard/*" element={<TTest />} />
        */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
