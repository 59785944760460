import React from "react";
import styled from "@emotion/styled";
import { breakpoints } from "../styles/breakpoints";

const ToggleSwitch = styled.div`
  width: 55px;
  height: 30px;
  border-radius: 25px;
  background-color: ${(props) => (props.isActive ? "#90a9f4" : "#ccc")};
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  @media (max-width: ${breakpoints.mobile}) {
    width: 36px;
    height: 20px;
  }
`;

const ToggleCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.isActive ? "calc(100% - 26px)" : "6px")};
  transition: left 0.3s ease;
  @media (max-width: ${breakpoints.mobile}) {
    width: 13.5px;
    height: 13.5px;

    left: ${(props) => (props.isActive ? "calc(100% - 18px)" : "5px")};
  }
`;

const EmailToggle = ({ isActive, setIsActive }) => {
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <ToggleSwitch isActive={isActive} onClick={handleToggle}>
      <ToggleCircle isActive={isActive} />
    </ToggleSwitch>
  );
};

export default EmailToggle;
