import React from "react";
import styled from "@emotion/styled";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryFlag from "react-country-flag";
import { breakpoints } from "../styles/breakpoints";

export const SignUpCountrySelector = ({ country, setCountry }) => {
  const options = countryList().getData();

  const customSingleValue = ({ data }) => (
    <CustomSingleValueConatiner>
      <CountryFlag countryCode={data.value} svg style={{ marginRight: 10 }} />
      <SelectCountryText>{data.label}</SelectCountryText>
    </CustomSingleValueConatiner>
  );

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <>
        <SelectCountryItemContainer
          ref={innerRef}
          {...innerProps}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CountryFlag
            countryCode={data.value}
            svg
            style={{ width: 26, marginRight: 10, borderRadius: 2 }}
          />
          <SelectCountryText>{data.label}</SelectCountryText>
        </SelectCountryItemContainer>

        <Divider></Divider>
      </>
    );
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      background: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected ? "#ddd" : "white",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#AAA",
      fontFamily: "Wanted Sans",
      fontSize: "12px",
      fontWeight: "400",
    }),
  };
  return (
    <Select
      className="country-select"
      options={options}
      value={country}
      onChange={setCountry}
      components={{
        SingleValue: customSingleValue,
        Option: customOption,
      }}
      label="country"
      placeholder="Select nationality"
      styles={customStyles}
    ></Select>
  );
};

const CustomSingleValueConatiner = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  margin-left: 8px;
`;
const Divider = styled.div`
  width: 400px;
  height: 0.5;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;
const SelectCountryItemContainer = styled.div`
  padding: 13px 16px;
  &:hover {
    background-color: #efefef;
    transition: ease-in-out 0.2s;
  }
`;

const SelectCountryText = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
