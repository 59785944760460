import React, { useState } from "react";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TryAgin from "../../components/notification-popups/TryAgin";
import { defaultInstance } from "../../shared/Request";
import { setNickname } from "../../modules/user";

import showPasswordIcon from "../../assets/icons/show-password.svg";
import hidePasswordIcon from "../../assets/icons/hide-password.svg";
import InputLael from "./InputComponents/InputLabel";
import { breakpoints } from "../../styles/breakpoints";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [isPopupVisible, setPopupVisible] = useState(false);

  //이메일 형식 검증
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  //비밀번호 형식 검증
  const validatePassword = (password) => {
    const minLength = 8;
    const errors = [];

    if (password.length < minLength) {
      errors.push(
        `The password must be at least ${minLength} characters long.`
      );
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("The password must contain at least one special character.");
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email format.";
    }

    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      // 첫 번째 비밀번호 오류만 저장
      newErrors.password = passwordErrors[0];
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // 유효성 검사가 모두 통과하면 로그인 로직 실행
      //로그인 요청
      postEmailLogIn();
    }
  };

  //로그인 요청
  const postEmailLogIn = async () => {
    try {
      const response = await defaultInstance.post("email-login", {
        email: email,
        password: password,
      });
      console.log(response.data);

      dispatch(setNickname(response.data.nickname));
      console.log("이미 가입된 회원 로그인 완료");
      navigate("/");
    } catch (error) {
      if (error.response) {
        const newErrors = {};
        const { detail, status } = error.response.data;
        //console.error(`Error during sign-in: ${detail} (Status: ${status})`);

        if (status === 400) {
          newErrors.login = "The email or password is incorrect.";
          setErrors(newErrors);
        } else {
          newErrors.login = "Please try again.";
          setErrors(newErrors);
        }
      }
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {isPopupVisible && <TryAgin onClose={() => setPopupVisible(false)} />}

      <InputLael id="email" errors={errors} />
      <InputContainer>
        <Input
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          minLength={1}
          maxLength={256}
        />
      </InputContainer>

      <InputLael id="password" errors={errors} />

      <InputContainer>
        <Input
          id="password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={1}
          maxLength={32}
        />
        <button
          type="button"
          onMouseDown={() => setShowPassword(true)}
          onMouseUp={() => setShowPassword(false)}
          onMouseLeave={() => setShowPassword(false)}
        >
          <ShowPasswordIcon>
            {showPassword ? (
              <img src={showPasswordIcon} alt="show password" />
            ) : (
              <img src={hidePasswordIcon} alt="show password" />
            )}
          </ShowPasswordIcon>
        </button>
      </InputContainer>
      {errors.login && <ErrorMessage>{errors.login}</ErrorMessage>}

      <ResetPassword onClick={() => navigate("/auth/reset-password")}>
        Forgot Password?
      </ResetPassword>
      <LoginBtn type="submit">
        <p>Log in</p>
      </LoginBtn>
    </FormWrapper>
  );
};

const ShowPasswordIcon = styled.div`
  margin-right: 10px;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobile}) {
  }
`;
const ResetPassword = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin: 10px 0;
  cursor: pointer;
`;
const ErrorMessage = styled.p`
  width: 300px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
`;

const LoginBtn = styled.button`
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 5px;
  font-size: 16px;
  ::placeholder {
    color: #888;
    font-family: "Wanted Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }
`;
const InputContainer = styled.div`
  position: relative;
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
export default LoginForm;
