import React from "react";
import styled from "@emotion/styled";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_upper_case.svg";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getNickname, setNickname } from "../modules/user";
import Loading from "./Loading";
import { breakpoints } from "../styles/breakpoints";

import food from "../assets/icons/main-food.svg";
import place from "../assets/icons/main-place.svg";
import home from "../assets/icons/main-home.png";
import mypage from "../assets/icons/main-mypage.png";

import defaultProfile from "../assets/images/default-profile.png";
import { authInstance } from "../shared/Request";

import * as Sentry from "@sentry/react";

const icons = [
  {
    icon: home,
    text: "HOME",
    url: "/",
  },
  {
    icon: food,
    text: "K-FOOD",
    url: "/k-food-guide",
  },
  {
    icon: place,
    text: "K-PLACE",
    url: "/k-place-guide",
  },
  {
    icon: mypage,
    text: "MY PAGE",
    url: "/mypage",
  },
];

const Header = () => {
  const navigate = useNavigate();
  const nickname = useSelector(getNickname);

  const dispatch = useDispatch();

  const fetchHome = async () => {
    try {
      const response = await authInstance.get("/member/home");
      //console.log("fetchHome success: ", response);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(setNickname(null));
        console.clear();
        return null;
      } else {
        console.clear();
        Sentry.captureException(error);
      }
    }
  };

  const { data: homeData, isLoading } = useQuery(["home"], fetchHome, {
    retry: 1,
    staleTime: 100000, // Data is considered fresh for 1 minute
    cacheTime: 100000, // Cache data for 1 minute before garbage collection
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Wrapper>
            <IconContainer>
              <Link to="/" className="logo">
                <KoriLogo src={logo} />
              </Link>
            </IconContainer>
            <LinkContainer>
              <ul className="nav__links">
                <li>
                  <NavLink
                    to={"/"}
                    className={({ isActive }) => (isActive ? "active-nav" : "")}
                  >
                    <p>Home</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/k-food-guide"}
                    className={({ isActive }) => (isActive ? "active-nav" : "")}
                  >
                    <p>K-Food Guide</p>
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink
                    to={"/k-place-guide"}
                    className={({ isActive }) => (isActive ? "active-nav" : "")}
                  >
                    <p>K-Place Guide</p>
                  </NavLink>
                </li>
              </ul>
              <VerticalLine className="line"></VerticalLine>

              {nickname ? (
                <NavLink to={"/mypage"} className="login">
                  <ImgBox>
                    {homeData ? (
                      <img src={homeData.profileCharacter} alt="kori" />
                    ) : (
                      <img src={defaultProfile} alt="kori" />
                    )}
                  </ImgBox>
                </NavLink>
              ) : (
                <LoginBtn onClick={() => navigate("/auth/sign-in")}>
                  Log in
                </LoginBtn>
              )}
            </LinkContainer>
          </Wrapper>
          <BottomHeader>
            {icons.map((i) => {
              return (
                <NavLink to={i.url} key={i.text}>
                  <BottomIconBox>
                    <img src={i.icon} alt={i.text} />
                    <BottomIconText>{i.text}</BottomIconText>
                  </BottomIconBox>
                </NavLink>
              );
            })}
          </BottomHeader>
        </div>
      )}
    </>
  );
};

const BottomHeader = styled.div`
  display: none;
  width: 100vw;
  position: fixed;
  bottom: 0px;
  padding: 12px 12px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 999;
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }
`;

const BottomIconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BottomIconText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ImgBox = styled.div`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: var(--Number, 35px);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  img {
    width: 35px;
    transform: scale(1.6);
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 14px;
  border-left: 1px solid;
  background-color: antiquewhite;
  margin: 0 30px 0 30px;
`;

const LoginBtn = styled.button`
  background-color: black;
  padding: 8px 14px;
  border-radius: 30px;
  color: white;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  position: fixed;
  top: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  @media (max-width: ${breakpoints.mobile}) {
    background: rgba(255, 255, 255, 1);
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 0 100px;
  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 0 0 24px;
  }
`;

const KoriLogo = styled.img`
  width: 74px;
  margin-right: 10px;
`;

const LinkContainer = styled.div`
  margin: 0 100px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  li {
    p {
      font-size: 16px;
      transition: 0.3s;
      color: black;

      &:hover {
        font-size: 17px;
        transition: 0.3s;
      }
    }

    .active-nav {
      p {
        font-weight: 700;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    ul {
      display: none; /* Hide the navigation links on mobile */
    }
    .line {
      display: none;
    }
    .login {
      display: flex; /* Ensure the login button or profile image remains visible */
    }
    margin: 0 40px 0 0;
  }
`;

export default Header;
