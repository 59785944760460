import React from "react";
import styled from "@emotion/styled";
import { breakpoints } from "../styles/breakpoints";

export const Footer = () => {
  return <Wrapper>ⓒ KORI all rights reserved.</Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #000;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    height: 70px;
  }
`;
