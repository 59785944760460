import React from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import CountryFlag from "react-country-flag";
import styled from "@emotion/styled";

const CountrySeletor = ({ country, setCountry }) => {
  const options = [{ value: "NA", label: "None" }, ...countryList().getData()];

  const customSingleValue = ({ data }) => (
    <CustomSingleValueConatiner>
      {data.value !== "NA" && (
        <CountryFlag countryCode={data.value} svg style={{ marginRight: 6 }} />
      )}
      {data.label}
    </CustomSingleValueConatiner>
  );

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <>
        <SelectCountryItemContainer
          ref={innerRef}
          {...innerProps}
          style={{ display: "flex", alignItems: "center" }}
        >
          {data.value !== "NA" && (
            <CountryFlag
              countryCode={data.value}
              svg
              style={{ width: 26, marginRight: 10, borderRadius: 2 }}
            />
          )}
          <SelectCountryText>{data.label}</SelectCountryText>
        </SelectCountryItemContainer>
        <Divider></Divider>
      </>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "155px",
      border: "1px solid #90A9F4",
      background: "none",
      height: "30px",
      minHeight: "0px",
      padding: "0px 0px 5px 0px",
      fontSize: "14px",
      alignItems: "start",
      "&:focus": {
        border: "1px solid #90A9F4",
      },
      "&:active": {
        border: "1px solid #90A9F4",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      backgroundColor: state.isSelected ? "#ddd" : "white",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#AAA",
      fontFamily: "Wanted Sans",
      fontSize: "14px",
      fontWeight: "500",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      alignItems: "flex-start", // 위쪽으로 정렬
      padding: 0, // 불필요한 여백 제거
    }),
  };

  return (
    <Select
      className="country-select"
      options={options}
      value={country}
      onChange={setCountry}
      components={{
        SingleValue: customSingleValue,
        Option: customOption,
      }}
      label="country"
      styles={customStyles}
      placeholder="Select country"
    />
  );
};

const CustomSingleValueConatiner = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 8px;
`;

const SelectCountryText = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Divider = styled.div`
  width: 120px;
  height: 0.5;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;

const SelectCountryItemContainer = styled.div`
  padding: 13px 16px;
  &:hover {
    background-color: #efefef;
    transition: ease-in-out 0.2s;
  }
`;

export default CountrySeletor;
