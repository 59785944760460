import React from "react";

import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import closeIcon from "../../assets/icons/closeIcon.svg";
import mapIcon from "../../assets/icons/mapIcon.svg";

import mapImage from "../../assets/images/map_Seoul.png";

import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

import { TagDiv } from "../../components/TagDiv";
import { FavoriteBtn } from "../../components/FavoriteBtn";
import { breakpoints } from "../../styles/breakpoints";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FoodModal({
  domain,
  food,
  open,
  setOpen,
  clickCount,
  setClickCount,
}) {
  const navigate = useNavigate();

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen((current) => !current);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth // 화면 가로를 꽉 채우도록 설정
      maxWidth="xs"
    >
      <DialogContainer className="dialog-container">
        <FoodImg>
          <img src={food.imgLink} alt={food.egName} />
        </FoodImg>
        <TitleContainer>
          <NameBox>
            <FoodTitle>
              <EgName>{food.egName}</EgName>
              <FoodTitleDivider></FoodTitleDivider>
              <KrName>{food.krName}</KrName>
            </FoodTitle>

            <FoodSubTitle>{food.translatedName}</FoodSubTitle>
          </NameBox>
          <FavoriteBtnContainer>
            <DetailBtn
              onClick={() => navigate(`/${domain}/${food.domainId}/reviews`)}
            >
              <p>Reviews</p>
            </DetailBtn>
            <FavoriteBtnBox>
              <FavoriteBtn
                domainId={food.domainId}
                favoriteDomainId={food.favoriteDomainId}
                setClickCount={setClickCount}
                mobileWidth="20px"
              />
              <p>{clickCount}</p>
            </FavoriteBtnBox>
          </FavoriteBtnContainer>
        </TitleContainer>
        <Divider></Divider>

        <FoodContent>
          {domain === "places" ? null : (
            <IngredientsContainer>
              <CategoryName>What's in this {domain.slice(0, -1)}?</CategoryName>
              <TagsContainer>
                {food.ingredients.map((item, i) => (
                  <TagDiv
                    key={i}
                    value={item}
                    background="#FFF"
                    color="#7A7A7A"
                  />
                ))}
              </TagsContainer>
            </IngredientsContainer>
          )}
          <FoodTagsContainer>
            <CategoryName>
              Keywords for this {domain.slice(0, -1)}.
            </CategoryName>
            <TagsContainer>
              {food.keywords.map((item) => (
                <TagDiv
                  key={item}
                  value={`#${item}`}
                  background={"#7A7A7A"}
                  color={"#FFF"}
                />
              ))}
            </TagsContainer>
          </FoodTagsContainer>
          <MapContainer>
            <MapBtn
              onClick={() =>
                navigate(`/foods/${food.domainId}/nearby?query=${food.krName}`)
              }
            >
              <img src={mapIcon} alt="mapIcon" />
              <p>Nearby {domain === "foods" ? "restaurants" : domain}</p>
            </MapBtn>
          </MapContainer>
        </FoodContent>

        <DialogActions>
          <CloseBtn onClick={handleClose}>
            <img src={closeIcon} alt="close" />
          </CloseBtn>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
}
const MapContainer = styled.div`
  position: relative;
  margin: 24px auto 10px auto;

  width: 100%;
  height: 80px;
  border-radius: 5px;
  border: 1px solid var(--gray, #aaa);
  background: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1.5px);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${mapImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1.5px); /* 블러 강도 설정 */
    z-index: -1; /* 배경보다 뒤로 */
  }
  @media (max-width: ${breakpoints.mobile}) {
    height: 60px;
  }

  img {
  }
`;
const MapBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  display: flex;
  padding: 11px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  gap: 4px;
  img {
    width: 15px;
    height: 12px;
  }
  p {
    color: var(--main, #90a9f4);
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-wrap: break-word;
  }
  &:hover {
    background: #f4f4f4;
    transition: 0.2s;
  }
`;
const FavoriteBtnContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FavoriteBtnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #000;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 9px;
    font-weight: 500;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 30px;
    p {
      margin: 4px 0 0 10px;
    }
  }
`;
const NameBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
`;

const CloseBtn = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  cursor: pointer;
  img {
    width: 11px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 8px;
  }
`;
const DetailBtn = styled.button`
  padding: 9px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
  }
  &:hover {
    background: #8098e2;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1px 5px;
  @media (max-width: ${breakpoints.mobile}) {
  }
`;
const FoodContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const IngredientsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 5px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;
const FoodTagsContainer = styled(IngredientsContainer)``;

const FoodTitle = styled.div`
  display: flex;
  align-items: center;
`;
const FoodTitleDivider = styled.div`
  height: 12px;
  margin: 0 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`;
const EgName = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 22px;
  }
`;

const KrName = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 13px;
  }
`;
const FoodSubTitle = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const FoodImg = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  margin: 0px 0 15px 0;

  img {
    width: 400px;
    object-fit: cover;
    border-radius: 5px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 170px;

    img {
      border-radius: 6px;
      width: 100%; /* 부모 크기에 맞춰 100%로 설정 */
      height: 100%; /* 부모 높이에 맞춰 100%로 설정 */
      object-fit: cover; /* 비율 유지하며 부모 크기에 맞게 잘림 허용 */
      object-position: center; /* 중앙 정렬 */
    }
  }
`;

const DialogContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 18px;
  border-radius: 7.25px;
  background: #fff;
  box-shadow: 0px 1.5px 4.3px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.25px);
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: fit-content;

    border-radius: 6px;
  }
`;

const CategoryName = styled.p`
  color: var(--tag, #7a7a7a);
  font-family: "Wanted Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 139.441% */
  letter-spacing: 0.13px;
  margin-top: 7px;
  margin-bottom: 3px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
