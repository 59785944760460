import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import checkNoneIcon from "../../../assets/icons/password-check-gray.svg";
import checkIcon from "../../../assets/icons/password-check-blue.svg";
import { defaultInstance } from "../../../shared/Request";
import { useNavigate } from "react-router-dom";
import TryAgin from "../../../components/notification-popups/TryAgin";

import showPasswordIcon from "../../../assets/icons/show-password.svg";
import hidePasswordIcon from "../../../assets/icons/hide-password.svg";
import InputLael from "../../login/InputComponents/InputLabel";
import { breakpoints } from "../../../styles/breakpoints";

const ChangePassword = ({ type, code = "", oldPassword = "" }) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [isChangeable, setIsChangeable] = useState(false);

  useEffect(() => {
    const newErrors = {};

    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      // 첫 번째 비밀번호 오류만 저장
      newErrors.password = passwordErrors[0];
    }

    if (!validatePasswordMatch(password, password2)) {
      newErrors.password2 = "The passwords do not match.";
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // 유효성 검사가 모두 통과하면 로그인 로직 실행
      setIsChangeable(true);
    } else {
      setIsChangeable(false);
    }
  }, [password, password2]);

  //비밀번호 일치 검증
  const validatePasswordMatch = (password, password2) => {
    return password.length > 0 && password === password2;
  };

  //비밀번호 형식 검증
  const validatePassword = (password) => {
    const minLength = 8;
    const errors = [];

    if (password.length < minLength) {
      errors.push(
        `The password must be at least ${minLength} characters long.`
      );
    }
    if (!/[A-Za-z]/.test(password)) {
      errors.push("The password must contain at least one letter.");
    }
    if (!/\d/.test(password)) {
      errors.push("The password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("The password must contain at least one special character.");
    }
    return errors;
  };

  // 비밀번호 재설정 (RESET) 요청 함수
  const patchResetPassword = async () => {
    try {
      const response = await defaultInstance.patch("reset-password", {
        verificationCode: code,
        newPassword: password,
      });
      console.log(response.data);
      alert("The password has been successfully reset.");
      navigate(-1);
    } catch (error) {
      if (error.response) {
        const newErrors = {};
        const { detail, status } = error.response.data;
        // console.error(
        //   `Error during password reset: ${detail} (Status: ${status})`
        // );

        if (status === 404) {
          newErrors.signUp = detail;
          setErrors(newErrors);
        } else {
          setPopupVisible(true);
        }
      }
    }
  };

  // 비밀번호 변경 (CHANGE) 요청 함수
  const patchChangePassword = async () => {
    try {
      const response = await defaultInstance.patch("/member/profile/password", {
        oldPassword: oldPassword,
        newPassword: password,
      });
      console.log(response.data);
      alert("The password has been successfully changed.");
      navigate(-1);
    } catch (error) {
      if (error.response) {
        const newErrors = {};
        const { detail, status } = error.response.data;
        // console.error(
        //   `Error during password change: ${detail} (Status: ${status})`
        // );

        if (status === 404) {
          newErrors.signUp = detail;
          setErrors(newErrors);
        } else {
          setPopupVisible(true);
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isChangeable) {
      if (type === "RESET") {
        patchResetPassword();
      } else if (type === "CHANGE") {
        patchChangePassword();
      }
    }
  };

  return (
    <div>
      {isPopupVisible && <TryAgin onClose={() => setPopupVisible(false)} />}

      <InputLael id="password" errors={errors} />
      <PasswordContainer>
        <Input
          id="password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={1}
          maxLength={32}
          placeholder="*At least 8 characters with a number and a special character."
        />
        <button
          type="button"
          onMouseDown={() => setShowPassword(true)}
          onMouseUp={() => setShowPassword(false)}
          onMouseLeave={() => setShowPassword(false)}
        >
          <ShowPasswordIcon>
            {showPassword ? (
              <img src={showPasswordIcon} alt="show password" />
            ) : (
              <img src={hidePasswordIcon} alt="show password" />
            )}
          </ShowPasswordIcon>
        </button>
      </PasswordContainer>

      {/* password2 input */}

      <InputLael id="confirm-password" errors={errors} />
      <PasswordContainer>
        <Input
          id="confirm-password"
          type={showPassword ? "text" : "password"}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          required
          minLength={1}
          maxLength={32}
        />
        <PasswordCheckIcon>
          {validatePasswordMatch(password, password2) ? (
            <img src={checkIcon} alt="check" />
          ) : (
            <img src={checkNoneIcon} alt="no check" />
          )}
        </PasswordCheckIcon>
      </PasswordContainer>

      {errors.signUp && <ErrorMessage>{errors.signUp}</ErrorMessage>}

      <SaveBtn type="submit" onClick={handleSubmit} isChangeable={isChangeable}>
        <p>Save</p>
      </SaveBtn>
    </div>
  );
};

const ShowPasswordIcon = styled.div`
  margin-right: 10px;
`;
const PasswordCheckIcon = styled.div`
  margin-right: 22px;
  img {
  }
`;
const ErrorMessage = styled.p`
  width: 310px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
`;
const SaveBtn = styled.button`
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: ${(props) =>
    props.isChangeable ? "var(--main, #90a9f4)" : "#AAA"};
  transition: background-color 0.2s ease;
  margin-top: 20px;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 15px 0px 15px 15px;
    ::placeholder {
      color: #aaa;
      font-family: "Wanted Sans";
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const PasswordContainer = styled.div`
  position: relative;
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;

export default ChangePassword;
