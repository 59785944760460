import React from "react";
import styled from "@emotion/styled";
import moreIcon from "../../assets/icons/more_arrow.svg";
import { Link } from "react-router-dom";
import { TodaysCard } from "./TodaysCard";
import { useQuery } from "react-query";
import { fetchTodayFoods } from "../../apis/mainApi";
import Loading from "../../components/Loading";
import { breakpoints } from "../../styles/breakpoints";

import CardSlider2 from "./slider/CardSlider2";

export const TodaysFood = () => {
  const {
    isLoading,
    isError,
    data: foodsData,
    error,
  } = useQuery("todaysFood", fetchTodayFoods, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (e) => {
      console.log(e.message);
    },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Wrapper>
      <TitleContainer>
        <Title>
          <h1>Today’s K-Food</h1>
          <h2>Today’s top 5 recommended Korean foods.</h2>
        </Title>
        <Link to={"/k-food-guide"}>
          <MoreBtn>
            more
            <img src={moreIcon} alt="more" />
          </MoreBtn>
        </Link>
      </TitleContainer>
      <ContentGrid>
        {foodsData.map((food, idx) => {
          if (idx === 0) {
            return (
              <TodaysCard
                key={food.domainId}
                domain="foods"
                className="big-card"
                food={food}
              />
            );
          } else {
            return (
              <TodaysCard
                domain="foods"
                className="small-card"
                key={food.domainId}
                food={food}
              />
            );
          }
        })}
      </ContentGrid>

      <CardSlider2 className="mobile-slider" foodsData={foodsData} />
    </Wrapper>
  );
};

const ContentGrid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 440px 304px 304px;
  grid-template-rows: 214px 214px;
  .big-card {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const MoreBtn = styled.div`
  background-color: #ffffff;
  border: none;
  color: #adadad;
  font-size: 15px;
  img {
    margin-left: 5px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 10px;

    font-size: 12px;
  }
`;
const Title = styled.div`
  h1 {
    color: #000;

    /* Headline */
    font-family: "Wanted Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h2 {
    color: #adadad;

    /* subtitle */
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 12px;
    }
  }
`;
const TitleContainer = styled.div`
  width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 20px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 300px;
    align-items: start;
  }
`;
const Wrapper = styled.div`
  width: 1080px;
  height: fit-content;
  margin: 130px auto 130px auto;
  background-color: #ffffff;
  box-sizing: border-box;
  @media (max-width: ${breakpoints.mobile}) {
    width: 300px;
    height: fit-content;
    margin: 90px auto 0px auto;
    display: flex;
    flex-direction: column;
  }
`;
