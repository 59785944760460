import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

export const Keyword = ({ item, checkedItems, setCheckedItems, review }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onclick = (category, value) => {
    setCheckedItems((prevState) => {
      const categoryItems = prevState[category];
      if (categoryItems.includes(value)) {
        // Remove the item if it is already checked
        return {
          ...prevState,
          [category]: categoryItems.filter((item) => item !== value),
        };
      } else {
        // Add the item if it is not checked
        return {
          ...prevState,
          [category]: [...categoryItems, value],
        };
      }
    });
    setIsChecked((prevState) => !prevState);
  };

  const returnValue = () => {
    if (review) {
      return isChecked ? (
        <TagDiv onClick={() => onclick("keyword", item)}>
          <p>{item}</p>
        </TagDiv>
      ) : (
        <NTagDiv onClick={() => onclick("keyword", item)}>
          <p>{item}</p>
        </NTagDiv>
      );
    } else {
      return isChecked ? (
        <Checked onClick={() => onclick("keyword", item)}>
          <p>{"#" + item}</p>
        </Checked>
      ) : (
        <NotChecked onClick={() => onclick("keyword", item)}>
          <p>{"#" + item}</p>
        </NotChecked>
      );
    }
  };
  return <>{returnValue()}</>;
};

const NotChecked = styled.div`
  display: flex;
  width: fit-content;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 3px 5px;
  border-radius: 12.5px;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #f3f3f3;
    transition: 0.3s ease-in-out;
  }
  p {
    color: #000;
    text-align: center;

    /* tagbox */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Checked = styled.div`
  display: flex;
  width: fit-content;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 3px 5px;
  border-radius: 12.5px;
  background: #ff7878;
  cursor: pointer;
  &:hover {
    background: #f46f6f;
    transition: 0.2s;
  }
  p {
    color: #fff;
    text-align: center;

    /* tagbox */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const NTagDiv = styled.button`
  width: 150px;
  border-radius: 10px;
  padding: 3px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin: 3px 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #d9d9de;
  &:hover {
    background-color: #f1f1f1;
    transition: 0.3s ease-in-out;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
`;

const TagDiv = styled.button`
  width: 150px;
  border-radius: 10px;
  padding: 3px 10px;
  border: 1px solid #c1d0e0;
  border-radius: 12px;
  margin: 3px 5px;
  background-color: #ecf1f5;
  box-shadow: 0px 0px 4px #d5d8dc;
  &:hover {
    background-color: #e5ebef;
    transition: 0.3s;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #6994c9;
  }
`;
