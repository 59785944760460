import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { authInstance, defaultInstance } from "../../shared/Request";
import Loading from "../Loading";

import { Keyword } from "./Keyword";

import BackIcon from "../../assets/icons/left_arrow_gray.svg";
import ReviewIcon from "../../assets/icons/review_black.svg";

import ResetIcon from "../../assets/icons/reset_button.svg";
import plusIcon from "../../assets/icons/plus-black.svg";
import imgDeleteIcon from "../../assets/icons/image-delete.svg";
import { resizeFile } from "../../utils/resizeFile";
import { breakpoints } from "../../styles/breakpoints";

export const UpdateReview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { domainId } = useParams();
  const { domainReviewId } = location.state || {};
  console.log("domainReviewId", domainId, domainReviewId);

  const [domainType, setDomainType] = useState("");
  const [detailType, setDetailType] = useState("");

  const [keywords, setKeywords] = useState(null);
  const [loadingKeywords, setLoadingKeywords] = useState(true);
  const [loadingMemberReview, setLoadingMemberReview] = useState(true);
  const [comment, setComment] = useState(
    "This is really good. You have to try it!"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const [imgChanged, setImgChanged] = useState(false);

  useEffect(() => {
    fetchMemberReview();
  }, [domainReviewId]);

  useEffect(() => {
    if (domainType && detailType) {
      if (domainType === "foods") {
        fetchFoodReviewKeyword(domainType);
      } else {
        fetchPlaceReviewKeyword(detailType);
      }
    }
  }, [domainType, detailType]);

  const fetchMemberReview = async () => {
    setLoadingMemberReview(true);
    try {
      const response = await defaultInstance.get(
        `/member/reviews/${domainReviewId}`
      );
      console.log(domainReviewId, "의 리뷰 데이터: ", response.data);

      setDomainType(response.data.domainType);
      setDetailType(response.data.detailType);
      setSelectedKeywords(response.data.memberSelectTags);
      setImagePreviewUrl(response.data.imgLink);
      setComment(response.data.comment);
    } catch (error) {
      //console.error("Error fetching Member Review", error);
    } finally {
      setLoadingMemberReview(false);
    }
  };

  //음식 도메인 키워드 데이터
  const fetchFoodReviewKeyword = async (domainType) => {
    try {
      const response = await defaultInstance.get(
        `/tags?detailType=${detailType}`
      );
      console.log("foods 키워드 태그 데이터: ", response.data);
      setKeywords(response.data.reviewTags);
    } catch (error) {
      //console.error("Error fetching Review Keyword", error);
    } finally {
      setLoadingKeywords(false);
    }
  };
  //장소 도메인 키워드 데이터
  const fetchPlaceReviewKeyword = async (detailType) => {
    try {
      const response = await defaultInstance.get(
        `/tags?detailType=${detailType}`
      );
      console.log("places 키워드 태그 데이터: ", response.data);
      setKeywords(response.data.reviewTags);
    } catch (error) {
      //console.error("Error fetching Review Keyword", error);
    } finally {
      setLoadingKeywords(false);
    }
  };

  const handleImageDelete = (event) => {
    event.stopPropagation();
    setImagePreviewUrl("");
    setSelectedFile(null);
    setImgChanged(true);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setImagePreviewUrl(URL.createObjectURL(file));
      setImgChanged(true);
    }
  };

  const submitReview = async () => {
    const formData = new FormData();

    formData.append("domainId", domainId);
    formData.append("memberSelectTags", selectedKeywords);
    formData.append("comment", comment);
    formData.append("imgChanged", imgChanged);

    //이미지가 있다면
    if (selectedFile) {
      try {
        const resizedImage = await resizeFile(selectedFile);
        console.log("imgae incoding after : ", resizedImage);

        formData.append("image", resizedImage, selectedFile.name);

        /* formData 출력 */
        for (let key of formData.keys()) {
          console.log(key, ":", formData.get(key));
        }
        /* formData 출력 */

        const response = await authInstance.put(
          `/member/reviews/${domainReviewId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("Response:", response.data);
          alert("Review submitted successfully.");
          navigate(-1);
        }
      } catch (error) {
        //console.error("Error submitting review:", error);
      }
    } else {
      // Handle case without image
      /* formData 출력 */
      for (let key of formData.keys()) {
        console.log(key, ":", formData.get(key));
      }
      /* formData 출력 */
      try {
        const response = await authInstance.put(
          `/member/reviews/${domainReviewId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("Response:", response.data);
          alert("Review submitted successfully.");

          navigate(-1);
        }
      } catch (error) {
        //("Error submitting review:", error);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    //선택한 키워드가 0개라면
    if (selectedKeywords.length === 0) {
      return alert("Please select at least one keyword.");
    }
    //텍스트폼 글자수가 350이 넘는다면
    if (comment.length > 350) {
      return alert("Please enter your review within 350 characters.");
    }
    submitReview();
  };

  useEffect(() => {
    // Cleanup function to revoke the object URL
    return () => {
      if (imagePreviewUrl) {
        URL.revokeObjectURL(imagePreviewUrl);
      }
    };
  }, [imagePreviewUrl]);

  return (
    <>
      {loadingKeywords || loadingMemberReview ? (
        <Loading />
      ) : (
        <Container>
          <BackBtn onClick={() => navigate(`/foods/${domainId}/reviews`)}>
            <img src={BackIcon} alt="BackIcon" />
            <p>Back</p>
          </BackBtn>
          <TitleContainer>
            <Title>
              <img src={ReviewIcon} alt="ReviewIcon" />
              <p>Update Review</p>
            </Title>
          </TitleContainer>
          <form onSubmit={handleSubmit}>
            <KeywordContainer>
              <KeywordTextBox>
                <h2>What did you like about it?</h2>
                <h3>Please choose a keyword. (1~5)</h3>
              </KeywordTextBox>
              <KeywordTagBox>
                {keywords.map((category) => {
                  return (
                    <TagContainer key={category.tagDetail}>
                      <CategoryTitle>{category.tagDetail}</CategoryTitle>
                      {category.tags.map((keyword) => {
                        return (
                          <Keyword
                            keyword={keyword}
                            key={keyword.TagValue}
                            selectedKeywords={selectedKeywords}
                            setSelectedKeywords={setSelectedKeywords}
                          />
                        );
                      })}
                    </TagContainer>
                  );
                })}
              </KeywordTagBox>
            </KeywordContainer>

            <PictureContainer
              onClick={() => document.getElementById("fileInput").click()}
            >
              <HiddenInput
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png"
              />
              {imagePreviewUrl ? (
                <ImagePreviewContainer>
                  <ImageDeleteBtn
                    src={imgDeleteIcon}
                    alt="imgDeleteIcon"
                    onClick={handleImageDelete}
                  ></ImageDeleteBtn>
                  <ImagePreview src={imagePreviewUrl} alt="Preview" />
                </ImagePreviewContainer>
              ) : (
                <PlusContainer>
                  <PlusComment>
                    Please upload a photo of it(optional)
                  </PlusComment>
                  <PlusIcon>
                    <img src={plusIcon} alt="plus" />
                  </PlusIcon>
                </PlusContainer>
              )}
            </PictureContainer>

            <TextReviewContainer>
              <TextBoxHeader>
                <h2>Please enter your review.</h2>
                <ResetBtn onClick={() => setComment("")}>
                  <img src={ResetIcon} alt="reset" />
                  Reset
                </ResetBtn>
              </TextBoxHeader>
              <Textarea
                type="textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="good"
                maxlength="350"
              />
              <TextBoxFooter>
                <WarningText>
                  <br /> If you don't enter a review, a default review will be
                  applied.
                </WarningText>
                <TextLength>
                  <NowTextLength className={comment.length > 350 ? "red" : ""}>
                    {comment.length}
                  </NowTextLength>
                  <span> / 350</span>
                </TextLength>
              </TextBoxFooter>
            </TextReviewContainer>
            <SubmitContainer>
              <ReviewWarning type="button">
                <p>Review guidelines</p>
              </ReviewWarning>
              <SubmitBtn type="submit">Update Review</SubmitBtn>
            </SubmitContainer>
          </form>
        </Container>
      )}
    </>
  );
};

const ReviewWarning = styled.button`
  margin-bottom: 20px;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
`;
const ImageDeleteBtn = styled.img`
  width: 16px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
const ImagePreviewContainer = styled.div`
  width: fit-content;
  position: relative;
`;
const PlusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
const PlusComment = styled.p`
  color: #000;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
`;
const PlusIcon = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #90a9f466;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #7993e066;
    transition: 0.2s;
  }
`;
const HiddenInput = styled.input`
  display: none;
`;
const NowTextLength = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin-right: 3px;
`;
const TextLength = styled.div`
  display: flex;
  align-items: center;
  span {
    color: var(--main, #8397d3);
    font-family: "Wanted Sans";
    font-size: 10px;
    font-weight: 500;
  }
  .red {
    color: var(--main, #e32828);
    font-family: "Wanted Sans";
    font-size: 10px;
    font-weight: 500;
  }
`;
const WarningText = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    width: 200px;
  }
`;
const TextBoxFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  padding: 10px;
  border: none;
  font-size: 16px;
  line-height: 1.5;
  background: none;
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:focus {
    border: none;
    outline: none;
  }

  /* Additional custom styles */
  &.error {
    border-color: red;
  }

  &::placeholder {
    color: #999;
  }
`;
const TextBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-weight: 700;
  }
`;
const ResetBtn = styled.button`
  display: inline-flex;
  height: 18px;
  padding: 3px 8px;
  justify-content: center;
  gap: 3px;
  border-radius: 10px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-weight: 500;
`;
const ImagePreview = styled.img`
  width: 230px;

  border-radius: 5px;
`;
const SubmitBtn = styled.button`
  width: 243.232px;
  height: 40px;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-weight: 700;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;
const TagContainer = styled.div``;
const KeywordTagBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${breakpoints.mobile}) {
    width: 453px;
  }
`;
const CategoryTitle = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const KeywordTextBox = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 7px;
  margin-bottom: 7px;
  h2 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-weight: 700;
  }
  h3 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-weight: 500;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 453px;
  }
`;
const InnerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  background: var(--back-blue, #f3f5fb);
  padding: 12px 10px;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
  }
`;
const KeywordContainer = styled(InnerContainer)`
  overflow-y: scroll;
  height: 300px;

  @media (max-width: ${breakpoints.mobile}) {
    overflow: scroll;
  }
`;
const PictureContainer = styled(InnerContainer)``;
const TextReviewContainer = styled(InnerContainer)`
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 5px;
  }
`;
const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

const BackBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 9px;
    margin-right: 5px;
  }
  p {
    color: var(--gray, #aaa);
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  margin-bottom: 15px;
`;
const TitleContainer = styled.div`
  width: 100%;
`;
const Container = styled.div`
  box-sizing: border-box;
  width: 727px;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;

  @media (max-width: ${breakpoints.mobile}) {
    box-sizing: border-box;
    width: 340px;
    height: fit-content;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    p {
      font-size: 17px;
    }
  }
`;
const SubTitle = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 10px 2px;
`;
