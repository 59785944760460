import React, { useState } from "react";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { defaultInstance } from "../../../shared/Request";
import TryAgin from "../../../components/notification-popups/TryAgin";
import { InputLabel } from "@mui/material";
import InputLael from "../InputComponents/InputLabel";
import { breakpoints } from "../../../styles/breakpoints";

const ResetEmailVerifyForm = ({ setIsEmailValid, code, setCode }) => {
  const navigate = useNavigate();

  const type = "reset";
  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState({});

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [isVerified, setIsVerified] = useState(false);

  const [isSendCodeDisabled, setSendCodeDisabled] = useState(false); // Send code 버튼 비활성화 상태
  const [timer, setTimer] = useState(0); // 타이머 상태

  //이메일 형식 검증
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const postEmailCode = async (email) => {
    if (!validateEmail(email)) {
      setErrors({ email: "Please enter a valid email format." });
      return;
    }
    setSendCodeDisabled(true); // 버튼 비활성화
    setTimer(60); // 1분 타이머 시작

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown); // 타이머 종료
          setSendCodeDisabled(false); // 버튼 활성화
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    try {
      const response = await defaultInstance.post("email-code", {
        email: email,
        type: type,
      });
    } catch (error) {
      if (error.response) {
        const { detail, status } = error.response.data;
        // console.error(
        //   `Error sending email code: ${detail} (Status: ${status})`
        // );
      }
    }
  };

  const postVerifyCode = async (code) => {
    try {
      const response = await defaultInstance.post("verify-code", {
        verificationCode: code,
      });
      console.log(response.status);

      if (response.status) {
        setIsVerified(true);
      }
    } catch (error) {
      //console.error("Error verifing email code:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Please complete email verification.");
      return 0;
    }
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email format.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // 유효성 검사가 모두 통과하면 로그인 로직 실행
      console.log("리셋 이메일 인증 완료:", { email, code });
      setIsEmailValid(true);
    }
  };

  return (
    <FormWrapper>
      {isPopupVisible && <TryAgin onClose={() => setPopupVisible(false)} />}

      {/* email input */}

      <InputLael
        id="email"
        errors={errors}
        isSendCodeDisabled={isSendCodeDisabled}
      />
      <EmailInputContainer>
        <InputContainer>
          <Input
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            minLength={1}
            maxLength={256}
          />
        </InputContainer>
        <MiniBtn
          type="button"
          onClick={() => postEmailCode(email)}
          disabled={isSendCodeDisabled}
        >
          <p>Send code</p>
        </MiniBtn>
      </EmailInputContainer>

      {/* code input */}
      <InputLael id="code" errors={errors} />
      <EmailInputContainer>
        <InputContainer>
          <Input
            id="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            minLength={1}
            maxLength={6}
          />
        </InputContainer>
        <MiniBtn
          type="button"
          onClick={() => postVerifyCode(code)}
          disabled={isVerified}
        >
          <p>Veirify</p>
        </MiniBtn>
      </EmailInputContainer>

      {errors.signUp && <ErrorMessage>{errors.signUp}</ErrorMessage>}

      <LoginBtn type="submit" onClick={handleSubmit}>
        <p>Next</p>
      </LoginBtn>
    </FormWrapper>
  );
};

const EmailInputContainer = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    gap: 5px;
  }
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.mobile}) {
    height: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const ErrorMessage = styled.p`
  width: 310px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
`;
const LoginBtn = styled.button`
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: var(--main, #90a9f4);
  margin-top: 20px;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background-color: #7791df;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }
`;
const InputContainer = styled.div`
  position: relative;
  width: 310px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 245px;
    height: 45px;
  }
`;
const MiniBtn = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 5px;
  background: ${({ disabled }) =>
    disabled
      ? "#AAA"
      : "var(--main, #90a9f4)"}; // 버튼이 비활성화되면 회색으로 변경
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 60px;
    height: 45px;
    p {
      font-size: 12px;
      line-height: 13px; /* 108.333% */
    }
  }
`;
export default ResetEmailVerifyForm;
