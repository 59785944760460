import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FavoriteBtn } from "../../components/FavoriteBtn";

import { defaultInstance } from "../../shared/Request";
import FoodModal from "./FoodModal";
import { breakpoints } from "../../styles/breakpoints";

export const DefaultFoodCard = ({ domain, food }) => {
  const [open, setOpen] = useState(false);
  const [clickCount, setClickCount] = useState(food.favoriteCount);

  const [reviewStatistics, setReviewStatistics] = useState(null);
  const [modalData, setModalData] = useState(null);

  const [loadingReviewStats, setLoadingReviewStats] = useState(true);
  const [loadingModalData, setLoadingModalData] = useState(true);

  useEffect(() => {
    if (food.favoriteCount !== undefined) {
      setClickCount(food.favoriteCount);
    }
  }, [food.favoriteCount]);

  //리뷰통계 GET API
  const fetchReviewTagStatistic = async () => {
    setLoadingReviewStats(true);
    try {
      const response = await defaultInstance.get(
        `/domains/${food.domainId}/review-statistics`
      );
      console.log("리뷰 통계 데이터: ", response.data);
      setReviewStatistics(response.data);
    } catch (error) {
      //console.error("Error fetching Review Tag Statistic", error);
    } finally {
      setLoadingReviewStats(false);
    }
  };

  //모달창 정보 get api
  const fetchModalData = async () => {
    setLoadingModalData(true);
    try {
      const response = await defaultInstance.get(
        `/modal/foods/${food.domainId}`
      );
      console.log("모달창 데이터: ", response.data);
      setModalData(response.data);
      setClickCount(response.data.favoriteCount);
    } catch (error) {
      //console.error("Error fetching fetchModalData", error);
    } finally {
      setLoadingModalData(false);
    }
  };

  const handleClickOpen = () => {
    fetchReviewTagStatistic();
    fetchModalData();

    setOpen(true);
  };

  return (
    <CardWrapper onClick={handleClickOpen}>
      {
        /* 디테일 모달 */
        loadingReviewStats || loadingModalData ? null : (
          <FoodModal
            domain={domain}
            food={modalData}
            open={open}
            setOpen={setOpen}
            reviewStatistics={reviewStatistics}
            clickCount={clickCount}
            setClickCount={setClickCount}
          />
        )
      }
      <FoodImg src={food.imgLink} loading="lazy"></FoodImg>
      <FoodContent>
        <FoodTitle>
          <TextBox>
            <h1>{food.egName}</h1>
            <h2>{food.translatedName}</h2>
          </TextBox>
          <FavoriteBtn
            domainId={food.domainId}
            favoriteDomainId={food.favoriteDomainId}
            setClickCount={setClickCount}
            width="22px"
            mobileWidth="16px"
          />
        </FoodTitle>
      </FoodContent>
    </CardWrapper>
  );
};
const TextBox = styled.div`
  width: 190px;
  h1 {
    color: #000;

    font-family: "Wanted Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 125% */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    color: #000;

    /* Card Subtitle */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 140px;
    h1 {
      font-size: 15px;
      line-height: 20px; /* 125% */
    }
    h2 {
      font-size: 10px;
    }
  }
`;

const FoodTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 7px 15px 0;
  @media (max-width: ${breakpoints.mobile}) {
    height: 34px;
    padding: 0px;
  }
`;

const CardWrapper = styled.div`
  box-sizing: content-box;
  width: 240px;
  height: 300px;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0px 0px 35px 0px;
  padding: 8px;
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0px 3px 5px #d6d6d6;
    transition: 0.3s;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 163px;
    height: 213px;

    margin: 0px 0px 0px 0px;
    padding: 6px 6px;
  }
`;

const FoodImg = styled.img`
  width: 240px;
  height: 240px;
  object-fit: cover;
  background-color: #e4e4e4;
  border-radius: 8px;
  position: relative;

  background-position: center;
  background-size: cover;
  @media (max-width: ${breakpoints.mobile}) {
    width: 162px;
    height: 162px;
  }
`;

const FoodContent = styled.div`
  width: 220px;
  height: 155px;
  margin: 10px 10px 10px 10px;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 155px;
    height: fit-content;
    margin: 5px 0px;
  }
`;
