import React from "react";
import styled from "@emotion/styled";
import HeaderDiv from "../../components/HeaderDiv";

import { Introduction } from "./Introduction";
import { TodaysFood } from "./TodaysFood";
import { TodaysPlace } from "./TodaysPlace";
import { TodaysReview } from "./TodaysReview";

import * as Sentry from "@sentry/react";

export const Main = () => {
  //에러 테스트
  // const errorTestButton = () => {
  //   try {
  //     throw new Error("커스텀 에러 발생, 센트리 에러 테스트");
  //   } catch (error) {
  //     Sentry.captureException(error);
  //   }
  // };

  // return (
  //   <div>
  //     <HeaderDiv />
  //     <p>메인페이지</p>
  //     <button onClick={errorTestButton}>에러 발생시키기</button>
  //   </div>
  // );

  return (
    <MainWrapper>
      <HeaderDiv />
      <Introduction />
      <TodaysFood />
      <TodaysPlace />
      <TodaysReview />
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  background-color: #ffffff;
`;
