import React from "react";
import styled from "@emotion/styled";

import closeIcon from "../../assets/icons/closeIcon.svg";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";

export default function WithinKoreaNoti({ onClose }) {
  const navigate = useNavigate();
  return (
    <Overlay onClick={() => navigate(-1)}>
      <Popup onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={() => navigate(-1)}>
          <img src={closeIcon} alt="closeIcon" />
        </CloseButton>
        <PopUpContainer>
          <h2>It seems like you're not in Korea!</h2>
          <h2>We're currently able to provide</h2>
          <h2>show places within Korea.</h2>
          <LoginBtn onClick={() => navigate(-1)}>
            <p>OK</p>
          </LoginBtn>
        </PopUpContainer>
      </Popup>
    </Overlay>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoginBtn = styled.button`
  width: 120px;
  height: 30px;
  margin-top: 20px;
  border-radius: 1.793px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 0.896px 2.689px 0px rgba(0, 0, 0, 0.2);
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 700;
  }
  &:hover {
    background: #7b93da;
    transition: 0.2s;
  }
`;
const PopUpContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
  align-items: center;
  img {
    margin: 40px 0 8px 0;
    width: 22.463px;
    height: 26.25px;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 17px;
    font-weight: 700;
  }
`;
const Overlay = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  box-sizing: border-box;
  position: relative;
  background: white;
  padding: 30px 20px;
  border-radius: 5px;
  width: 350px;
  z-index: 5;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeIn} 0.5s forwards;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  img {
    width: 10px;
  }
`;
