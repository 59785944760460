import styled from "@emotion/styled";

export const ThumUp = ({ color, backgrounColor }) => (
  <Icon
    color={color}
    backgrounColor={backgrounColor}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    stroke={color}
  >
    <path
      d="M5.67695 18.5449H2.1146C1.57345 18.5449 1.13477 18.1062 1.13477 17.5651V9.3013C1.13477 8.76015 1.57345 8.32146 2.1146 8.32146H4.69712C5.23826 8.32146 5.67695 8.76015 5.67695 9.3013V13.4332V18.5449ZM5.67695 18.5449H14.5968C15.9178 18.5449 17.0112 17.5115 17.0939 16.1849L17.5026 9.62546C17.5478 8.9007 16.9758 8.28791 16.2541 8.28791H13.6003C13.2549 8.28791 12.9748 8.03946 12.9748 7.65852C12.9748 7.27758 13.3702 6.74675 13.3702 5.02313C13.3702 3.29951 12.6886 2.13287 11.4763 1.68246C10.4321 1.29451 8.99208 1.64938 8.99208 3.75832C8.99208 5.86726 8.99208 5.86716 8.99208 5.86716C8.99208 8.06087 6.83184 9.03293 5.67695 9.3037"
      strokeWidth="1.5"
    />
  </Icon>
);
const Icon = styled.svg`
  stroke: ${(props) => props.color || "#000000"};
  fill: ${(props) => props.backgrounColor || ""};
`;
