import React from "react";
import styled from "@emotion/styled";
import logo from "../../assets/images/logo_upper_case.svg";
import { FeatureCard } from "./FeatureCard";
import { breakpoints } from "../../styles/breakpoints";
export const Introduction = () => {
  return (
    <Wrapper>
      <TitleContainer>
        <KoriLogo src={logo} />
        <SubTitle>
          I'm your Korean friend, <BlueText>Kori</BlueText>
        </SubTitle>
        <SubTitle>Experience Korea like a local with me!</SubTitle>
      </TitleContainer>
      <FeatureContainer>
        <FeatureCard
          featureName={"K-Food Guide"}
          featureDescription={"Check out all Korean foods!"}
          imgSrc={process.env.PUBLIC_URL + "images/main_kfood.jpg"}
        />

        <FeatureCard
          featureName={"K-Place Guide"}
          featureDescription={"Are you ready to feel inside Korea?"}
          imgSrc={process.env.PUBLIC_URL + "images/main_kplace.jpg"}
        />
      </FeatureContainer>
    </Wrapper>
  );
};

const FeatureContainer = styled.div`
  display: flex;
  width: 1200px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    width: 360px;
    padding: 0px 0px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;
const SubTitle = styled.h1`
  color: #000;
  /* slogan */
  font-family: "Wanted Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;

const BlueText = styled.span`
  color: #8397d3;
  text-align: center;
  /* slogan */
  font-family: "Wanted Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 112% */

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;

const KoriLogo = styled.img`
  width: 185px;
  margin-right: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 163px;
    margin-bottom: 7px;
  }
`;

const Wrapper = styled.div`
  width: 1200px;
  height: 450px;
  margin: 100px auto 130px auto;
  background-color: #ffffff;

  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 465px;
    margin: 90px auto 120px auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  height: 150px;
  padding: 0px 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.mobile}) {
    height: 120px;
    padding: 0px 14px;
    gap: 0px;
  }
`;
