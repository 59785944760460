import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import closeIcon from "../../assets/icons/closeIcon.svg";

export default function CustomNoti({ onClose, message }) {
  return (
    <Overlay onClick={onClose}>
      <Popup onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <img src={closeIcon} alt="closeIcon" />
        </CloseButton>

        <Message>{message}</Message>
      </Popup>
    </Overlay>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Message = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
`;

const Overlay = styled.div`
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  box-sizing: border-box;
  position: relative;
  background: white;
  border-radius: 5px;
  width: 350px;
  height: 150px;
  padding: 10px 30px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeIn} 0.5s forwards;
  @media (max-width: 800px) {
    width: 326px;
    height: 150px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  img {
    width: 10px;
  }
`;
