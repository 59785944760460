import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import checkIcon from "../../assets/icons/check-white.svg";

const Toast = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ToastContainer visible={visible}>
      <img src={checkIcon} alt="check" />
      <p>{message}</p>
    </ToastContainer>
  );
};

const ToastContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 40px;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 1s ease-out, visibility 1s ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 700;
  }
`;

export default Toast;
