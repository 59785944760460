import React from "react";
import styled from "@emotion/styled";

import { Global, css } from "@emotion/react";
import diceIcon from "../../assets/icons/diceIcon.svg";
import { SignUpCountrySelector } from "../../components/SignUpCountrySelector";

import HeaderDiv from "../../components/HeaderDiv";
import { breakpoints } from "../../styles/breakpoints";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";

export const InformationInput = ({
  userNickname,
  setUserNickname,
  country,
  setCountry,
  handleSignUpClick,
}) => {
  const animalNames = [
    "Lion",
    "Tiger",
    "Bear",
    "Shark",
    "Eagle",
    "Wolf",
    "Fox",
    "Panda",
    "Hawk",
    "Leopard",
  ];

  const handleRandomNickname = (event) => {
    event.preventDefault();
    const randomAnimal =
      animalNames[Math.floor(Math.random() * animalNames.length)];
    const randomNumbers = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number

    setUserNickname(`${randomAnimal}${randomNumbers}`);
  };

  const onChange = (event) => {
    setUserNickname(event.target.value);
  };

  return (
    <>
      <Global styles={GlobalStyles} />

      <Wrapper>
        <LoginContainer>
          <TitleContainer>
            <Title>Sign up</Title>
            <Description>
              Nice to meet you! Please enter your information.
            </Description>
          </TitleContainer>

          <NickNameContainer>
            <SignUpForm>
              {/* nickname input */}
              <LabelContainer>
                <Label htmlFor="nickname">
                  Nickname<span>*</span>
                </Label>
              </LabelContainer>
              <InputContainer>
                <Input
                  id="nickname"
                  type="text"
                  label="Nickname"
                  value={userNickname}
                  required
                  onChange={onChange}
                  minLength={1}
                  maxLength={20}
                />
                <RandomBtn onClick={handleRandomNickname}>
                  <img src={diceIcon} alt="random-button" />
                </RandomBtn>
              </InputContainer>

              {/* country input */}
              <LabelContainer>
                <Label htmlFor="nationality">Nationality</Label>

                <ErrorMessage>※You can fill it out later</ErrorMessage>
              </LabelContainer>
              <CountryInputContainer>
                <SignUpCountrySelector
                  id="nationality"
                  country={country}
                  setCountry={setCountry}
                />
              </CountryInputContainer>
            </SignUpForm>
          </NickNameContainer>

          <SignUpBtn type="submit" onClick={handleSignUpClick}>
            <BtnText className="sign-up-button--text">Next</BtnText>
          </SignUpBtn>
        </LoginContainer>
      </Wrapper>
    </>
  );
};

const ErrorMessage = styled.p`
  width: 310px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-weight: 500;
  word-wrap: break-word;
  @media (max-width: ${breakpoints.mobile}) {
    width: 220px;
  }
`;
const LabelContainer = styled.div`
  width: 440px;
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
  margin-bottom: 4px;
  margin-left: 2px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;

    align-items: center;
  }
`;
const Label = styled.label`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    color: red;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }
  @media (max-width: ${breakpoints.mobile}) {
    padding: 15px 15px 15px 25px;
    font-size: 12px;
  }
`;

const SignUpForm = styled.form``;
const InputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;

  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const CountryInputContainer = styled(InputContainer)`
  padding: 0 0 6px 0;
`;
const SignUpBtn = styled.button`
  width: 440px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--main, #90a9f4);

  &:hover {
    background-color: #8397d3;
    transition: 0.2s;

    .sign-up-button--text {
      color: #ffffff;
      transition: 0.2s;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
    position: absolute;
    bottom: 15px;
  }
`;

const BtnText = styled.p`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;
const NickNameContainer = styled.div`
  display: flex;
  align-items: end;
  margin: 26px auto;
  @media (max-width: ${breakpoints.mobile}) {
    margin: 0px auto;
  }
`;
const RandomBtn = styled.button`
  width: 40px;
  height: 40px;
  margin: 0 6px 0 0px;
  border: none;
  background: none;
  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 4px 0 0px;
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

const LoginContainer = styled.div`
  width: 530px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 100%;
    flex-shrink: 0;
    position: relative;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
      overflow: hidden;
    }
  }
`;
const Wrapper = styled.div`
  box-sizing: border-box;
  width: 95vw;
  height: 538px;
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-top: 62px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;
    padding: 84px 0 0 0;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 530px;
  height: fit-content;
  padding: 0 0px 15px 45px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    padding: 0 0px 15px 8px;
  }
`;
