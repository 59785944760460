import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import styled from "@emotion/styled";

// Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./CardSlider2.css"; // 스타일을 위한 커스텀 CSS 파일

import CardSlide from "./CardSlide";
import { breakpoints } from "../../../styles/breakpoints";

const CardSlider2 = ({ foodsData }) => {
  const paginationRef = useRef(null);
  return (
    <SlideWrapper className="container">
      <div className="swiper-container">
        <Swiper
          modules={[Navigation, Pagination]}
          loop={true}
          pagination={{
            el: paginationRef.current,
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + "</span>";
            },
          }}
          navigation={{
            nextEl: ".custom-next", // 커스텀 내비게이션 클래스
            prevEl: ".custom-prev", // 커스텀 내비게이션 클래스
          }}
        >
          {foodsData.map((food, index) => {
            return (
              <SwiperSlide
                key={index} // 각 슬라이드에 고유 키를 추가
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <CardSlide food={food} />
              </SwiperSlide>
            );
          })}

          {/* 커스텀 내비게이션 버튼 */}
          <div className="custom-prev"></div>
          <div className="custom-next"></div>
        </Swiper>
        <div className="custom-pagination" ref={paginationRef}></div>
      </div>
    </SlideWrapper>
  );
};

const SlideWrapper = styled.div`
  height: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }
`;

export default CardSlider2;
