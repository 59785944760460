import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#90A9F4" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 2px lightblue" : provided.boxShadow,
    "&:hover": {
      borderColor: "lightblue",
    },
    width: "120px",
    minHeight: "30px",
    height: "30px",
    fontSize: "14px",
    textAlign: "start",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0 14px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "4px",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0px",
    borderRadius: "0px 0px 4px 4px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "gray",
    backgroundColor: state.isFocused ? "#f5f5f5" : "#ffffff",
    fontSize: "14px",
    "&:active": {
      backgroundColor: "#e6e6e6",
    },
  }),
};

const options = [
  { value: "all", label: "All" },
  { value: "foods", label: "K-food" },
  { value: "places", label: "K-place" },
];

const DomainCustomSelect = ({ type, setType }) => {
  const handleChange = (selectedOption) => {
    setType(selectedOption); // Update the state in the parent component
    console.log(type);
  };
  return (
    <Select
      options={options}
      value={type} // Controlled value from the parent component
      onChange={handleChange} // Set new value when an option is selected
      styles={customStyles}
      isSearchable={false}
    />
  );
};

export default DomainCustomSelect;
