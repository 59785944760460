import React, { useState } from "react";
import styled from "@emotion/styled";

import { defaultInstance } from "../../shared/Request";
import { Keyword } from "./Keyword";
import { fetchIngredients, fetchKeywords } from "../../apis/foodApi";

/*       Mui        */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";

/*       Icon        */
import Tooltip from "@mui/material/Tooltip";
import Loading from "../../components/Loading";
import { useQuery } from "react-query";
import ingredient from "../../assets/icons/ingredient.svg";

import leftIcon from "../../assets/icons/left_arrow_black.svg";
import foodtag from "../../assets/icons/foodtag.svg";
import drawerButton from "../../assets/icons/drawer_button.svg";

import resetIcon from "../../assets/icons/filter_reset.svg";
import { breakpoints } from "../../styles/breakpoints";

export const FoodFilter = ({
  setSearchedFoods,
  mobileFilterClick,
  setMobileFilterClick,
}) => {
  const TYPE = "foods";

  const [isFormVisible, setIsFormVisible] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isFoodTagFormVisible, setIsFoodTagFormVisible] = useState([
    false,
    false,
    false,
  ]);
  //선택된
  const [checkedItems, setCheckedItems] = useState({
    ingredient: [],
    keyword: [],
  });

  //서버로 부터 features data 받아오기
  const { isLoading: keywordLoading, data: keywords } = useQuery(
    "keyword",
    () => fetchKeywords(TYPE)
  );
  const { isLoading: ingredientsLoading, data: ingredients } = useQuery(
    "ingredients",
    () => fetchIngredients(TYPE)
  );

  const handleCheckboxChange = (category, value) => {
    console.log(checkedItems);
    setCheckedItems((prevState) => {
      const categoryItems = prevState[category];
      if (categoryItems.includes(value)) {
        // Remove the item if it is already checked
        return {
          ...prevState,
          [category]: categoryItems.filter((item) => item !== value),
        };
      } else {
        // Add the item if it is not checked
        return {
          ...prevState,
          [category]: [...categoryItems, value],
        };
      }
    });
  };

  //서버로 선택한 재료 전송
  const handleSubmit = async (event) => {
    event.preventDefault();
    const IString = [];
    checkedItems.ingredient.forEach((i, index) => {
      index === 0
        ? IString.push(`ingredient=${i}`)
        : IString.push(`&ingredient=${i}`);
    });

    const KString = [];
    checkedItems.keyword.forEach((i) => {
      KString.push(`&tag=${i}`);
    });

    const params = encodeURI((IString + KString).replace(/,/g, ""));
    console.log(params);
    try {
      const response = await defaultInstance.get(`/search/tag/foods?${params}`);
      console.log("Response:", response.data);
      setSearchedFoods(response.data.searchResult);
    } catch (error) {
      //console.error("Error submitting ingredients:", error);
    }
  };

  const handleTitleClick = (index) => {
    const newIsFormVisible = [];
    isFormVisible.forEach((item, i) => {
      newIsFormVisible.push(i === index ? !isFormVisible[index] : item);
    });
    setIsFormVisible(newIsFormVisible);
  };

  const handleFoodTagTitleClick = (index) => {
    const newIsFormVisible = [];
    isFoodTagFormVisible.forEach((item, i) => {
      newIsFormVisible.push(i === index ? !isFoodTagFormVisible[index] : item);
    });
    setIsFoodTagFormVisible(newIsFormVisible);
  };

  const handleOverlayClick = () => {
    setMobileFilterClick(false);
  };

  const handleFilterClick = (e) => {
    e.stopPropagation(); // 필터 자체를 클릭할 때는 오버레이 클릭 이벤트가 발생하지 않도록 함
  };

  const handleFilterReset = (e) => {
    e.stopPropagation();
    console.log("filter reset click");
    setCheckedItems({
      ingredient: [],
      keyword: [],
    });
    setIsFormVisible([false, false, false, false, false, false, false]);
    setIsFoodTagFormVisible([false, false, false]);
  };

  return (
    <>
      {keywordLoading || ingredientsLoading ? (
        <Loading />
      ) : (
        <div>
          <Overlay
            mobileFilterClick={mobileFilterClick}
            onClick={handleOverlayClick}
          ></Overlay>
          <FilterContainer
            mobileFilterClick={mobileFilterClick}
            onClick={handleFilterClick}
          >
            <MobileHeader>
              <img
                onClick={() => setMobileFilterClick((prev) => !prev)}
                src={leftIcon}
                alt="back"
              />
              <HeaderDivider></HeaderDivider>
            </MobileHeader>
            {/* 재료 필터 */}
            <IngredientBox>
              <FilterType>
                <TitleBox>
                  <img src={ingredient} alt="ingredient" />
                  <p>Ingredient</p>
                  <Tooltip title="Choose the ingredients for the food">
                    <IconButton>
                      <HelpOutlineIcon color="action" sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                </TitleBox>
                <ResetBtn onClick={handleFilterReset}>
                  <img src={resetIcon} alt="reset" />
                  <p>Reset</p>
                </ResetBtn>
              </FilterType>

              {ingredients.map((item, i) => {
                return isFormVisible[i] ? (
                  /* 열려있는 폼 */
                  <OpendIngredientCategory
                    key={item.featureDetail}
                    color="#E3E8F8"
                  >
                    <CategoryHeader
                      onClick={() => handleTitleClick(i)}
                      color="#d9e0f6"
                    >
                      <IngredientCategoryText>
                        {item.featureDetail}
                      </IngredientCategoryText>
                      <img src={drawerButton} alt="ingredient" />
                    </CategoryHeader>
                    <div className="divider"></div>
                    <CategoryContent>
                      <StyledFormGroup
                        visible={isFormVisible[i]}
                        id="ingredients"
                      >
                        {item.featureValues.map((iitem) => (
                          <StyledFormControlLabel
                            key={iitem}
                            control={
                              <Checkbox
                                className="ingredient-checkbox"
                                onChange={() =>
                                  handleCheckboxChange("ingredient", iitem)
                                }
                                sx={{
                                  color: "#8397D3",
                                  "&.Mui-checked": {
                                    color: "#8397D3",
                                  },
                                }}
                              />
                            }
                            label={<StyledLabel>{iitem}</StyledLabel>}
                          />
                        ))}
                      </StyledFormGroup>
                    </CategoryContent>
                  </OpendIngredientCategory>
                ) : (
                  /* 닫혀있는 폼 */
                  <ClosedIngredientCategory
                    key={item.featureDetail}
                    onClick={() => handleTitleClick(i)}
                  >
                    <IngredientCategoryText>
                      {item.featureDetail}
                    </IngredientCategoryText>
                    <img src={drawerButton} alt="ingredient" />
                  </ClosedIngredientCategory>
                );
              })}
            </IngredientBox>

            <Divider className="container-divider" />

            {/* 음식태그 필터 */}
            <IngredientBox>
              <FilterType>
                <img src={foodtag} alt="Food Tag" />
                <p>Food Tag</p>
                <Tooltip title="Choose the Food Tag you want">
                  <IconButton>
                    <HelpOutlineIcon color="action" sx={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </FilterType>
              {keywords.map((item, i) => {
                return isFoodTagFormVisible[i] ? (
                  /* 열려있는 폼 */
                  <OpendFoodTagCategory
                    key={item.featureDetail}
                    color="#FFE5E5"
                  >
                    <CategoryHeader
                      onClick={() => handleFoodTagTitleClick(i)}
                      color="#f8dada"
                    >
                      <IngredientCategoryText>
                        {item.featureDetail}
                      </IngredientCategoryText>
                      <img src={drawerButton} alt="ingredient" />
                    </CategoryHeader>
                    <div className="divider"></div>
                    <KeywordContainer>
                      {item.featureValues.map((iitem) => (
                        <Keyword
                          item={iitem}
                          checkedItems={checkedItems}
                          setCheckedItems={setCheckedItems}
                          key={iitem}
                        />
                      ))}
                    </KeywordContainer>
                  </OpendFoodTagCategory>
                ) : (
                  /* 닫혀있는 폼 */
                  <ClosedFoodTagCategory
                    key={item.featureDetail}
                    onClick={() => handleFoodTagTitleClick(i)}
                  >
                    <IngredientCategoryText>
                      {item.featureDetail}
                    </IngredientCategoryText>
                    <img src={drawerButton} alt="ingredient" />
                  </ClosedFoodTagCategory>
                );
              })}
            </IngredientBox>

            <Divider className="container-divider" />

            <SubmitButton
              onClick={handleSubmit}
              type="submit"
              form="ingredients"
            >
              Search
            </SubmitButton>
          </FilterContainer>
        </div>
      )}
    </>
  );
};
const FilterType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  img {
    margin: 0 7px 0 3px;
  }
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const ResetBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  p {
    color: var(--main, #90a9f4);
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const MobileHeader = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    img {
      width: 12px;
    }
  }
`;

const HeaderDivider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 15px auto;
`;

const Overlay = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    display: ${(props) => (props.mobileFilterClick ? "block" : "none")};
    transition: 0.3s;
  }
`;
const OpendFoodTagCategory = styled.div`
  width: 232px;
  height: fit-content;
  margin: 5px 0;
  border-radius: 5px;
  background: #ffe5e5;
  padding: 13px 13px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    background: #f8dada;
    transition: 0.2s;
  }
  img {
    transform: rotate(180deg);
  }
  .divider {
    width: 218px;
    border-top: 1px solid rgba(255, 120, 120, 0.6);
    margin-bottom: 5px;
  }
`;

const ClosedFoodTagCategory = styled.div`
  width: 232px;
  height: 44px;
  margin: 5px 0;
  border-radius: 5px;
  background: #ffe5e5;
  padding: 0px 13px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 10px;
  }
  &:hover {
    background: #f8dada;
    transition: 0.2s;
  }
`;
const StyledLabel = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
`;
const CategoryContent = styled.div``;
const CategoryHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  img {
    width: 10px;
  }
  &:hover {
    background: ${(props) => props.color};
    transition: 0.2s;
  }
`;
const OpendIngredientCategory = styled.div`
  width: 232px;
  height: fit-content;
  margin: 5px 0;
  border-radius: 5px;
  background: #e3e8f8;
  padding: 13px 13px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    background: #d9e0f6;
    transition: 0.2s;
  }
  img {
    transform: rotate(180deg);
  }
  .divider {
    width: 218px;
    border-top: 1px solid rgba(131, 151, 211, 0.6);
    margin-bottom: 5px;
  }
`;

const IngredientCategoryText = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const ClosedIngredientCategory = styled.div`
  width: 232px;
  height: 44px;
  margin: 5px 0;
  border-radius: 5px;
  background: #e3e8f8;
  padding: 0px 13px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 10px;
  }
  &:hover {
    background: #d9e0f6;
    transition: 0.2s;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--main, #8397d3);
  border: none;
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #768bc7;
    transition: 0.3s;
  }
`;
const KeywordContainer = styled.div`
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
`;

const StyledFormGroup = styled(FormGroup)`
  display: grid;
  grid-template-columns: repeat(2, 80px);
  gap: 5px;
  width: 220px;
  padding: 5px 5px;
  display: ${(props) => (props.visible ? "block" : "none")};
  .ingredient-checkbox {
    width: 15px;
    height: 15px;
    background-color: white;
    margin: 0 8px;
    &hover {
      background-color: white;
    }
  }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100px;
  height: 30px;
  margin: 0;
`;

const IngredientBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  width: 260px;
  height: 80vh;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
  padding: 20px 25px 20px 13px;
  border-radius: 10px;
  background: #fff;
  .container-divider {
    margin: 30px 0;
  }
  /* Sticky behavior */
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px; /* The offset from the top where the element should start sticking */
  z-index: 9; /* Ensures it stays on top of other content */

  overflow-y: scroll;
  @media (max-width: ${breakpoints.mobile}) {
    position: fixed;

    padding: 20px 35px 20px 13px;
    top: 80px;
    right: -30px;
    z-index: 99;
    border-radius: 0px;
    display: ${(props) => (props.mobileFilterClick ? "block" : "none")};

    /* 크기 제한 및 스크롤 활성화 */
    max-height: 77vh; /* top 값에 따른 높이 조정 */

    overflow-y: auto; /* 세로 스크롤만 필요할 경우 */
  }
`;

export default FoodFilter;
