import React, { useEffect, useState } from "react";
import HeaderDiv from "../../components/HeaderDiv";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { authInstance } from "../../shared/Request";
import { StoreCard } from "./StoreCard";

import { KakaoMap } from "./KakaoMap";
import { breakpoints } from "../../styles/breakpoints";
import WithinKoreaNoti from "../../components/notification-popups/WithinKoreaNoti";

export const NearbyStore = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const [loading, setLoading] = useState(true);

  const [location, setLocation] = useState({ longitude: null, latitude: null });
  const [error, setError] = useState(null);
  const [stores, setStores] = useState([]);
  const [markedStore, setMarkedStore] = useState(null);

  const [showWithinKoreaNoti, setShowWithinKoreaNoti] = useState(false);

  useEffect(() => {
    getLocation();

    const intervalId = setInterval(() => {
      getLocation();
    }, 3000000); // 30초마다 getLocation 호출

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  console.log("선택된 가게: ", markedStore);

  //햔재 위치 받아오기
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          fetchLocation(latitude, longitude);
          setError("");
          // Call fetchLocation with the updated coordinates directly
          fetchLocation(latitude, longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setError("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              setError("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              setError("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              setError("An unknown error occurred.");
              break;
            default:
              setError("An unexpected error occurred while fetching location.");
              break;
          }
          console.log(error);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      //console.error(error);
    }
  };

  //가게 정보 요청
  const fetchLocation = async (latitude, longitude) => {
    setLoading(true);
    try {
      const params = {
        query: query,
        longitude: longitude,
        latitude: latitude,
      };
      const response = await authInstance.get(`/nearby-stores`, { params });
      console.log("Response:", response.data);

      //위치가 한국이 아닐경우 처리
      if (response.data.inKorea) {
        setStores(response.data.nearbyStores);
        setLoading(false);
      } else {
        setShowWithinKoreaNoti(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching 장소정보불러오기:", error);
      // Handle error
    }
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <HeaderDiv />
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          {showWithinKoreaNoti && (
            <WithinKoreaNoti onClose={() => setShowWithinKoreaNoti(false)} />
          )}
          <KakaoMap
            location={location}
            markedStore={markedStore}
            setMarkedStore={setMarkedStore}
            stores={stores}
          />

          <StoreContainer>
            {stores.map((store) => (
              <StoreCard
                store={store}
                markedStore={markedStore}
                setMarkedStore={setMarkedStore}
                key={store.id}
              />
            ))}
          </StoreContainer>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 1078px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px auto 0 auto;
  @media (max-width: ${breakpoints.mobile}) {
    width: 95vw;
  }
`;

const StoreContainer = styled.div`
  width: 1078px;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
