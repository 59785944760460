import React, { useState } from "react";
import styled from "@emotion/styled";

import search from "../../assets/icons/search.svg";
import cutlery from "../../assets/icons/cutlery.svg";

import filterIcon from "../../assets/icons/Filter_alt.svg";
import searchDelete from "../../assets/icons/search_delete.svg";

import { useMutation } from "react-query";
import { fetchSearchByName } from "../../apis/foodApi";
import { breakpoints } from "../../styles/breakpoints";

export const GuideHeader = ({
  domain,
  setSearchedFoodName,
  setMobileFilterClick,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const domainParam = domain.toLowerCase() + "s";
    mutation.mutate({ searchTerm, domainParam });
  };
  const mutation = useMutation(
    ({ searchTerm, domainParam }) => fetchSearchByName(searchTerm, domainParam),
    {
      onSuccess: (data) => {
        console.log("Name Search Response:", data);
        setSearchedFoodName(data.searchResult);
      },
      onError: (error) => {
        //console.error("Error submitting foodname search:", error);
      },
    }
  );

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
  };
  return (
    <GuideHeaderContainer>
      <Title>K-{domain} Guide</Title>
      <SubTitle>Check out all Korean {domain.toLowerCase()}s!</SubTitle>
      <form onSubmit={handleSubmit}>
        <SearchContainer>
          <IconWrapper>
            <img src={cutlery} alt="search" />
          </IconWrapper>
          <Input
            type="text"
            placeholder="Please enter your search term."
            value={searchTerm}
            onChange={handleChange}
          />

          <ButtonContianer>
            {searchTerm && (
              <ClearButton onClick={handleClear} type="button">
                <img src={searchDelete} alt="search" />
              </ClearButton>
            )}
            <SearchButton type="sumbit">
              <img src={search} alt="search" />
            </SearchButton>
          </ButtonContianer>
        </SearchContainer>
      </form>

      <SearchFilter onClick={() => setMobileFilterClick((prev) => !prev)}>
        <img src={filterIcon} alt="filter" />
        <p>Search Filter</p>
      </SearchFilter>
    </GuideHeaderContainer>
  );
};

const ButtonContianer = styled.div`
  width: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0px;
`;
const SearchFilter = styled.button`
  display: none;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: var(--main, #90a9f4);
  margin-top: 7px;
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:hover {
    background: #7b93dc;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
  }
`;

const SearchContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 670px;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  border-radius: 25px;
  border: 2px solid var(--main, #8397d3);
  background-color: white;

  @media (max-width: ${breakpoints.mobile}) {
    width: 330px;
    margin-top: 20px;

    justify-content: space-between;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  width: 80%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px 0px;
  ::placeholder {
    color: #aaa;
    text-align: start;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 200px;
  }
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: #d3d3d3;
  cursor: pointer;

  &:hover {
    color: #000;
  }
  @media (max-width: ${breakpoints.mobile}) {
  }
`;

const SearchButton = styled.button`
  background: none;
  border: none;

  color: #aaa;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;
const GuideHeaderContainer = styled.div`
  width: 700px;
  margin: 80px auto;
  background-color: rgba(243, 245, 251, 1);
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
  }
`;
const Title = styled.h1`
  color: #000;
  text-align: center;

  /* Headline */
  font-family: "Wanted Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 25px;
  }
`;

const SubTitle = styled.p`
  color: #000;
  text-align: center;

  /* slogan L tablet */
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 125% */

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;
