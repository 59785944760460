import React from "react";
import styled from "@emotion/styled";

import googleLogo from "../assets/images/google_logo.png";
import yahooLogo from "../assets/images/yahoo_logo.png";
import { breakpoints } from "../styles/breakpoints";

export const SocialLoginButton = ({ sns }) => {
  const handleLogin = () => {
    if (sns === "google") {
      window.location.href = process.env.REACT_APP_GOOGLE_AUTH_URL;
    } else if (sns === "yahoo") {
      window.location.href = process.env.REACT_APP_YAHOO_AUTH_URL;
    }
  };

  return (
    <Btn onClick={handleLogin} backgroundColor={"#ffffff"} color={"black"}>
      <BtnImg>
        <img src={sns === "google" ? googleLogo : yahooLogo} alt={sns} />
      </BtnImg>
    </Btn>
  );
};

const Btn = styled.button`
  width: 215px;
  height: 70px;
  border-radius: 10px;
  background: #f3f5fb;
  border: none;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    background: rgba(144, 169, 244, 0.5);
    transition: 0.2s;
  }
  &:active {
    border: 2px solid var(--main, #90a9f4);
    background: rgba(144, 169, 244, 0.5);
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 150px;
    height: 45px;

    border-radius: 5px;
  }
`;

const BtnImg = styled.div`
  img {
    height: 40px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    img {
      height: 26px;
    }
  }
`;
