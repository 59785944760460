import styled from '@emotion/styled';
import React from 'react'

export const HeaderDiv = () => {
  return (
    <Div></Div>
  )
}

const Div = styled.div`
  width: 100vw;
  height: 80px;
`;
export default HeaderDiv;