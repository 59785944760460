import React from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import { SocialLoginButton } from "../../components/SocialLoginButton";
import HeaderDiv from "../../components/HeaderDiv";
import { breakpoints } from "../../styles/breakpoints";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      <Wrapper>
        <LoginContainer>
          <TitleContainer>
            <Title>Log in</Title>
            <Description>Welcome back to Kori!</Description>
          </TitleContainer>
          <LoginForm />
          <SignUpContianer>
            <h1>New to Kori? </h1>
            <button onClick={() => navigate("/auth/sign-up")}>Sign up</button>
          </SignUpContianer>

          <SocialTextContainer>
            <h1>Or log in with</h1>
          </SocialTextContainer>
          <SocialLoginContainer>
            <SocialLoginButton sns={"google"} />
            <SocialLoginButton sns={"yahoo"} />
          </SocialLoginContainer>
        </LoginContainer>
      </Wrapper>
    </>
  );
};

const SocialTextContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
  }
`;
const SignUpContianer = styled.div`
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #dbdbdb;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
  }
  button {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
  }
`;
const SocialLoginContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const LoginContainer = styled.div`
  width: 530px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 260px;
    flex-shrink: 0;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
const Wrapper = styled.div`
  width: 95vw;
  height: 650px;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 530px;
  height: fit-content;
  padding: 0 0px 15px 45px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;

    padding: 0 0px 15px 8px;
  }
`;

export default Login;
