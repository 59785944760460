import { combineReducers, createStore } from 'redux'
import { persistReducer } from 'redux-persist';
import AuthReducer from './user'
//로컬스토리지
import storageSession from "redux-persist/es/storage/session";

const persistConfig = {
    key: "root",
    storage: storageSession,  // session storage에 저장.
}

const rootReducer = combineReducers({ user: AuthReducer });

const store = createStore(persistReducer(persistConfig, rootReducer));

export default store;