
// Redux에서 관리할 초기 상태
const initialState = {
  nickname: '',
}

// 액션 타입
const SET_USER = 'SET_USER';

// 액션 함수
export const setNickname = nickname => ({ 
  type: SET_USER, 
  nickname,
  
});

// 닉네임 가져오는 selector 함수
export const getNickname = (state) => state.user?.nickname || '';


// 리듀서
export default function AuthReducer(state = initialState, action) {
  switch(action.type) {
      case SET_USER:
          return {
              ...state,
              nickname: action.nickname,
          }
      default:
          return state;
  }
}