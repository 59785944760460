import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";

import Spinner from "../assets/images/Spin@1x-1.0s-200px-200px.gif";
import { useNavigate } from "react-router-dom";
import { breakpoints } from "../styles/breakpoints";
const RetryModal = ({ onClose }) => {
  return (
    <Overlay>
      <Modal>
        <Message>Please try again.</Message>
        <Button onClick={onClose}>Go Back</Button>
      </Modal>
    </Overlay>
  );
};

export const Loading = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 로딩 화면 표시 중에는 스크롤을 비활성화
    document.body.style.overflow = "hidden";

    const timer = setTimeout(() => {
      setShowModal(true);
    }, 10000); // 10 seconds

    return () => {
      // 컴포넌트 언마운트 시 스크롤 원복
      document.body.style.overflow = "auto";
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    setShowModal(false);
    navigate(-1);
  };

  return (
    <>
      {showModal && <RetryModal onClose={handleClose} />}
      <Background>
        <SpinnerImage src={Spinner} alt="Loading" />
        <LoadingText>Loading...</LoadingText>
      </Background>
    </>
  );
};

const SpinnerImage = styled.img`
  width: 10%;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100px;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;
const Modal = styled.div`
  background: white;
  padding: 20px 20px 50px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 80%;
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: #829ae4;
  color: #ffffff;
  font-family: "Wanted Sans";
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #738bd5;
  }
`;

const Message = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
`;

export const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled.div`
  text-align: center;
`;

export default Loading;
