import React, { useEffect, useState } from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { authInstance } from "../../../shared/Request";
import HeaderDiv from "../../../components/HeaderDiv";
import Loading from "../../../components/Loading";
import { ProfileEditHeader } from "./ProfileEditHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchHome } from "../../../apis/memberApi";

import { breakpoints } from "../../../styles/breakpoints";

export const ProfileImgEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { profileCharacter } = location.state;

  const [loading, setLoading] = useState(true);
  const [profileImgList, setProfileImgList] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    characterCode: "currentlySet",
    storageFileName: profileCharacter,
  });

  useEffect(() => {
    fetchProfileImgList();
  }, []);

  const { data, isLoading, error, refetch } = useQuery(["home"], fetchHome, {
    refetchOnWindowFocus: false, // Disable auto refetch on window focus (optional)
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const fetchProfileImgList = async () => {
    setLoading(true);
    try {
      const response = await authInstance.get("/characters");
      //console.log("Response:", response.data);
      setProfileImgList(response.data.characterList);
      setLoading(false);
    } catch (error) {
      //console.error("Error fetch Profile Img List:", error);
      // Handle error
    }
  };

  const handleChangeImage = (image) => {
    if (selectedImage === image) {
      setSelectedImage({
        characterCode: "currentlySet",
        storageFileName: profileCharacter,
      });
      return "";
    } else {
      setSelectedImage(image);
      return "selected";
    }
  };

  const handleClickFinish = async (e) => {
    e.preventDefault();
    if (selectedImage.characterCode === "currentlySet") {
      alert("There are no changes.");
    } else {
      try {
        const response = await authInstance.patch(`/member/profile/character`, {
          characterCode: selectedImage.characterCode,
        });
        console.log("프로필 사진 서버로 전송", response.data);
        refetch();
      } catch (error) {
        //console.error("Error sending Profile Image info to server:", error);
      } finally {
        navigate(-1);
      }
    }
  };
  return (
    <div>
      <HeaderDiv />

      <Global styles={GlobalStyles} />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <ProfileEditHeader headerTitle={"Edit profile image"} />
          <Wrapper>
            <ProfileChangeContainer>
              <CurrentImageContainer>
                <CurrentImageBox>
                  <ProfileImg>
                    <img
                      src={selectedImage.storageFileName}
                      alt={selectedImage.characterCode}
                    />
                  </ProfileImg>
                </CurrentImageBox>
              </CurrentImageContainer>
              <ImageListContainer>
                {profileImgList.map((image) => (
                  <ImageBox
                    key={image.characterCode}
                    onClick={() => handleChangeImage(image)}
                    className={selectedImage === image ? "selected" : ""}
                  >
                    <img
                      src={image.storageFileName}
                      alt={image.characterCode}
                    />
                  </ImageBox>
                ))}
              </ImageListContainer>
            </ProfileChangeContainer>
            <FinishBtn type="submit" onClick={(e) => handleClickFinish(e)}>
              <BtnText>Save</BtnText>
            </FinishBtn>
          </Wrapper>
        </div>
      )}
    </div>
  );
};
const FinishBtn = styled.button`
  margin-top: 36px;
  display: inline-flex;
  padding: 6px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 14.99px;
  border: none;
  background-color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 50px;
  }
`;

const BtnText = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;

const ProfileChangeContainer = styled.div`
  display: flex;
  gap: 14px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;
const ProfileImg = styled.div`
  width: 168px;
  height: 168px;
  border-radius: 168px;
  background: #ffffff;
  overflow: hidden;
  img {
    width: 168px;
    margin-top: 10px;
    transform: scale(1.5);
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 180px;
    height: 180px;
    border-radius: 180px;
    img {
      width: 180px;
      margin-top: 10px;
      transform: scale(1.7);
      overflow: hidden;
    }
  }
`;
const CurrentImageContainer = styled.div`
  width: 360px;
  height: 297px;
  border-radius: 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    height: 180px;
  }
`;

const ImageListContainer = styled.div`
  width: 50vw;
  height: 297px;
  border-radius: 15px;
  background: #fff;
  padding: 20px 35px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
  overflow-y: scroll;
  .selected {
    border: 3px solid var(--main, #90a9f4);
    background: rgba(144, 169, 244, 0.3);
    &:hover {
      background: rgba(144, 169, 244, 0.5);
      transition: 0.2s;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 170px;
    padding: 0px;
    gap: 9px;
    overflow: hidden;
  }
`;

const ImageBox = styled.div`
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 14px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
    transition: 0.2s;
  }
  img {
    margin-top: 18px;
    transform: scale(1.5);
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 78px;
    height: 78px;

    border-radius: 10px;
  }
`;

const CurrentImageBox = styled.div`
  width: 168px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 168px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @media (max-width: ${breakpoints.mobile}) {
    width: 180px;
    height: 180px;
    border-radius: 180px;
  }
`;

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
    }
  }
`;
const Wrapper = styled.div`
  gap: 14px;
  display: flex;
  width: 1100px;
  margin: 20px auto 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 610px;
    margin: 0px auto 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
