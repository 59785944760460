import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import mapIcon from "../../assets/icons/Map-gray.svg";
import PinIcon from "../../assets/icons/Pin_fill.svg";
import phoneIcon from "../../assets/icons/Phone_fill.svg";
import copyIcon from "../../assets/icons/Copy_alt.svg";
import mapMoreIcon from "../../assets/icons/map-more.svg";
import { AddressCard } from "./AddressCard";
import { copyToClipboard } from "../../utils/copyToClipboard";

import Toast from "../../components/notification-popups/Toast";
import { breakpoints } from "../../styles/breakpoints";

export const StoreCard = ({ store, setMarkedStore, markedStore }) => {
  const [addressIsOpen, setAddressIsOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [containerClassName, setContainerClassName] = useState("");

  useEffect(() => {
    if (markedStore) {
      if (markedStore.id === store.id) {
        setContainerClassName("marked");
      } else {
        setContainerClassName("");
      }
    } else {
      setContainerClassName("");
    }
  }, [markedStore]);
  const closeToast = () => {
    setToastMessage("");
  };
  const handleShowStoreClick = () => {
    window.location.href = store.place_url;
  };

  const handleGetDirectionClick = () => {
    window.location.href = `https://map.kakao.com/link/to/${store.place_name},${store.y},${store.x}`;
  };

  return (
    <Wrapper>
      <CardContainer className={containerClassName}>
        {toastMessage && <Toast message={toastMessage} onClose={closeToast} />}
        <CardHeader>
          <StoreName>
            <h1>{store.place_name}</h1>
          </StoreName>
          {markedStore === store ? (
            <PinBtn
              onClick={() => setMarkedStore(null)}
              bgColor="#90A9F4"
              color="#ffffff"
            >
              <p>On map</p>
            </PinBtn>
          ) : (
            <PinBtn
              onClick={() => setMarkedStore(store)}
              bgColor="#ffffff"
              color="#90A9F4"
            >
              <p>On map</p>
            </PinBtn>
          )}
        </CardHeader>

        <StoreContentDiv>
          <StoreContentIcon src={PinIcon} alt="PinIcon" />
          {store.road_address_name !== "" ? (
            <StoreContent>{store.road_address_name}</StoreContent>
          ) : (
            <StoreContent>{store.address_name}</StoreContent>
          )}
          <AddressMore
            onClick={() => setAddressIsOpen((cur) => !cur)}
            src={mapMoreIcon}
            alt="mapMoreIcon"
            addressIsOpen={addressIsOpen}
          />
        </StoreContentDiv>

        {/* 상세 주소 팝업 */}
        {addressIsOpen ? (
          <AddressCard
            addressName={store.address_name}
            roadAddressName={store.road_address_name}
            setToastMessage={setToastMessage}
            setAddressIsOpen={setAddressIsOpen}
          />
        ) : null}

        <StoreContentDiv>
          <StoreContentIcon src={mapIcon} alt="mapIcon" />
          {store.distance !== "" ? (
            <>
              <StoreContent>{store.distance}</StoreContent>
            </>
          ) : (
            <StoreContent>정보가 없습니다</StoreContent>
          )}
        </StoreContentDiv>

        <StoreContentDiv>
          <StoreContentIcon src={phoneIcon} alt="phoneIcon" />
          {store.phone !== "" ? (
            <>
              <StoreContent>{store.phone}</StoreContent>
              <CopyIcon
                onClick={() => copyToClipboard(store.phone, setToastMessage)}
                src={copyIcon}
                alt="copyIcon"
              />
            </>
          ) : (
            <StoreContent>정보가 없습니다</StoreContent>
          )}
        </StoreContentDiv>

        <ButtonContainer>
          <DirectionGuidanceBtn onClick={handleGetDirectionClick}>
            <p>Show Directions</p>
          </DirectionGuidanceBtn>
          <StoreDetailBtn onClick={handleShowStoreClick}>
            <p>View Store</p>
          </StoreDetailBtn>
        </ButtonContainer>
      </CardContainer>
    </Wrapper>
  );
};
const PinBtn = styled.button`
  width: 62px;
  height: 18px;
  border-radius: 2px;
  background: ${(props) => props.bgColor};

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  p {
    color: ${(props) => props.color};
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-weight: 500;
  }
  &:hover {
    background: var(--main, #839deb);
    transition: 0.2s;
    p {
      color: #ffffff;
    }
  }
`;
const Wrapper = styled.div`
  .marked {
    border: 2px solid var(--main, #90a9f4);
    background: linear-gradient(
        0deg,
        rgba(144, 169, 244, 0.3) 0%,
        rgba(144, 169, 244, 0.3) 100%
      ),
      #fff;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 190px;
  }
`;
const CardContainer = styled.div`
  width: 260px;
  height: 188px;
  border-radius: 5px;
  padding: 20px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid var(--main, #ffffff);
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 190px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
`;

const StoreName = styled.div`
  width: 160px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  h1 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const StoreContentDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 7px;
  margin-bottom: 8px;
`;
const StoreContentIcon = styled.img`
  width: 24px;
`;
const StoreContent = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const AddressMore = styled.img`
  transform: ${(props) => (props.addressIsOpen ? "rotate(180deg)" : "none")};
  transition: 0.2s;
`;
const CopyIcon = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 80%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8.5px;
`;
const DirectionGuidanceBtn = styled.button`
  width: 105px;
  height: 22px;
  border-radius: 2px;
  background: #fff;
  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  p {
    color: var(--main, #90a9f4);
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:hover {
    background: var(--main, #e7e7e7);
    transition: 0.2s;
  }
`;

const StoreDetailBtn = styled(DirectionGuidanceBtn)`
  background: var(--main, #90a9f4);
  p {
    color: #fff;
  }
  &:hover {
    background: var(--main, #839deb);
    transition: 0.2s;
  }
`;
