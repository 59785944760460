/* eslint-disable*/
import AppRoutes from "./routes";

const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
