import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
      primary: {
        main: "#90A9F4",
        light: "#a4b9f8",
  	    dark: "#7f9aea",
      },
      secondary:{
        main: "#F57D7D",
      }
    }
});