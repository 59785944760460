import React, { useState } from "react";

import { breakpoints } from "../../../styles/breakpoints";
import showPasswordIcon from "../../../assets/icons/show-password.svg";
import hidePasswordIcon from "../../../assets/icons/hide-password.svg";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { defaultInstance } from "../../../shared/Request";
import InputLael from "../../login/InputComponents/InputLabel";

const CheckCurrentPassword = ({ setIsPasswordValid, setOldPassword }) => {
  const [isSubmissive, setIsSubmissive] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleChangePassword = (e) => {
    const password = e.target.value;
    if (password.length > 8) {
      setIsSubmissive(true);
    } else {
      setIsSubmissive(false);
    }
    setPassword(password);
  };
  const handleNextClick = async (e) => {
    e.preventDefault();
    //성공시
    try {
      const response = await defaultInstance.post("/member/profile/password", {
        password: password,
      });
      console.log("비밀번호 검증 완료", response.data);
      setIsPasswordValid(true);
      setOldPassword(password);
    } catch (error) {
      if (error.response) {
        const { detail, status } = error.response.data;
        // console.error(
        //   `Error during password check: ${detail} (Status: ${status})`
        // );

        if (status === 404) {
          setError("The password is incorrect.");
        } else {
        }
      }
    }
  };

  return (
    <div>
      <Form>
        <InputLael id="password" errors={error} />
        <EmailInputContainer>
          <InputContainer>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => handleChangePassword(e)}
              required
              minLength={1}
              maxLength={32}
            />

            <button
              type="button"
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
              onMouseLeave={() => setShowPassword(false)}
            >
              <ShowPasswordIcon>
                {showPassword ? (
                  <img src={showPasswordIcon} alt="show password" />
                ) : (
                  <img src={hidePasswordIcon} alt="show password" />
                )}
              </ShowPasswordIcon>
            </button>
          </InputContainer>
        </EmailInputContainer>
      </Form>
      <ResetPassword onClick={() => navigate("/auth/reset-password")}>
        Forgot Password?
      </ResetPassword>
      <NextBtn
        onClick={handleNextClick}
        isSubmissive={isSubmissive}
        disabled={!isSubmissive}
      >
        <p>Next</p>
      </NextBtn>
    </div>
  );
};
const ShowPasswordIcon = styled.div`
  margin-right: 16px;
`;
const NextBtn = styled.button`
  width: 440px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  background: ${(props) =>
    props.isSubmissive ? "var(--main, #90a9f4)" : "#AAA"};
  transition: background-color 0.2s ease;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const ResetPassword = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin: 15px 0;
  cursor: pointer;
`;

const Form = styled.div``;
const EmailInputContainer = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    gap: 5px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 15px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }
  @media (max-width: ${breakpoints.mobile}) {
    padding: 15px 0px 15px 15px;
    ::placeholder {
      color: #aaa;
      font-family: "Wanted Sans";
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 440px;
  height: 50px;
  border-radius: 5px;
  background: #f3f5fb;
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;

export default CheckCurrentPassword;
