import React, { useState } from "react";

import styled from "@emotion/styled";
import { ProfileEditHeader } from "../profile-edit-components/ProfileEditHeader";
import HeaderDiv from "../../../components/HeaderDiv";
import { breakpoints } from "../../../styles/breakpoints";

import { Global, css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import CheckCurrentPassword from "./CheckCurrentPassword";
import ChangePassword from "./ChangePassword";

const PasswordManager = () => {
  const type = "CHANGE";
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [oldPassword, setOldPassword] = useState("");

  return (
    <div>
      <HeaderDiv />
      <ProfileEditHeader headerTitle="Change password" />
      <Global styles={GlobalStyles} />
      <Wrapper>
        {!isPasswordValid ? (
          <CheckCurrentPassword
            setIsPasswordValid={setIsPasswordValid}
            setOldPassword={setOldPassword}
          />
        ) : (
          <ChangePassword type={type} oldPassword={oldPassword} />
        )}
      </Wrapper>
    </div>
  );
};

export default PasswordManager;

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
    }
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 95vw;
  min-height: 290px;
  height: fit-content;
  border-radius: 10px;
  padding: 64px 0px;
  margin: 0 auto 200px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 610px;
    margin: 0 auto 0px auto;
    align-items: start;
    justify-content: center;
  }
`;
