import React, { useState } from "react";
import styled from "@emotion/styled";
import shareIcon from "../../assets/icons/share_icon.svg";

import mapIcon from "../../assets/icons/mapIcon.svg";
import mapImage from "../../assets/images/map_Seoul.png";
import { useNavigate, Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { TagDiv } from "../../components/TagDiv";
import { FavoriteBtn } from "../../components/FavoriteBtn";
import { breakpoints } from "../../styles/breakpoints";
import KoriComment from "./KoriComment";

import breadIcon from "../../assets/icons/Breadcrumb_arrow.svg";

export const ContentContainer = ({ domain, food, domainId }) => {
  const navigate = useNavigate();
  const [clickCount, setClickCount] = useState(food.favoriteCount);
  console.log(domain);
  const currentUrl = window.location.href;

  const handleCopyLink = () => {
    // 현재 페이지 URL 가져오기
    navigator.clipboard
      .writeText(currentUrl) // 클립보드에 URL 복사
      .then(() => {
        alert("Link copied!"); // 복사 성공 알림
      })
      .catch((error) => {
        //console.error("Failed to copy!", error);
      });
  };

  return (
    <Container>
      <BreadcrumbContainer>
        <BreadcrumbLink to={`/k-${domain.slice(0, -1)}-guide`}>
          {`K-${domain.slice(0, -1)} guide`}
        </BreadcrumbLink>
        <img src={breadIcon} alt="next" />
        <BreadcrumbLink to={`/k-${domain.slice(0, -1)}-guide`}>
          {food.domainDetailType}
        </BreadcrumbLink>
        <img src={breadIcon} alt="next" />
        <BreadcrumbLink to={currentUrl} className="now">
          {food.egName}
        </BreadcrumbLink>
      </BreadcrumbContainer>

      <ImgContainer>
        <BigImg>
          <img src={food.imgLink} alt={food.egName} />
        </BigImg>
      </ImgContainer>

      <ContentsContainer>
        <FoodTitle>
          <EgName>{food.egName}</EgName>
          <NameDivider></NameDivider>
          <KrName>{food.krName}</KrName>

          <FavoriteBtnContainer>
            <ShareBtn onClick={handleCopyLink}>
              <img src={shareIcon} alt="share" />
            </ShareBtn>

            <FavoriteBox>
              <FavoriteBtn
                domainId={domainId}
                favoriteDomainId={food.favoriteDomainId}
                setClickCount={setClickCount}
                mobileWidth="20px"
              />
              <p>{clickCount}</p>
            </FavoriteBox>
          </FavoriteBtnContainer>
        </FoodTitle>

        <FoodSubTitle>{food.translatedName}</FoodSubTitle>

        <Divider></Divider>
        {domain === "places" ? null : (
          <IngredientsContainer>
            <CategoryName>What's in this {domain.slice(0, 5)}?</CategoryName>
            <TagsContainer>
              {food.ingredients.map((item, i) => (
                <TagDiv
                  key={i}
                  value={item}
                  background="#FFF"
                  color="#000000"
                />
              ))}
            </TagsContainer>
          </IngredientsContainer>
        )}

        <FoodTagsContainer>
          <CategoryName>Keywords for this {domain.slice(0, 5)}</CategoryName>
          <TagsContainer>
            {food.keywords.map((item) => (
              <TagDiv
                value={`#${item}`}
                background={"#8397D3"}
                color={"#FFF"}
                key={item}
              />
            ))}
          </TagsContainer>
        </FoodTagsContainer>
        <BottomContents>
          <MapContainer>
            <MapBtn
              onClick={() =>
                navigate(`/foods/${food.domainId}/nearby?query=${food.krName}`)
              }
            >
              <MapIcon src={mapIcon} alt="mapIcon" />
              <p>Nearby {domain === "foods" ? "restaurants" : domain}</p>
            </MapBtn>
          </MapContainer>
          <KoriComment comment={food.briefReview} />
        </BottomContents>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 1100px;
  height: 473px;
  max-height: 500px;
  padding: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: start;
  gap: 10px;
  margin: 42px auto 0 auto;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: fit-content;
    max-height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-radius: 5px;
  }
`;

const BreadcrumbContainer = styled.nav`
  display: flex;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 10px;
  gap: 8px;
  .now {
    color: #000;
    font-weight: 700;
  }
`;

const BreadcrumbLink = styled(Link)`
  color: var(--tag, #7a7a7a);
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    color: #000;
  }
`;

const BottomContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 12px;
`;
const ShareBtn = styled.button`
  gap: 10px;
  width: 18px;
  border: none;
  background: none;
  img {
    width: 18px;
  }
`;

const FavoriteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    height: 21px;
    flex-shrink: 0;
  }
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    p {
      font-size: 10px;
      margin: 4px 0 0 12px;
    }
  }
`;
const FavoriteBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  position: absolute;
  gap: 15px;
  right: 0;
  top: 0px;
  @media (max-width: ${breakpoints.mobile}) {
    right: 10px;
    top: 0px;
  }
`;

const MapIcon = styled.img`
  width: 15px;
  height: 12px;
`;
const MapContainer = styled.div`
  position: relative;
  margin: 24px auto 0px auto;
  width: 100%;
  height: 140px;
  border-radius: 5px;
  border: 1px solid var(--gray, #d3d3d3);
  background: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(1.5px);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.02);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${mapImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1.5px); /* 블러 강도 설정 */
    z-index: -1; /* 배경보다 뒤로 */
  }

  @media (max-width: ${breakpoints.mobile}) {
    height: 100px;
  }
`;
const MapBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 165px;
  display: flex;
  padding: 11px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  gap: 4px;

  p {
    color: var(--main, #90a9f4);
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-wrap: break-word;
  }
  &:hover {
    background: #f4f4f4;
    transition: 0.2s;
  }
`;

const ContentsContainer = styled.div`
  box-sizing: border-box;
  width: 440px;
  height: 470px;
  flex-shrink: 0;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 330px;
    height: 100%;

    padding-bottom: 230px;
  }
`;
const ImgContainer = styled.div`
  height: 100%;
  @media (max-width: ${breakpoints.mobile}) {
    overflow: hidden;
  }
`;

const BigImg = styled.div`
  width: 622px;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  img {
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    height: 240px;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }
`;

const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3px 5px;
`;

const IngredientsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 5px;
  margin-top: 16px;
`;
const FoodTagsContainer = styled(IngredientsContainer)`
  height: fit-content;

  margin-top: 16px;
`;

const FoodTitle = styled.div`
  display: flex;
  align-items: center;
`;

const NameDivider = styled.div`
  height: 12px;
  margin: 0 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`;
const EgName = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const KrName = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const FoodSubTitle = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
`;

const CategoryName = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 125% */
  margin-bottom: 3px;
`;
