import React, { useState } from "react";
import styled from "@emotion/styled";
import getRandomPastelColor from "../../utils/getRandomPastelColor";

import { FavoriteBtn } from "../../components/FavoriteBtn";
import { useNavigate } from "react-router-dom";

export const TodaysCard = ({ domain = "", className, food }) => {
  const [clickCount, setClickCount] = useState(food.favoriteCount);
  const navigate = useNavigate();
  const handleIngredientsLength = () => {
    let text = "";
    food.ingredients.forEach((item, i, row) => {
      if (i + 1 === row.length) {
        text += item;
      } else {
        text += item + ",\u00a0";
      }
    });
    return text;
  };

  const handleDetailClick = () => {
    navigate(`/${domain}/${food.domainId}/reviews`);
  };

  return (
    <CardWrapper
      className={className}
      imgSrc={food.imgLink}
      onClick={handleDetailClick}
    >
      <FoodInfoContainer>
        <TextBox>
          <FoodName>{food.egName}</FoodName>
          <Comment>{food.translatedName}</Comment>
        </TextBox>
        <FavoriteBox>
          <FavoriteBtn
            domainId={food.domainId}
            favoriteDomainId={food.favoriteDomainId}
            white={true}
            setClickCount={setClickCount}
          />
          <p>{clickCount}</p>
        </FavoriteBox>
      </FoodInfoContainer>

      <DetailButton className={className + "-detail-button"}>
        detail
      </DetailButton>

      <FeatureContainer className={className + "-feature-container"}>
        {domain === "foods" ? (
          <FoodIngredient>
            <h1>Ingredient : </h1>
            <h2>{handleIngredientsLength()}</h2>
          </FoodIngredient>
        ) : null}

        <FoodKeyword>
          <h1>Tag : </h1>
          {food.keywords.map((item, i) => {
            if (i === 2) {
              return null;
            } else {
              return (
                <Keyword key={i} pastelColor={getRandomPastelColor()}>
                  <h2>{"#" + item}</h2>
                </Keyword>
              );
            }
          })}
        </FoodKeyword>
      </FeatureContainer>
    </CardWrapper>
  );
};

const FoodKeyword = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    font-size: 14px;
    margin: 0 10px 5px 0;
    color: #fff;

    /* Caption */
    font-family: "Wanted Sans";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 10px 5px 0;
  }
`;

const Keyword = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${(props) => props.pastelColor};
  margin: 0 5px 5px 0;
  overflow: hidden;
  &:hover {
    background-color: #dedede;
    transition: 0.3s;
  }
  h2 {
    color: #000;

    /* contents bold */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const FoodIngredient = styled.div`
  display: flex;
  align-items: baseline;
  h1 {
    font-size: 14px;
    margin: 0 10px 5px 0;
    color: #fff;

    /* Caption */
    font-family: "Wanted Sans";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;

    /* contents bold */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const FeatureContainer = styled.div`
  display: flex;
  width: 85%;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9.515px;
  border: 1px solid var(--white-blender-line, rgba(255, 255, 255, 0.5));
  background: var(--white-blender, rgba(255, 255, 255, 0.25));

  /* white blender */
  backdrop-filter: blur(2.6px);

  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0;
`;
const FoodInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px 25px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const FavoriteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 24px;
    height: 21px;
    flex-shrink: 0;
  }
  .favorite-border-icon {
    filter: brightness(0) invert(1);
  }
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const CardWrapper = styled.div`
  position: relative;
  background-color: #61667e;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${(props) => `${props.imgSrc}`}) lightgray -25px -20px / 115% 109%
      no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  position: relative;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url(${(props) => `${props.imgSrc}`}) lightgray -25px -20px / 115% 109%
        no-repeat;
    background-position: center;
    background-size: cover;
    .small-card-detail-button {
      opacity: 1;
      transition: ease-in-out 0.3s;
    }
    .big-card-feature-container {
      opacity: 1;
      transition: ease-in-out 0.3s;
    }
  }
`;

const FoodName = styled.h1`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 25px;
  font-weight: 700;
`;
const Comment = styled.h2`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 14px;
  font-weight: 500;
`;
const DetailButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 14px;
  border-radius: 15px;
  border: 1px solid var(--white-blender-line, rgba(255, 255, 255, 0.5));
  background: var(--white-blender, rgba(255, 255, 255, 0.25));
  color: white;
  /* white blender */
  backdrop-filter: blur(2px);
  opacity: 0;
  cursor: pointer;
`;
