import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import HeaderDiv from "../../../components/HeaderDiv";
import { breakpoints } from "../../../styles/breakpoints";
import { useNavigate } from "react-router-dom";
import ResetEmailVerifyForm from "./ResetEmailVerifyForm";
import ChangePassword from "../../mypage/change-password/ChangePassword";

const ResetPassword = () => {
  const navigate = useNavigate();
  const type = "RESET";
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      <Wrapper>
        <LoginContainer>
          <TitleContainer>
            <Title>Reset password</Title>
            <Description>
              Please enter your email address to get a verification code.
            </Description>
          </TitleContainer>
          {!isEmailValid ? (
            <ResetEmailVerifyForm
              setIsEmailValid={setIsEmailValid}
              code={code}
              setCode={setCode}
            />
          ) : (
            <ChangePassword type={type} code={code} />
          )}
        </LoginContainer>
      </Wrapper>
    </>
  );
};

const LoginContainer = styled.div`
  width: 530px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 260px;
    flex-shrink: 0;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
const Wrapper = styled.div`
  width: 95vw;
  height: 470px;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    box-sizing: border-box;
    width: 340px;
    height: 622px;

    align-items: start;
    padding-top: 130px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 530px;
  height: fit-content;
  padding: 0 0px 15px 45px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;

    padding: 0 0px 15px 8px;
  }
`;

export default ResetPassword;
