import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { defaultInstance } from "../../shared/Request";
import Loading from "../../components/Loading";
import { ReviewCard } from "../../components/review/ReviewCard";
import CountrySeleter from "./CountrySeletor";

import ImageEmpty from "../../assets/icons/image_only_empty.png";
import ImageFull from "../../assets/icons/image_only_full.png";
import bottomArrowIcon from "../../assets/icons/right-arrow-white.svg";
import { breakpoints } from "../../styles/breakpoints";

export const UserReview = ({ foodName }) => {
  const { domainId } = useParams();

  const [reviews, setReviews] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [sort, setSort] = useState("date");
  const [country, setCountry] = useState("NA");
  const [imageOnly, setImageOnly] = useState(false);

  const [loadingReviews, setLoadingReviews] = useState(false);

  useEffect(() => {
    fetchUserReview();
  }, [sort, country, imageOnly]);

  const fetchUserReview = async () => {
    setLoadingReviews(true);
    const params = {
      page: currentPage,
      sort: sort,
      nationality: country.value,
      img: imageOnly,
    };
    try {
      const response = await defaultInstance.get(
        `/domains/${domainId}/reviews`,
        { params }
      );
      console.log("유저 리뷰 데이터: ", response.data);
      setReviews(response.data.reviewsOfDomain);
      setMetaData(response.data.metaData);
    } catch (error) {
      //console.error("Error fetching User Reviews", error);
    } finally {
      setLoadingReviews(false);
    }
  };

  const fetchUserReviewPage = async () => {
    setLoadingReviews(true);
    const params = {
      page: currentPage,
      sort: sort,
      nationality: country.value,
      img: imageOnly,
    };
    console.log(params);
    try {
      const response = await defaultInstance.get(
        `/domains/${domainId}/reviews`,
        { params }
      );
      console.log(`리뷰 데이터 ${currentPage} 페이지: `, response.data);
      setReviews((prevReviews) => [
        ...prevReviews,
        ...response.data.reviewsOfDomain,
      ]);
      setMetaData(response.data.metaData);
    } catch (error) {
      //console.error("Error fetching User Reviews", error);
    } finally {
      setLoadingReviews(false);
    }
  };
  const handleNextPage = () => {
    if (!loadingReviews && metaData.hasNext) {
      const scrollPosition = window.scrollY;

      setCurrentPage((current) => current + 1);

      fetchUserReviewPage().then(() => {
        window.scrollTo(0, scrollPosition);
      });
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title>'{foodName}' Review</Title>
        <SubTitle>‘{foodName}’ Food review from users.</SubTitle>
      </TitleContainer>
      <SortContainer>
        <DateThumSortContainer>
          <DateThumSort
            className={sort === "date" ? "active" : ""}
            onClick={() => setSort("date")}
          >
            Latest
          </DateThumSort>
          <span className="middle-point">·</span>
          <DateThumSort
            className={sort === "thumb" ? "active" : ""}
            onClick={() => setSort("thumb")}
          >
            Most popular
          </DateThumSort>
        </DateThumSortContainer>
        <CountryImageSortContainer>
          <CountrySeleter country={country} setCountry={setCountry} />
          <ImageSort>
            <ImageCheckBox
              onClick={() => setImageOnly((prev) => !prev)}
              src={imageOnly ? ImageFull : ImageEmpty}
            ></ImageCheckBox>
            <p>Image reviews</p>
          </ImageSort>
        </CountryImageSortContainer>
      </SortContainer>
      {loadingReviews ? (
        <Loading />
      ) : (
        reviews && (
          <ReviewContaner>
            {/* 이미지리뷰 only */}
            {reviews.map((review, i) => (
              <ReviewCard
                key={i + review.reviewContent.domainReviewId}
                domain
                review={review}
                setReviews={setReviews}
                isMypage={false}
              />
            ))}
            <MoreBtn onClick={handleNextPage} disabled={loadingReviews}>
              {metaData && metaData.hasNext ? (
                <>
                  <p>Show more</p>
                  <img src={bottomArrowIcon} alt="bottomArrowIcon" />
                </>
              ) : (
                <p>Last page</p>
              )}
            </MoreBtn>
          </ReviewContaner>
        )
      )}
    </Container>
  );
};
const MoreBtn = styled.button`
  display: inline-flex;
  padding: 8px 35px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin: 10px 0;

  /* 버튼 쉐이드 */
  border-radius: 15px;
  background: var(--light-gray, #d3d3d3);
  &:hover {
    background: var(--main, #b7b7b7);
    transition: 0.2s;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  img {
    margin-top: 3px;
    rotate: 90deg;
  }
`;
const ImageSort = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5.5px;
  margin-bottom: 0%;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const ImageCheckBox = styled.img``;

const DateThumSort = styled.span`
  padding: 5px 0px 5px 0px;
  color: var(--gray, #aaa);
  font-family: "Wanted Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
  &.active {
    color: #000;
    font-weight: 700;
  }
`;
const DateThumSortContainer = styled.div`
  .middle-point {
    margin: 0 5px;
    font-size: 15px;
    color: var(--gray, #aaa);
  }
`;
const CountryImageSortContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;

    gap: 5px;
    align-items: start;
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const SortContainer = styled.div`
  width: 100%;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: end;
  justify-content: space-between;
  @media (max-width: ${breakpoints.mobile}) {
    align-items: start;
  }
`;
const ReviewContaner = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #b8b8b8;
    transition: all 0.5s ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #919191;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 320px;
    height: 650px;
  }
`;
const Container = styled.div`
  width: 727px;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;

  @media (max-width: ${breakpoints.mobile}) {
    box-sizing: border-box;
    width: 340px;
    height: fit-content;
    max-height: 810px;
  }
`;

const Title = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;
const SubTitle = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
`;
