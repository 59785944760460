import React from "react";
import styled from "@emotion/styled";

export const TagDiv = ({ value, background, color }) => {
  return (
    <Tags background={background} color={color}>
      <p>{value}</p>
    </Tags>
  );
};
const Tags = styled.div`
  padding: 4px 8px;
  width: fit-content;
  border-radius: 9px;
  border: 0.5px solid #e7e7e7;
  background: ${(props) => props.background || "#FFF"};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  text-align: center;
  p {
    color: ${(props) => props.color || "#000000"};
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
`;
