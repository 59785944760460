import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import FavoriteBorderIcon from "../assets/icons/FavoriteBorderIcon.svg";
import FavoriteIcon from "../assets/icons/FavoriteIcon.svg";
import { authInstance } from "../shared/Request";
import { useQuery } from "react-query";
import { fetchTodayFoods } from "../apis/mainApi";
import LoginRequired from "./notification-popups/LoginRequired";
import { breakpoints } from "../styles/breakpoints";

import * as Sentry from "@sentry/react";

export const FavoriteBtn = ({
  width = "20px",
  mobileWidth = "16px",
  domainId,
  favoriteDomainId,
  white,
  setClickCount,
}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [favoriteId, setFavoriteId] = useState(favoriteDomainId);
  const [animate, setAnimate] = useState(false);
  const { refetch } = useQuery("todaysFood", fetchTodayFoods);

  const handleFavoriteClick = async (event) => {
    event.stopPropagation();
    console.log("favoriteId", favoriteId);
    console.log("domainId", domainId);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500); // Reset animation after it finishes
    try {
      if (favoriteId) {
        await deleteFavoriteFood();
      } else {
        await postFavoriteFood();
      }
      refetch();
    } catch (error) {
      //console.error("Error handling favorite click:", error);
      Sentry.captureException(error);
    }
  };

  const postFavoriteFood = async () => {
    const data = {
      domainId: domainId,
    };
    try {
      const response = await authInstance.post(`/member/favorites`, data);
      console.log(domainId, "FavoriteFood 전달완료");

      setClickCount(response.data.favoriteCount);
      setFavoriteId(response.data.favoriteDomainId);
      console.log(response.data);
    } catch (error) {
      setPopupVisible(true);
      //console.error("Error sending Favorite Food to server:", error);
      console.clear();
      Sentry.captureException(error);
    }
  };

  const deleteFavoriteFood = async () => {
    try {
      const response = await authInstance.delete(
        `/member/favorites/${favoriteId}`,
        {
          data: {
            domainId: domainId,
          },
        }
      );
      console.log(domainId, "FavoriteFood 삭제완료");
      console.log(response.data);
      setClickCount(response.data.favoriteCount);
      setFavoriteId(0);
    } catch (error) {
      //console.error("Error sending Delete Favorite Food to server:", error);
      console.clear();
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {favoriteId !== 0 ? (
        <FavoriteButton
          onClick={handleFavoriteClick}
          className={animate ? "explode" : ""}
          width={width}
          mobileWidth={mobileWidth}
        >
          <img src={FavoriteIcon} alt="FavoriteIcon" />
        </FavoriteButton>
      ) : (
        <FavoriteButton
          onClick={handleFavoriteClick}
          width={width}
          mobileWidth={mobileWidth}
        >
          {white ? (
            <img
              className="white-border"
              src={FavoriteBorderIcon}
              alt="FavoriteBorderIcon"
            />
          ) : (
            <img src={FavoriteBorderIcon} alt="FavoriteBorderIcon" />
          )}
        </FavoriteButton>
      )}

      {isPopupVisible && (
        <LoginRequired onClose={() => setPopupVisible(false)} />
      )}
    </>
  );
};

const pop = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const FavoriteButton = styled.button`
  background: none;
  border: none;
  width: 40px;
  height: 28px;
  color: #a7a7a7;
  cursor: pointer;
  .white-border {
    filter: brightness(0) invert(1);
  }

  &.explode {
    animation: ${pop} 0.5s ease-out forwards;
  }
  img {
    width: ${(props) => props.width};
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: ${(props) => props.mobileWidth};
    height: ${(props) => props.mobileWidth};
    img {
      width: ${(props) => props.mobileWidth};
      height: ${(props) => props.mobileWidth};
    }
  }
`;
