import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setNickname } from "../../../modules/user";
import { defaultInstance } from "../../../shared/Request";

const SocialLoginCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNickname = async () => {
      try {
        const response = await defaultInstance.get("/member/home");
        dispatch(setNickname(response.data.nickname));
        console.log("이미 가입된 회원 로그인 완료");
        navigate("/");
      } catch (error) {
        //console.error("Error fetching user nickname:", error);
        navigate("/");
      }
    };
    fetchNickname();
  }, [navigate]);

  return (
    <div>
      <p>로그인 성공</p>
    </div>
  );
};

export default SocialLoginCallback;
