import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import FoodFilter from "./PlaceFilter";
import HeaderDiv from "../../components/HeaderDiv";

import Loading from "../../components/Loading";
import { useQuery } from "react-query";
import { fetchDefaultDomains } from "../../apis/foodApi";
import { GuideHeader } from "../k-food/GuideHeader";
import { CardContainer } from "../k-food/CardContainer";

import filterIcon from "../../assets/icons/Filter_alt.svg";
import cutlery from "../../assets/icons/cutlery_black.svg";
import { DefaultFoodCard } from "../k-food/DefaultFoodCard";
import { breakpoints } from "../../styles/breakpoints";

export const KplaceGuide = () => {
  const DOMAIN = "places";
  const [mobileFilterClick, setMobileFilterClick] = useState(false);
  const [searchedFoodName, setSearchedFoodName] = useState(null);
  const {
    isLoading,
    isError,
    data: FoodsData,
    error,
  } = useQuery("allPlaces", () => fetchDefaultDomains(DOMAIN), {
    onError: (e) => {
      // 실패시 호출 (401, 404 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
      console.log(e.message);
    },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      <Global styles={GlobalStyles} />
      <HeaderDiv />
      <GuideHeader
        domain="Place"
        setSearchedFoodName={setSearchedFoodName}
        setMobileFilterClick={setMobileFilterClick}
      />
      <Wrapper>
        <FoodFilter
          mobileFilterClick={mobileFilterClick}
          setMobileFilterClick={setMobileFilterClick}
        />
        <FoodContainer>
          {
            // 장소 이름 검색 결과가 있으면 디폴트 카드
            searchedFoodName ? (
              <>
                <FoodTypeText>
                  <img src={cutlery} alt="cutlery" />
                  <p>This is the result of the search.</p>
                </FoodTypeText>
                <FoodTypeDiv>
                  {searchedFoodName.map((item, i) => {
                    return (
                      <DefaultFoodCard
                        key={item.domainId + `${i}`}
                        domain={DOMAIN}
                        food={item}
                        apiPath={item.apiPath}
                      />
                    );
                  })}
                </FoodTypeDiv>
                <NoMoreContent>
                  <p>No more content</p>
                </NoMoreContent>
                <Divider />
              </>
            ) : null
          }
          {
            //디폴트 카드 보여주기
            FoodsData.map((domainType, i) => {
              return (
                <CardContainer
                  domain={DOMAIN}
                  domainType={domainType}
                  key={domainType.apiPath}
                />
              );
            })
          }
        </FoodContainer>
        <SearchFilter onClick={() => setMobileFilterClick((prev) => !prev)}>
          <img src={filterIcon} alt="filter" />
        </SearchFilter>
      </Wrapper>
    </>
  );
};

const SearchFilter = styled.div`
  display: none;
  position: fixed;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--main, #90a9f4);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: ${breakpoints.mobile}) {
    display: inline-flex;
    bottom: 73px;
    right: 10px;
  }
`;
const NoMoreContent = styled.div`
  display: flex;
  padding: 5px 34px;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
  gap: 10px;
  border-radius: 12.5px;
  background: #d3d3d3;
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background: #c1c1c1;
    transition: ease-in-out 0.2s;
  }
`;
const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
    }
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px auto;
  border-top: 1px solid black;
  opacity: 0.2;
`;

const FoodTypeText = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 0 10px;
  p {
    margin-left: 10px;
    color: #000;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: ${breakpoints.mobile}) {
    p {
      font-size: 15px;
    }
    img {
      width: 26px;
    }
  }
`;
const Wrapper = styled.div`
  width: 1100px;
  height: fit-content;
  margin: 0px auto;
  display: flex;
  justify-content: end;
  gap: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 400px;
    gap: 0px;
  }
`;

const FoodContainer = styled.div`
  width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0px 13px;
  align-items: flex-start;
  border-radius: 10px;
  background: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;
const FoodTypeDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: ${breakpoints.mobile}) {
    gap: 8px;
  }
`;
