import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import WriteReviewIcon from "../../assets/icons/write_reviews_white.svg";

import arrow from "../../assets/icons/left_arrow_black.svg";
import { breakpoints } from "../../styles/breakpoints";
import BackCustomNoti from "../../components/notification-popups/BackCustomNoti";
import LoginRequired from "../../components/notification-popups/LoginRequired";
import { authInstance } from "../../shared/Request";
import CustomNoti from "../../components/notification-popups/CustomNoti";

// 다섯개 이상일 때 처리 나중에 하기

const TagStatistics = ({ data, domainId }) => {
  const navigate = useNavigate();
  const [whiteOverlay, setWhiteOverlay] = useState("");

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);

  useEffect(() => {
    if (data.tagFrequencyOfDomain.length >= 5) {
      setWhiteOverlay("on");
    } else {
      setWhiteOverlay("");
    }
  }, []);

  const totalCount = data.tagFrequencyOfDomain.reduce(
    (acc, tag) => acc + tag.count,
    0
  );
  // Function to determine bar color based on count
  const getColor = (index) => {
    const opacity = 1 - index / 10;
    return `rgba(131, 151, 211, ${opacity})`;
  };

  const fetchIsReviewWritten = async () => {
    try {
      const response = await authInstance.head(
        `/member/reviews?domainId=${domainId}`
      );
      if (response.status === 200) {
        setPopupVisible(true);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // Proceed to the next code (e.g., navigating to the review creation form)
        setIsLoginPopupVisible(true);
      } else if (err.response && err.response.status === 404) {
        // Proceed to the next code (e.g., navigating to the review creation form)
        console.log("중복아님! 작성가능");
        navigate(`/foods/${domainId}/reviews/new`);
      } else {
        // Handle other errors
      }
    }
  };

  return (
    <>
      {isPopupVisible && (
        <CustomNoti
          onClose={() => setPopupVisible(false)}
          message="You've already written a review for it."
        />
      )}

      {isLoginPopupVisible && (
        <LoginRequired onClose={() => setIsLoginPopupVisible(false)} />
      )}
      <Container>
        <Title>Keywords Review</Title>
        <SubTitle>Keywords review from users</SubTitle>
        <ReviewBtn onClick={fetchIsReviewWritten}>
          <img src={WriteReviewIcon} alt="WriteReviewIcon" />
          <p>Write a Review</p>
        </ReviewBtn>
        <TitleDivider></TitleDivider>
        {data.tagFrequencyOfDomain.map((tag, index) => (
          <BarContainer key={index}>
            <Bar color={getColor(index)} width={(tag.count / totalCount) * 100}>
              <TextDiv>
                <Emoji>{tag.emoji}</Emoji>
                <TagText>{tag.tagValue}</TagText>
              </TextDiv>
              <CountText>{tag.count.toString().padStart(2, "0")}</CountText>
            </Bar>
          </BarContainer>
        ))}
      </Container>
    </>
  );
};

const TextDiv = styled.div`
  display: flex;
  position: absolute;
`;
const OverlayWhite = styled.div`
  width: 350px;
  height: 134px;
  border-radius: var(--Number, 0px) var(--Number, 0px) 5px 5px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  display: none;
  justify-content: center;
  align-items: end;

  position: absolute;
  bottom: 0;
  z-index: 2;
  img {
    width: 10px;
    transform: rotate(270deg);
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 330px;
  }
`;
const TitleDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const ReviewBtn = styled.button`
  width: 340px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background: #90a9f4;
  display: flex;
  justify-content: center;
  align-items: center;

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background: #809bea;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 322px;
    height: 50px;
  }
`;
const Container = styled.div`
  box-sizing: border-box;
  width: 360px;
  height: fit-content;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  .on {
    display: flex;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: fit-content;
  }
`;

const Title = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2px;
`;
const SubTitle = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
`;

const BarContainer = styled.div`
  box-sizing: border-box;
  width: 340px;
  height: 40px;
  margin: 5px 0;
  display: flex;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 322px;
    height: 30px;
  }
`;

const Bar = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;

const TagText = styled.span`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 5px;
`;

const CountText = styled.span`
  position: absolute;
  right: 0px;
  padding-right: 10px;
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Emoji = styled.div`
  font-size: 18px;
  margin-left: 10px;
`;

export default TagStatistics;
