import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import HeaderDiv from "../../components/HeaderDiv";
import { breakpoints } from "../../styles/breakpoints";
import { useNavigate } from "react-router-dom";

import googleLogo from "../../assets/images/google_logo.png";
import yahooLogo from "../../assets/icons/yahoo-logo.png";

import emailLogo from "../../assets/icons/email-signup-blue.png";
import Policy from "../../components/Policy";

export const SignupTypeSelection = () => {
  const navigate = useNavigate();
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const signUpList = [
    { sns: "Email", img: emailLogo, linkTo: "email" },
    { sns: "Google", img: googleLogo },
    { sns: "Yahoo", img: yahooLogo },
  ];
  const handleSignUpClick = (sns) => {
    switch (sns) {
      case "Google":
        window.location.href = process.env.REACT_APP_GOOGLE_AUTH_URL;
        break;
      case "Yahoo":
        window.location.href = process.env.REACT_APP_YAHOO_AUTH_URL;
        break;
      case "Email":
        navigate("email");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      {isPolicyOpen ? <Policy setIsPolicyOpen={setIsPolicyOpen} /> : null}
      <Wrapper>
        <LoginContainer>
          <TitleContainer>
            <Title>Sign Up</Title>
            <Description>
              By proceeding, you agree to our{" "}
              <span onClick={() => setIsPolicyOpen(true)}>policy.</span>
            </Description>
          </TitleContainer>
          <SignUpBtnContainer>
            {signUpList.map((sns) => {
              return (
                <BtnBox id={sns.sns} onClick={() => handleSignUpClick(sns.sns)}>
                  <LogoImage src={sns.img} alt={sns.sns} className={sns.sns} />
                  <SignUpText>
                    Sign up with <span>{sns.sns}</span>
                  </SignUpText>
                </BtnBox>
              );
            })}
          </SignUpBtnContainer>
          <SignInContianer>
            <h1>Already have an account?</h1>
            <button onClick={() => navigate("/auth/sign-in")}>Log in</button>
          </SignInContianer>
        </LoginContainer>
      </Wrapper>
    </>
  );
};

const SignUpText = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    font-weight: 700;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
const LogoImage = styled.img`
  height: 22px;
`;

const BtnBox = styled.div`
  width: 440px;
  height: 50px;
  border-radius: 10px;
  background: var(--back-blue, #f3f5fb);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  transition: ease-in-out 0.2s;

  border: 2px solid var(--main, #f3f5fb);
  .Yahoo {
    height: 26px;
  }

  &:hover {
    background: rgba(144, 169, 244, 0.5);
  }
  &:active {
    border: 2px solid var(--main, #90a9f4);
    background: rgba(144, 169, 244, 0.5);
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 45px;
  }
`;
const SignUpBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const SignInContianer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
  }
  button {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    h1 {
      font-size: 9px;
    }
    button {
      font-size: 9px;
    }
  }
`;
const LoginContainer = styled.div`
  width: 530px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 260px;
    flex-shrink: 0;
    margin-bottom: 80px;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
const Wrapper = styled.div`
  width: 95vw;
  height: 450px;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
`;
const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 530px;
  height: fit-content;
  padding: 0 0px 15px 45px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    padding: 0 0px 15px 8px;
  }
`;

export default SignupTypeSelection;
