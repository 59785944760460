export const getRandomPastelColor = () => {
  // Generate a random number between 0 and 255
  const getRandomValue = () => Math.floor(Math.random() * 256);

  // Increase the influence of white by averaging with a higher constant value
  const higherValue = 300;

  // Generate a random RGB color and ensure it is brighter by averaging with the higher value
  const r = Math.floor((getRandomValue() + higherValue) / 2);
  const g = Math.floor((getRandomValue() + higherValue) / 2);
  const b = Math.floor((getRandomValue() + higherValue) / 2);

  // Ensure the values don't exceed 255
  const clampValue = (value) => Math.min(value, 255);

  const finalR = clampValue(r);
  const finalG = clampValue(g);
  const finalB = clampValue(b);

  // Convert the RGB values to a hex string
  const pastelColor = `rgba(${finalR}, ${finalG}, ${finalB}, 1)`;
  
  return pastelColor;
}

export default getRandomPastelColor;