import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import HeaderDiv from "../../components/HeaderDiv";

import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { ContentContainer } from "./ContentContainer";
import { defaultInstance } from "../../shared/Request";
import Loading from "../../components/Loading";
import TagStatistics from "./TagStatistic";
import { UserReview } from "./UserReview";
import { WriteReview } from "../../components/review/WriteReview";
import { css, Global } from "@emotion/react";
import { CompleteReview } from "../../components/review/CompleteReview";
import { UpdateReview } from "../../components/review/UpdateReview";
import { breakpoints } from "../../styles/breakpoints";

export const DetailPage = () => {
  const { domainId } = useParams();
  console.log(domainId);

  const location = useLocation(); // 현재 URL을 가져옴
  const domain = location.pathname.split("/")[1];

  const [food, setFood] = useState(null);
  const [reviewStatistics, setReviewStatistics] = useState(null);

  const [loadingFood, setLoadingFood] = useState(false);
  const [loadingReviewStats, setLoadingReviewStats] = useState(false);

  useEffect(() => {
    fetchDetailType();
    fetchReviewTagStatistic();
  }, []);

  //음식 디테일 정보 api 데이터 받아오기
  const fetchDetailType = async () => {
    setLoadingFood(true);
    try {
      const response = await defaultInstance.get(`/detail/foods/${domainId}`);
      console.log("디테일 데이터: ", response.data);
      setFood(response.data);
      if (domain === "places") {
      }
    } catch (error) {
      //console.error("Error fetching food Detail Type", error);
    } finally {
      setLoadingFood(false); // Ensure loading is set to false in both success and error cases
    }
  };

  const fetchReviewTagStatistic = async () => {
    setLoadingReviewStats(true);
    try {
      const response = await defaultInstance.get(
        `/domains/${domainId}/review-statistics`
      );
      console.log("리뷰 통계 데이터: ", response.data);
      setReviewStatistics(response.data);
    } catch (error) {
      //console.error("Error fetching Review Tag Statistic", error);
    } finally {
      setLoadingReviewStats(false);
    }
  };

  return (
    <Container>
      <Global styles={GlobalStyles} />
      <HeaderDiv />
      {loadingFood || loadingReviewStats ? (
        <Loading />
      ) : (
        food && (
          <Wrapper>
            <ContentContainer
              domain={domain}
              food={food}
              reviewStatistics={reviewStatistics}
              domainId={domainId}
            />
            <ReviewContainer>
              <TagStatistics data={reviewStatistics} domainId={domainId} />
              <Routes>
                <Route
                  path="reviews"
                  element={
                    <UserReview doamin={domain} foodName={food.egName} />
                  }
                />
                <Route
                  path="reviews/new"
                  element={
                    <WriteReview
                      foodName={food.egName}
                      detailType={food.detailApiPath}
                    />
                  }
                />
                <Route
                  path="reviews/new/complete"
                  element={<CompleteReview foodName={food.egName} />}
                />
                <Route
                  path="reviews/update"
                  element={
                    <UpdateReview foodName={food.egName} doamin={domain} />
                  }
                />
              </Routes>
            </ReviewContainer>
          </Wrapper>
        )
      )}
    </Container>
  );
};
const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
    }
  }
`;

const Container = styled.div`
  background: #f3f5fb;
  @media (max-width: ${breakpoints.mobile}) {
    background: #ffffff;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    gap: 10px;
  }
`;
const ReviewContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  margin: 15px auto;
  gap: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
  }
`;
