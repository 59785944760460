import React from "react";

import styled from "@emotion/styled";
import { breakpoints } from "../../../styles/breakpoints";

const InputLael = ({ id, errors, isSendCodeDisabled = false }) => {
  return (
    <LabelContainer>
      <Label htmlFor="email">
        {id.charAt(0).toUpperCase() + id.slice(1).replace(/-/g, " ")}
      </Label>
      {errors[id] && <ErrorMessage>{errors[id]}</ErrorMessage>}
      {isSendCodeDisabled && (
        <ErrorMessage>Please try again in 1 minute.</ErrorMessage>
      )}
    </LabelContainer>
  );
};

const LabelContainer = styled.div`
  width: 440px;
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
  margin-bottom: 4px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;

    align-items: end;
  }
`;
const Label = styled.label`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

const ErrorMessage = styled.p`
  width: 310px;
  color: #ff0000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  @media (max-width: ${breakpoints.mobile}) {
    width: 220px;
  }
`;

export default InputLael;
