import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  Map,
  MapMarker,
  ZoomControl,
  CustomOverlayMap,
} from "react-kakao-maps-sdk";

import mapPinIcon from "../../assets/icons/map-pin.svg";
import mapPinNoIcon from "../../assets/icons/map-pin-no.svg";
import mapPinMarkedIcon from "../../assets/icons/map-pin-marked.svg";

import miniPin from "../../assets/icons/mini-pin.svg";
import { breakpoints } from "../../styles/breakpoints";

import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";

export const KakaoMap = ({ location, markedStore, setMarkedStore, stores }) => {
  const mapRef = useRef();
  const [state, setState] = useState({
    center: { lat: location.latitude, lng: location.longitude }, // 지도의 초기 위치
    isPanto: true, // 부드럽게 이동
  });

  //console.log(stores);
  useEffect(() => {
    if (markedStore !== null) {
      setState({
        center: { lat: markedStore.y, lng: markedStore.x },
        isPanto: true,
      });
    }
  }, [markedStore]);

  const handleClickMarker = (store) => {
    setMarkedStore(store);
  };
  return (
    <MapWrapper id="map">
      <TooltipContainer>
        <Tooltip title="Please use the website's translation to convert the Korean text.">
          <IconButton>
            <HelpOutlineIcon sx={{ color: "#ffffff", fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </TooltipContainer>
      <Map
        center={state.center} // 지도 중심 좌표
        isPanto={state.isPanto}
        style={{ width: "100%", height: "100%" }} // 지도 크기
        level={4} // 지도 확대 레벨
      >
        {markedStore ? (
          <>
            {stores.map((store, i) => {
              //현재 스토어가 마크되어 있으면 선택 핀

              if (store.id !== markedStore.id) {
                return (
                  <CustomOverlayMap
                    position={{ lat: store.y, lng: store.x }}
                    zIndex={9}
                  >
                    <CustomMarker onClick={() => handleClickMarker(store)}>
                      <img src={mapPinNoIcon} alt="Marker" />
                    </CustomMarker>
                  </CustomOverlayMap>
                );
              } else if (store.id === markedStore.id) {
                return (
                  <CustomOverlayMap
                    position={state.center}
                    zIndex={99} // Add zIndex here
                  >
                    <CustomMarker onClick={() => handleClickMarker(store)}>
                      <img src={mapPinMarkedIcon} alt="Marker" />
                    </CustomMarker>
                    <InfoWindow>
                      <a
                        href={`https://map.kakao.com/link/to/${markedStore.place_name},${markedStore.y},${markedStore.x}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StoreName>{markedStore.place_name}</StoreName>
                      </a>
                      <AddressBox>
                        <img src={miniPin} alt="Marker" />
                        <Address>
                          <p>{markedStore.road_address_name}</p>
                        </Address>
                      </AddressBox>
                    </InfoWindow>
                  </CustomOverlayMap>
                );
              }
            })}
          </>
        ) : (
          <>
            {stores.map((store, i) => {
              return (
                <CustomOverlayMap position={{ lat: store.y, lng: store.x }}>
                  <CustomMarker onClick={() => handleClickMarker(store)}>
                    <p>{i + 1}</p>
                    <img src={mapPinIcon} alt="Marker" />
                  </CustomMarker>
                </CustomOverlayMap>
              );
            })}
          </>
        )}

        <ZoomControl position={"RIGHT"} />
      </Map>
    </MapWrapper>
  );
};

const TooltipContainer = styled.div`
  top: 15px;
  left: 15px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--red, #ff7878);

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;
const StoreName = styled.h2`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const AddressBox = styled.div`
  display: flex;
  align-items: start;
  gap: 5px;
`;

const Address = styled.div`
  width: 120px;
  white-space: normal;
  margin: auto 0px;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const CustomMarker = styled.div`
  width: 40px;
  height: 60px;
  position: relative;
  img {
  }
  p {
    position: absolute;

    top: 34%;
    left: 46%;
    transform: translate(-50%, -50%);
    color: var(--main, #90a9f4);

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const MapWrapper = styled.div`
  width: 1078px;
  height: 450px;
  transition: 0.2s;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 250px; // 모바일에서 높이도 조정 가능
  }
  overflow: hidden;
`;
const InfoWindow = styled.div`
  box-sizing: border-box;
  width: 169px;
  height: 80px;
  padding: 15px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -90px;
  left: -60px;
  z-index: 999;
`;
