import React, { useEffect, useState } from "react";
import { authInstance, defaultInstance } from "../../shared/Request";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import HeaderDiv from "../../components/HeaderDiv";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import warningIcon from "../../assets/icons/warning-gray.svg";
import rightArrow from "../../assets/icons/rigth_arrow_icon.svg";

import CountryFlag from "react-country-flag";
import CustomNoti from "../../components/notification-popups/CustomNoti";
import { breakpoints } from "../../styles/breakpoints";
import { ProfileEditHeader } from "./profile-edit-components/ProfileEditHeader";

export const ProfileEdit = () => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();

  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await authInstance.get("/member/profile");
        //console.log("Response:", response.data);
        setUserInfo(response.data);
        setLoading(false);
      } catch (error) {
        //console.error("Error fetching user data:", error);
        // Handle error
      }
    };
    fetchUserData();
  }, []);

  const handleProfileImgEdit = () => {
    const profileCharacter = userInfo.profileCharacter;
    navigate("/mypage/profile-edit/profile-img", {
      state: { profileCharacter },
    });
  };

  const handleNationalityClick = () => {
    if (userInfo.nationality === "NA")
      navigate(`/mypage/profile-edit/nationality/${userInfo.nationality}`);
    else {
      alert("Nationality can only be set once during the initial setup.");
    }
  };
  const handleChangePasswordClick = async () => {
    try {
      const response = await defaultInstance.head("/member/profile/password");
      if (response.status === 200) {
        // 이메일 가입 계정일 때
        navigate("change-password");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // 소셜로그인 계정일 때 404
        setPopupVisible(true);
      } else {
        // 그 외 응답 처리
        //console.error("An error occurred:", error.message);
      }
    }
  };

  return (
    <div>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      <ProfileEditHeader headerTitle={"Edit profile"} />
      {isPopupVisible && (
        <CustomNoti
          onClose={() => setPopupVisible(false)}
          message={"The password cannot be changed for social login accounts."}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Wrapper>
            <UserInfo>
              <ProfileImg>
                <img
                  src={userInfo.profileCharacter}
                  alt={userInfo.nickname}
                  width="168px"
                />
              </ProfileImg>
              <EditBtn onClick={handleProfileImgEdit}>
                <p>Edit image</p>
              </EditBtn>
            </UserInfo>

            <CategoryContainer>
              <Category>
                <InnerContent
                  onClick={() =>
                    navigate(
                      `/mypage/profile-edit/nickname/${userInfo.nickname}`
                    )
                  }
                >
                  <InnerContentTitle>Nickname</InnerContentTitle>
                  <CountContianer>
                    <Count>{userInfo.nickname}</Count>
                    <img src={rightArrow} alt="right_arrow" />
                  </CountContianer>
                </InnerContent>
                <Divider></Divider>
                <InnerContent>
                  <InnerContentTitle>
                    E-mail
                    <InnerContentWarning>
                      <img src={warningIcon} alt="warningIcon" />
                      <p>This is unchangeable.</p>
                    </InnerContentWarning>
                  </InnerContentTitle>
                  <CountContianer>
                    <Count>{userInfo.platformEmail}</Count>
                  </CountContianer>
                </InnerContent>
                <Divider></Divider>
                <InnerContent onClick={handleNationalityClick}>
                  <InnerContentTitle>
                    Nationality
                    <InnerContentWarning>
                      <img src={warningIcon} alt="warningIcon" />
                      <p>Once it is set, it can’t be changed. </p>
                    </InnerContentWarning>
                  </InnerContentTitle>
                  <CountContianer>
                    {userInfo.nationality === "NA" ? (
                      <img src={rightArrow} alt="right_arrow" />
                    ) : (
                      <>
                        <CountryFlag
                          countryCode={userInfo.nationality}
                          svg
                          style={{
                            width: 30,
                            marginRight: 5,
                            borderRadius: 2,
                          }}
                        />
                      </>
                    )}
                  </CountContianer>
                </InnerContent>
              </Category>
              <Category>
                <InnerContent onClick={handleChangePasswordClick}>
                  <InnerContentTitle>Change password</InnerContentTitle>
                  <CountContianer>
                    <img src={rightArrow} alt="right_arrow" />
                  </CountContianer>
                </InnerContent>
                <Divider></Divider>
                <InnerContent>
                  <InnerContentTitle>
                    Linked social media account
                  </InnerContentTitle>
                  <CountContianer>
                    <Count>{userInfo.platformName}</Count>
                    <img src={rightArrow} alt="right_arrow" />
                  </CountContianer>
                </InnerContent>
              </Category>
            </CategoryContainer>
            <DeleteAccount onClick={() => navigate("delete")}>
              <p>delete account</p>
            </DeleteAccount>
          </Wrapper>
        </>
      )}
    </div>
  );
};

const DeleteAccount = styled.div`
  position: absolute;
  bottom: -23px;
  left: 50%;
  p {
    color: #aaa;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
  @media (max-width: ${breakpoints.mobile}) {
    position: inherit;
    bottom: 0;
    left: 0;
    margin-top: 10px;
    p {
      font-size: 10px;
    }
  }
`;
const InnerContentWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    color: var(--gray, #aaa);
    font-family: "Wanted Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 3px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 10px;
  }
`;

const InnerContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 3px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;

const CountContianer = styled.div`
  position: absolute;
  right: 32px;
  display: flex;
  align-items: center;
  img {
    width: 11px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    right: 18px;
    img {
      width: 8px;
    }
  }
`;
const Count = styled.p`
  color: var(--gray, #aaa);
  text-align: right;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;

    margin-right: 5px;
  }
`;

const InnerContent = styled.button`
  position: relative;
  width: 723px;
  background-color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  padding: 28px 24px;
  &:hover {
    background-color: #f2f2f2;
    transition: 0.3s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 15px 17px;
  }
`;
const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
const CategoryContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 290px;
    gap: 10px;

    justify-content: start;
  }
`;
const Category = styled.div`
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    border-radius: 10px;
  }
`;

const ProfileImg = styled.div`
  width: 168px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 168px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  img {
    transform: scale(1.7);
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 10px;
    img {
      width: 110px;
      height: 110px;
    }
  }
`;
const EditBtn = styled.button`
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--main, #90a9f4);
  border: none;
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  cursor: pointer;
  &:hover {
    background-color: #7c97ea;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin: 13px 0 30px 0;
    p {
      font-size: 12px;
    }
  }
`;
const UserInfo = styled.div`
  width: 360px;
  height: 100%;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    background: none;
    width: 100%;
    height: 180px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    margin-bottom: 20px;
    gap: 0px;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  gap: 14px;
  display: flex;
  width: 1100px;
  height: 428px;
  margin: 20px auto 100px auto;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;
    flex-direction: column;

    gap: 0px;
    margin-bottom: 40px;
    align-items: center;
  }
`;

const Divider = styled.div`
  width: 95%;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 auto;
`;
