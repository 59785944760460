import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import HeaderDiv from "../../components/HeaderDiv";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { defaultInstance } from "../../shared/Request";

import { setNickname } from "../../modules/user";
import { InformationInput } from "./InformationInput";
import { InformationCheck } from "./InformationCheck";

const SignupProfileDetails = () => {
  const { code } = useParams();

  console.log("회원가입 페이지 code:", code);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userNickname, setUserNickname] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("NA");
  const [isActive, setIsActive] = useState(true);

  const [page, setPage] = useState("Input");

  const handleSignUpClick = () => {
    if (userNickname.length < 1) {
      alert("Please enter a nickname.");
    } else {
      setPage("Check");
    }
  };
  const handleSignUp = async (event) => {
    event.preventDefault();
    console.log(userNickname);
    console.log("country_value: ", country);

    if (!userNickname) {
      setMessage("Please generate or enter a nickname first.");
      alert("Please enter a nickname.");
      return;
    }
    try {
      let data = {
        nickname: userNickname,
        nationality: country.value,
        code,
        subcribe: isActive ? true : false,
      };
      if (country === "NA") {
        data = {
          nickname: userNickname,
          nationality: country,
          code: code,
          subcribe: isActive ? true : false,
        };
      }
      console.log(data, "전달전");

      const response = await defaultInstance.post(`/members`, data);
      console.log(data, "전달완료");

      setMessage("Nickname successfully sent to the server!");
      dispatch(setNickname(response.data.nickname));
      navigate("/");
    } catch (error) {
      console.log(userNickname);
      //console.error("Error sending nickname to server:", error);
      setMessage("Failed to send nickname to the server.");
      console.log(message);
      alert("Sign-up failed.");
    }
  };

  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      {page === "Input" ? (
        <InformationInput
          userNickname={userNickname}
          setUserNickname={setUserNickname}
          country={country}
          setCountry={setCountry}
          handleSignUpClick={handleSignUpClick}
        />
      ) : (
        <InformationCheck
          userNickname={userNickname}
          country={country}
          setPage={setPage}
          handleSignUp={handleSignUp}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      )}
    </>
  );
};
const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  .country-select {
    width: 100%;
    padding: 20px 15px 15px 15px;
    border: none;
    background: F3F5FB;
    border-radius: 4px;
    font-size: 16px;
  }
`;

export default SignupProfileDetails;
