import { defaultInstance } from "../shared/Request";

export const fetchTodayFoods = async () => {
  return await defaultInstance
    .get("/today/foods")
    .then((res) => res.data.todayDomainRecommendationList);
};

export const fetchTodayPlaces = async () => {
  return await defaultInstance
    .get("/today/places")
    .then((res) => res.data.todayDomainRecommendationList);
};

export const fetchTodayReviews = async () => {
  return await defaultInstance
    .get("/today/reviews")
    .then((res) => res.data.todayReviewRecommendationList);
};
