import React, { useState } from "react";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";

import { SocialLoginButton } from "../../components/SocialLoginButton";
import HeaderDiv from "../../components/HeaderDiv";
import { breakpoints } from "../../styles/breakpoints";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import Policy from "../../components/Policy";
import SignUpForm from "./SignUpForm";

const SignupCredentials = () => {
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      {isPolicyOpen ? <Policy setIsPolicyOpen={setIsPolicyOpen} /> : null}

      <Wrapper>
        <LoginContainer>
          <TitleContainer>
            <Title>Sign up</Title>
            <Description>
              By proceeding, you agree to our{" "}
              <span onClick={() => setIsPolicyOpen(true)}>policy.</span>
            </Description>
          </TitleContainer>
          <SignUpForm />
        </LoginContainer>
      </Wrapper>
    </>
  );
};

const LoginContainer = styled.div`
  width: 530px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    padding-top: 70px;
  }
`;

const GlobalStyles = css`
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;
const Wrapper = styled.div`
  width: 95vw;
  height: 622px;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  span {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
`;
const TitleContainer = styled.div`
  box-sizing: border-box;
  width: 530px;
  height: fit-content;
  padding: 0 0px 15px 45px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;

    padding: 0 0px 15px 8px;
  }
`;

export default SignupCredentials;
