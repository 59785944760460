import React from "react";
import styled from "@emotion/styled";

import backArrowIcon from "../../assets/icons/back-arrow-black.svg";
import { useNavigate } from "react-router-dom";

export const FavoriteHeader = ({ headerTitle }) => {
  const navigate = useNavigate();
  return (
    <Header>
      <BackBtn onClick={() => navigate(-1)}>
        <img src={backArrowIcon} alt="backArrowIcon" />
      </BackBtn>
      <HeaderTitle>{headerTitle}</HeaderTitle>
      <BackBtn></BackBtn>
    </Header>
  );
};
const Header = styled.div`
  width: 95vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
`;

const BackBtn = styled.div``;

const HeaderTitle = styled.h1`
  color: #000;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
