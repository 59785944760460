import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import HeaderDiv from "../../components/HeaderDiv";
import { defaultInstance } from "../../shared/Request";
import { FoodCard } from "../k-food/FoodCard";
import Loading from "../../components/Loading";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { fetchFavoriteFoods } from "../../apis/foodApi";
import { useQuery } from "react-query";
import { DefaultFoodCard } from "../k-food/DefaultFoodCard";
import { FavoriteHeader } from "./FavoriteHeader";

export const FavoriteFoods = () => {
  const TYPE = "foods";
  const {
    isLoading,
    isError,
    data: FoodsData,
    error,
  } = useQuery("favoriteFoods", () => fetchFavoriteFoods(TYPE), {
    onError: (e) => {
      console.log(e.message);
    },
  });

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      <HeaderDiv />
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <FavoriteHeader headerTitle="Favorite Foods" />
          <Wrapper>
            <FoodContainer>
              {FoodsData.map((item, index) => {
                return (
                  <FoodTypeWrapper key={index}>
                    <FoodTypeText>
                      <RestaurantIcon />
                      <p>{item.foodType}</p>
                    </FoodTypeText>
                    <FoodTypeDiv>
                      {item.foodList.map((iitem) => {
                        return (
                          <DefaultFoodCard key={iitem.domainId} food={iitem} />
                        );
                      })}
                    </FoodTypeDiv>
                    <Divider />
                  </FoodTypeWrapper>
                );
              })}
            </FoodContainer>
          </Wrapper>
        </div>
      )}
    </>
  );
};
const FoodTypeWrapper = styled.div`
  width: 100%;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px auto;
  border-top: 1px solid black;
  opacity: 0.2;
`;

const FoodTypeText = styled.div`
  margin: 5px 0 0 10px;
  display: flex;
  p {
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
  }
`;
const Wrapper = styled.div`
  width: 90vw;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0 78px;
`;

const FoodContainer = styled.div`
  width: 1050px;
  height: fit-content;
  padding-top: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
`;
const FoodTypeDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
`;
