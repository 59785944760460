import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderDiv from "../../../components/HeaderDiv";

const DeletedMember = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderDiv />
      <button onClick={() => navigate("/")}>홈으로 가기</button>

      <button onClick={() => navigate("/")}>홈으로 가기</button>

      <button onClick={() => navigate("/")}>홈으로 가기</button>

      <button onClick={() => navigate("/")}>홈으로 가기</button>
    </div>
  );
};

export default DeletedMember;
