import React, { useState } from "react";
import styled from "@emotion/styled";

import { defaultInstance } from "../../shared/Request";
import { FavoriteBtn } from "../../components/FavoriteBtn";
import FoodModal from "./FoodModal";
import Loading from "../../components/Loading";
import { breakpoints } from "../../styles/breakpoints";

export const FoodCard = ({ domain, food, searchLoading }) => {
  console.log(food);
  const [open, setOpen] = useState(false);
  const [clickCount, setClickCount] = useState(food.favoriteCount);

  const [reviewStatistics, setReviewStatistics] = useState(null);
  const [modalData, setModalData] = useState(null);

  const [loadingReviewStats, setLoadingReviewStats] = useState(true);
  const [loadingModalData, setLoadingModalData] = useState(true);

  //리뷰통계 GET API
  const fetchReviewTagStatistic = async () => {
    setLoadingReviewStats(true);
    try {
      const response = await defaultInstance.get(
        `/domains/${food.domainId}/review-statistics`
      );
      console.log("리뷰 통계 데이터: ", response.data);
      setReviewStatistics(response.data);
    } catch (error) {
      //console.error("Error fetching Review Tag Statistic", error);
    } finally {
      setLoadingReviewStats(false);
    }
  };

  //모달창 정보 get api
  const fetchModalData = async () => {
    console.log(`food.domainId ->> /modal/foods/${food.domainId}`);
    setLoadingModalData(true);
    try {
      const response = await defaultInstance.get(
        `/modal/foods/${food.domainId}`
      );
      console.log("모달창 데이터: ", response.data);
      setModalData(response.data);
    } catch (error) {
      //("Error fetching fetchModalData", error);
    } finally {
      setLoadingModalData(false);
    }
  };

  const handleClickOpen = () => {
    fetchReviewTagStatistic();
    fetchModalData();

    setOpen(true);
  };
  const handleIngredientsLength = (food, userIngredients) => {
    return (food?.ingredients || []).map((item, i, row) => {
      const isUserIngredient = userIngredients.includes(item); // 유저의 재료인지 확인
      return (
        <IngredientItem key={i} isUserIngredient={isUserIngredient}>
          {i + 1 === row.length ? item : item + ",\u00a0"}
        </IngredientItem>
      );
    });
  };

  return (
    <>
      {searchLoading ? (
        <Loading />
      ) : (
        <CardWrapper onClick={handleClickOpen}>
          {
            /* 디테일 모달 */
            loadingReviewStats || loadingModalData ? null : (
              <FoodModal
                domain={domain}
                food={modalData}
                open={open}
                setOpen={setOpen}
                reviewStatistics={reviewStatistics}
                clickCount={clickCount}
                setClickCount={setClickCount}
              />
            )
          }
          <FoodImg src={food.imgLink} loading="lazy"></FoodImg>
          <FoodContent>
            <FoodTitle>
              <TextBox>
                <h1>{food.egName}</h1>
                <h2>{food.translatedName}</h2>
              </TextBox>
              <FavoriteBtn
                domainId={food.domainId}
                favoriteDomainId={food.favoriteDomainId}
                setClickCount={setClickCount}
                width="22px"
                mobileWidth="16px"
              />
            </FoodTitle>

            <FoodFeature>
              <FoodIngredient>
                <h1>Ingredient</h1>
                <h2>{handleIngredientsLength(food, food.userIngredients)}</h2>
              </FoodIngredient>
              <FoodKeyword>
                <h1>Keyword</h1>
                {food.keywords.map((item, i) => {
                  if (i === 2) return null;
                  const isUserKeyword = food.userKeywords.includes(item); // 유저의 키워드인지 확인
                  return (
                    <Keyword key={i} isUserKeyword={isUserKeyword}>
                      <h2>{"#" + item}</h2>
                    </Keyword>
                  );
                })}
              </FoodKeyword>
            </FoodFeature>
          </FoodContent>
        </CardWrapper>
      )}
    </>
  );
};

const IngredientItem = styled.span`
  background-color: ${(props) =>
    props.isUserIngredient ? "#dae6ff" : "transparent"}; // 배경색 변경
  color: ${(props) => (props.isUserIngredient ? "#000000" : "#000000")};
  margin-right: 2px;
  h2 {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 10px;
    }
    h2 {
      font-size: 9px;
    }
  }
`;

const TextBox = styled.div`
  width: 190px;
  h1 {
    color: #000;

    font-family: "Wanted Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 125% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    color: #000;

    /* Card Subtitle */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 140px;
    h1 {
      font-size: 15px;
      line-height: 20px; /* 125% */
    }
    h2 {
      font-size: 10px;
    }
  }
`;

const Keyword = styled.div`
  padding: 3px 10px;
  margin: 0 5px 5px 0;
  overflow: hidden;
  border-radius: 12.5px;
  background-color: ${(props) =>
    props.isUserKeyword ? "#FF7878" : "#9b9b9b"}; // 배경색 변경

  h2 {
    font-size: 12px;
    color: white;
  }
  @media (max-width: ${breakpoints.mobile}) {
    padding: 3px 8px;
    h2 {
      font-size: 8px;
    }
  }
`;
const CardWrapper = styled.div`
  box-sizing: content-box;
  width: 240px;
  height: 380px;
  background-color: #ffffff;
  overflow: hidden;
  margin: 0px 0px 35px 0px;
  padding: 8px;
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0px 3px 5px #d6d6d6;
    transition: 0.3s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 163px;
    height: 270px;
    margin: 0px 0px 0px 0px;
    padding: 6px 6px;
  }
`;

const FoodImg = styled.img`
  width: 240px;
  height: 240px;
  object-fit: cover;

  background-color: #e4e4e4;
  border-radius: 8px;
  position: relative;

  background-position: center;
  background-size: cover;
  @media (max-width: ${breakpoints.mobile}) {
    width: 162px;
    height: 162px;
  }
`;

const FoodTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 7px 0px 0;
  margin-bottom: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    height: 34px;
    padding: 0px;
  }
`;
const FoodFeature = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 100%;
  }
`;

const FoodIngredient = styled.div`
  width: 240px;
  display: flex;
  h1 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px 5px 0;
  }
  h2 {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 150px;
    h1 {
      font-size: 10px;
    }
    h2 {
      font-size: 10px;
    }
  }
`;
const FoodKeyword = styled.div`
  display: flex;
  flex-wrap: wrap;
  h1 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px 5px 0;
  }
  @media (max-width: ${breakpoints.mobile}) {
    h1 {
      font-size: 10px;
    }
  }
`;

const FoodContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  height: 135px;
  margin: 10px 10px;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 155px;
    height: fit-content;
    margin: 5px 0px;
  }
`;
