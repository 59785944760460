import React from "react";

import styled from "@emotion/styled";

import closeBtn from "../assets/icons/closeIcon.svg";
import { breakpoints } from "../styles/breakpoints";

const Policy = ({ setIsPolicyOpen }) => {
  return (
    <PolicyWrapper>
      <CloseButton onClick={() => setIsPolicyOpen(false)}>
        <img src={closeBtn} alt="close" />
      </CloseButton>

      <SectionTitle>Terms and Conditions</SectionTitle>
      <List>
        <ListItem>
          You may unsubscribe from Kori at any time. However, after
          unsubscribing, you will not be able to sign up again with the same
          email or social media account for 3 months.
        </ListItem>
        <ul>
          <SubListItem className="point">
            This policy is in place to prevent misuse of the service. After 3
            months, all related information will be permanently deleted.
          </SubListItem>
          <SubListItem className="point">
            Once your account has been properly deactivated, it cannot be
            recovered.
          </SubListItem>
        </ul>

        <ListItem>
          If you post reviews that are not related to food or places, the posts
          may be deleted without notice, and you will be informed afterward.
        </ListItem>
        <ListItem>
          Repeatedly posting reviews that are irrelevant to food or places may
          result in your account being revoked.
        </ListItem>
      </List>

      <SectionTitle>Privacy Policy</SectionTitle>
      <List>
        <ListItem>
          We only collect your email address, which will be used to send you
          updates about Kori if you agree to receive them.
        </ListItem>
        <ListItem>
          If you sign up via email, your password will be securely managed
          through encryption.
        </ListItem>
      </List>
    </PolicyWrapper>
  );
};

const SectionTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 13px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;

const List = styled.ol`
  width: 653px;
  padding-left: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
  list-style-type: decimal; /* 숫자 리스트 스타일 적용 */

  .point {
    list-style-type: circle;
  }
  ul {
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 305px;
  }
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 176.923% */
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;

    line-height: 15px; /* 176.923% */
  }
`;
const SubListItem = styled.li`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 176.923% */

  margin-left: 16px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;

    line-height: 15px; /* 176.923% */
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  filter: brightness(0) invert(1);
`;

const PolicyWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 727px;
  height: 500px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.6);

  backdrop-filter: blur(7px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 622px;

    border-radius: 5px;
  }
`;
export default Policy;
