import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./modules/index";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

//Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

// console.log(
//   "현재 빌드 환경: ",
//   process.env.REACT_APP_SPRING_ENV,
//   process.env.REACT_APP_API_URL
// );

// replace console.* for disable log on production
if (process.env.REACT_APP_SPRING_ENV === "production") {
  console = window.console || {};
  console.log = function no_console() {}; // console log 막기
  console.warn = function no_console() {}; // console warning 막기
  console.error = function () {}; // console error 막기
  console.clear();
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN, // .env 에 넣어준 dns 코드를 가져옵니다.
  environment: process.env.REACT_APP_SPRING_ENV, // sentry 에서 필터 환경을 설정합니다.
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      // route 가 이뤄질 때 추적합니다.
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(), // replay 를 이용할 수 있습니다. (사용자 추적)
  ],

  tracesSampleRate: 0.6, // default 는 1이나 너무 민감하므로 실제는 0.6 정도로 낮춥니다.

  tracePropagationTargets: [
    // sentry 를 사용할 도메인 타겟입니다.
    "localhost",
    process.env.REACT_APP_API_URL,
  ],

  replaysSessionSampleRate: 0.1, // 리플레이를 샘플링할 비율을 설정합니다. 0.1은 100% 중 10% 정도 기록합니다.
  replaysOnErrorSampleRate: 1.0, // 오류가 발생했을 때 세션 리플레이를 기록할 확률을 설정합니다.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
const persistor = persistStore(store);

root.render(
  <QueryClientProvider client={queryClient}>
    {/* devtools 
    
    <ReactQueryDevtools initialIsOpen={true} />
    */}
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);

export default store;
