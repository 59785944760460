import React, { useState } from "react";
import styled from "@emotion/styled";

import { DefaultFoodCard } from "./DefaultFoodCard";

import moreIcon from "../../assets/icons/more_arrow.svg";
import RestaurantIcon from "../../assets/icons/food-type-icon.svg";

import { defaultInstance } from "../../shared/Request";
import Loading from "../../components/Loading";
import { breakpoints } from "../../styles/breakpoints";
import { convertToId } from "../../utils/convertToId";

export const CardContainer = ({ domain, domainType }) => {
  const [foodTypeData, setFoodTypeData] = useState(domainType);
  const [loading, setLoading] = useState(false);

  const fetchDetailType = async () => {
    setLoading(true);
    try {
      const response = await defaultInstance.get(
        `/default/${domain}/${domainType.apiPath}`
      );

      setFoodTypeData(response.data.defaultResult[0]);
      setLoading(false);
    } catch (error) {
      //("Error fetching food Detail Type", error);
    }
  };

  return (
    <Wrapper>
      <FoodTypeTitle id={convertToId(domainType.detailType)}>
        <FoodTypeText>
          <img src={RestaurantIcon} alt="Restaurant Icon" />
          <p>{domainType.detailType}</p>
        </FoodTypeText>
        <MoreBtn onClick={fetchDetailType}>
          <p>more</p>
          <img src={moreIcon} alt="more Icon" />
        </MoreBtn>
      </FoodTypeTitle>
      {loading ? (
        <Loading />
      ) : (
        <FoodTypeDiv>
          {foodTypeData.domainList.map((iitem) => {
            return (
              <DefaultFoodCard
                domain={domain}
                key={iitem.domainId}
                food={iitem}
              />
            );
          })}
        </FoodTypeDiv>
      )}
      <Divider />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  background-color: #ffffff;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;
const FoodTypeTitle = styled.div`
  width: 100%;
  margin: 30px 0px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    margin: 20px 0px 0px 0px;
  }
`;
const MoreBtn = styled.div`
  background-color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 40px;
  p {
    color: #adadad;
    font-size: 15px;
  }
  img {
    margin-left: 5px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-right: 10px;
    p {
      font-size: 12px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 auto 0px auto;
  border-top: 1px solid black;
  opacity: 0.2;
`;

const FoodTypeText = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    p {
      font-size: 15px;
    }
  }
`;
const FoodTypeDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
  }
`;
