import React, { useState } from "react";
import styled from "@emotion/styled";
import { authInstance } from "../shared/Request";
import { useQuery } from "react-query";
import { fetchTodayFoods } from "../apis/mainApi";
import { ThumUp } from "./icons/ThumUp";

export const ThumUpBtn = ({ review, ReaderThumbUpId }) => {
  const [thumbUpId, setThumbUpId] = useState(ReaderThumbUpId);

  const [clickCount, setClickCount] = useState(
    review.reviewContent.thumbUpCount
  );
  const { refetch } = useQuery("todaysFood", fetchTodayFoods);

  const handleFavoriteClick = async (event) => {
    console.log(thumbUpId);
    try {
      if (thumbUpId) {
        await deleteReviewThumb();
      } else {
        await postReviewThumb();
      }
      refetch();
    } catch (error) {
      //console.error("Error handling favorite click:", error);
    }
  };

  const postReviewThumb = async () => {
    const data = {
      domainReviewId: review.reviewContent.domainReviewId,
    };
    try {
      const response = await authInstance.post(`/member/review-thumbs`, data);
      console.log("Thum Up 전달완료");
      console.log(response.data);
      setClickCount(response.data.thumbUpCount);
      setThumbUpId(response.data.thumbUpId);
    } catch (error) {
      alert("Login is required.");
      //console.error("Error sending Favorite Food to server:", error);
    }
  };

  const deleteReviewThumb = async () => {
    console.log(thumbUpId);

    try {
      const response = await authInstance.delete(
        `/member/review-thumbs/${thumbUpId}`
      );
      console.log("Thum Up 삭제완료");
      console.log(response.data);
      setClickCount(response.data.thumbUpCount);
      setThumbUpId(0);
    } catch (error) {
      //console.error("Error sending Delete Favorite Food to server:", error);
    }
  };

  return (
    <>
      {thumbUpId === 0 ? (
        <ThumbUpContainer
          backgroundColor="#ffffff"
          onClick={handleFavoriteClick}
        >
          <ThumUp color="#8397D3" />
          <ThumUpCount color="#8397D3">{clickCount}</ThumUpCount>
        </ThumbUpContainer>
      ) : (
        <ThumbUpContainer
          backgroundColor="#8397D3"
          onClick={handleFavoriteClick}
        >
          <ThumUp color="#ffffff" />
          <ThumUpCount color="#ffffff">{clickCount}</ThumUpCount>
        </ThumbUpContainer>
      )}
    </>
  );
};

const ThumUpCount = styled.span`
  color: ${(props) => props.color || "#000000"};
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-weight: 600;
`;
const ThumbUpContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;

  display: flex;
  width: fit-content;
  padding: 5px 8px;
  justify-content: center;
  align-items: end;
  border-radius: 2px;
  gap: 4px;
  background-color: ${(props) => props.backgroundColor || "#ffffff"};

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;
