import { authInstance, defaultInstance } from "../shared/Request";
import * as Sentry from "@sentry/react";

//디폴트 domain data
export const fetchDefaultDomains = async (domain) => {
  try {
    const response = await defaultInstance.get(`/default/${domain}`);
    console.log("fetch Default Domains success: ", response);
    return response.data.defaultResult;
  } catch (error) {
    // Handle other errors
    //console.error("fetch Default Domains error: ", error);
    Sentry.captureException(error);
    return {
      success: false,
      message: "Error fetching Default Domains data",
      error: error.message || "Unknown error occurred",
    };
  }
};

//음식 재료 종류
export const fetchIngredients = async (type) => {
  return await defaultInstance
    .get(`/features/${type}`)
    .then((res) => res.data.ingredients);
};

//음식,장소 태그 종류
export const fetchKeywords = async (type) => {
  try {
    const response = await defaultInstance.get(`/features/${type}`);
    //console.log("fetch keywords success: ", response);
    return response.data.keywords;
  } catch (error) {
    Sentry.captureException(error);
    //console.error("fetch keywords error: ", error);

    return {
      success: false,
      message: "Error fetching keywords data",
      error: error.message || "Unknown error occurred",
    };
  }
};

//음식,장소 태그 종류
export const fetchPlaceKeywords = async (type) => {
  try {
    const response = await defaultInstance.get(`/features/${type}`);
    //console.log("fetch place keywords success: ", response.data);
    return response.data.placeTypes;
  } catch (error) {
    Sentry.captureException(error);
    //console.error("fetch place keywords error: ", error);

    return {
      success: false,
      message: "Error fetching keywords data",
      error: error.message || "Unknown error occurred",
    };
  }
};

export const fetchFavoriteFoods = async (type) => {
  return await authInstance
    .get(`/member/favorites?type=${type}`)
    .then((res) => res.data.favoriteDomains);
};

export const fetchSearchByName = async (searchTerm, domainParam) => {
  try {
    const response = await defaultInstance.get(
      `/search/name/${domainParam}?query=${searchTerm}`
    );
    //console.log("fetch Search By Name success: ", response.data);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    //console.error("fetch Search By Name error: ", error);

    return {
      success: false,
      message: "Error fetching Search By Name data",
      error: error.message || "Unknown error occurred",
    };
  }
};
