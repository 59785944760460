import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import HeaderDiv from "../../../components/HeaderDiv";

const SocialSignUpCallback = () => {
  const urlParams = new URL(window.location.href).searchParams;
  const code = urlParams.get("code");
  const navigate = useNavigate();

  console.log("code:", code);

  useEffect(() => {
    console.log("code:", code);
    navigate(`/auth/sign-up/${code}`);
  }, [navigate]);

  return (
    <>
      <HeaderDiv></HeaderDiv>
      <p>로그인 성공</p>
    </>
  );
};

export default SocialSignUpCallback;
