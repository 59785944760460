import React, { useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { Global, css } from "@emotion/react";
import diceIcon from "../../../assets/icons/diceIcon.svg";

import HeaderDiv from "../../../components/HeaderDiv";
import { useNavigate } from "react-router-dom";
import { authInstance } from "../../../shared/Request";
import { useDispatch } from "react-redux";
import { setNickname } from "../../../modules/user";
import { ProfileEditHeader } from "./ProfileEditHeader";
import { breakpoints } from "../../../styles/breakpoints";

export const NicknameEdit = () => {
  const { nickname } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSubmissive, setIsSubmissive] = useState(false);
  const [newNickname, setNewNickname] = useState("");
  const animalNames = [
    "Lion",
    "Tiger",
    "Bear",
    "Shark",
    "Eagle",
    "Wolf",
    "Fox",
    "Panda",
    "Hawk",
    "Leopard",
  ];

  const handleRandomNickname = (event) => {
    event.preventDefault();
    const randomAnimal =
      animalNames[Math.floor(Math.random() * animalNames.length)];
    const randomNumbers = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number

    setNewNickname(`${randomAnimal}${randomNumbers}`);
  };

  const onChange = (event) => {
    setIsSubmissive(false);
    setNewNickname(event.target.value);
    if (newNickname.length >= 1 && nickname !== newNickname) {
      setIsSubmissive(true);
    }
  };

  const handleFinishClick = async (e) => {
    e.preventDefault();
    if (nickname === newNickname) {
      alert("There are no changes.");
    } else {
      try {
        const response = await authInstance.patch(`/member/profile/nickname`, {
          nickname: newNickname,
        });
        console.log("닉네임 서버로 전송", response.data);
        dispatch(setNickname(response.data.nickname));
        navigate(-1);
      } catch (error) {
        //("Error sending Profile info to server:", error);
      }
    }
  };

  return (
    <div>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      <ProfileEditHeader headerTitle={"Edit nickname"} />
      <Wrapper>
        <LoginContainer>
          <NickNameContainer>
            <Title>Please enter a new nickname.</Title>
            <SignUpForm>
              <InputContainer>
                <Input
                  type="text"
                  label="Nickname"
                  value={newNickname}
                  placeholder={nickname}
                  required
                  onChange={onChange}
                  minLength={1}
                  maxLength={20}
                />
                <RandomBtn onClick={handleRandomNickname}>
                  <img src={diceIcon} alt="random-button" />
                </RandomBtn>

                <RequiredIndicator>*</RequiredIndicator>
              </InputContainer>
            </SignUpForm>
          </NickNameContainer>
          {isSubmissive ? (
            <SignUpBtn bg="#90A9F4" type="submit" onClick={handleFinishClick}>
              <BtnText>Finish</BtnText>
            </SignUpBtn>
          ) : (
            <SignUpBtn
              bg="#BDBDBD"
              type="submit"
              onClick={() => alert("There are no changes.")}
            >
              <BtnText>Save</BtnText>
            </SignUpBtn>
          )}
        </LoginContainer>
      </Wrapper>
    </div>
  );
};

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
    }
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 25px;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 16px;
  ::placeholder {
    color: #888;
    font-family: "Wanted Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:focus {
    outline: none;
    border-color: #ddd; /* Optional: to keep the border color the same */
  }
`;

const RequiredIndicator = styled.label`
  position: absolute;
  top: 15px;
  left: 15px;
  color: #ff2b2b;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-weight: 500;
`;
const SignUpForm = styled.form``;
const InputContainer = styled.div`
  position: relative;
  width: 440px;
  height: 70px;
  border-radius: 10px;
  background: #f3f5fb;
  border: none;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 50px;
  }
`;

const LoginContainer = styled.div`
  width: 530px;
  height: 300px;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 610px;
    background-color: none;

    justify-content: space-between;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  width: 95vw;
  height: 300px;
  border-radius: 10px;
  margin: 0 auto 200px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 610px;
    margin: 0 auto 0px auto;
    align-items: start;
    justify-content: center;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -32px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
    top: -24px;
  }
`;
const SignUpBtn = styled.button`
  display: inline-flex;
  padding: 6px 22.5px 6px 23.5px;
  justify-content: center;
  align-items: center;
  border-radius: 14.99px;
  border: none;
  background-color: ${(props) => props.bg};
  position: absolute;
  bottom: -72px;
  &:hover {
    opacity: 80%;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 50px;
    border-radius: 10px;
    bottom: 0px;
  }
`;

const BtnText = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;
const NickNameContainer = styled.div`
  display: flex;
  align-items: end;
  margin: 28px auto;
  position: relative;
`;
const RandomBtn = styled.button`
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0px;
  border: none;
  background: none;
  @media (max-width: ${breakpoints.mobile}) {
    width: 25px;
    img {
      width: 25px;
      height: 25px;
    }
  }
`;
