import React from "react";
import styled from "@emotion/styled";
import { breakpoints } from "../../../styles/breakpoints";
import { useNavigate } from "react-router-dom";

const CardSlide = ({ food }) => {
  const navigate = useNavigate();
  return (
    <CardContainer imgSrc={food.imgLink}>
      <DetailContainer>
        <DetailButton
          onClick={() => navigate(`/foods/${food.domainId}/reviews`)}
        >
          detail
        </DetailButton>
      </DetailContainer>
    </CardContainer>
  );
};

const DetailButton = styled.button`
  padding: 6px 14px;
  border-radius: 15px;
  border: 1px solid var(--white-blender-line, rgba(255, 255, 255, 0.5));
  background: var(--white-blender, rgba(255, 255, 255, 0.25));
  color: white;
  backdrop-filter: blur(2px);
  opacity: 1;
  cursor: pointer;
`;

const CardContainer = styled.div`
  width: 300px;
  height: 240px;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(${(props) => `${props.imgSrc}`}) lightgray -25px 0px / 115% 120% no-repeat;
  background-position: center;
  background-size: cover;
  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  @media (max-width: ${breakpoints.mobile}) {
    > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const DetailContainer = styled.div`
  display: flex;
  width: 300px;
  height: 55px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1.5px);
  position: absolute;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (max-width: ${breakpoints.mobile}) {
    /* 모바일에서는 기본적으로 보임 */
    opacity: 1;
    visibility: visible;
  }
`;

export default CardSlide;
