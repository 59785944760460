import React, { useEffect, useState } from "react";
import HeaderDiv from "../../components/HeaderDiv";
import { authInstance } from "../../shared/Request";
import styled from "@emotion/styled";

import { Route, Routes, useNavigate } from "react-router-dom";
import { ReviewCard } from "../../components/review/ReviewCard";
import Loading from "../../components/Loading";

import backArrowIcon from "../../assets/icons/back-arrow-black.svg";
import { UpdateReviewPopup } from "../../components/review/UpdateReviewPopup";
import bottomArrowIcon from "../../assets/icons/right-arrow-white.svg";
import DomainCustomSelect from "../../components/review/DomainCustomSelect";

export const FavoriteReviews = () => {
  const navigate = useNavigate();
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [metaData, setMetaData] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);

  const [sort, setSort] = useState("date");
  const [type, setType] = useState({ value: "all", label: "All" });

  useEffect(() => {
    getMemberReviews(0); // Start fetching reviews from page 0
  }, [sort, type]);

  const getMemberReviews = async (page) => {
    setLoadingReviews(true);
    const params = {
      page: page,
      type: type.value,
    };
    try {
      const response = await authInstance.get(`/member/review-thumbs`, {
        params,
      });
      console.log("Response:", response.data.domainReviewsOfMember);
      setReviews(response.data.domainReviewsOfMember);
      setMetaData(response.data.metaData);
    } catch (error) {
      //("Error get MemberReviews data:", error);
      // Handle error
    }
    setLoadingReviews(false);
  };

  const getNextPage = async () => {
    setLoadingReviews(true);
    const params = {
      page: currentPage,
      sort: sort,
      type: type.value,
    };
    try {
      const response = await authInstance.get(`/member/review-thumbs`, {
        params,
      });
      console.log("Response:", response.data.domainReviewsOfMember);
      setReviews((prevReviews) => [
        ...prevReviews,
        ...response.data.domainReviewsOfMember,
      ]);
      setMetaData(response.data.metaData);
    } catch (error) {
      //console.error("Error get MemberReviews data:", error);
      // Handle error
    }
    setLoadingReviews(false);
  };

  const handleNextPage = () => {
    if (!loadingReviews && metaData.hasNext) {
      // Capture current scroll position
      const scrollPosition = window.scrollY;

      // Update the current page and fetch more reviews
      setCurrentPage((prev) => prev + 1);

      getNextPage().then(() => {
        // Restore the scroll position after new reviews are appended
        window.scrollTo(0, scrollPosition);
      });
    }
  };

  return (
    <>
      <HeaderDiv></HeaderDiv>

      {loadingReviews && currentPage === 0 ? (
        <Loading />
      ) : (
        <div>
          <Routes>
            <Route path="update" element={<UpdateReviewPopup />} />
          </Routes>
          <Wrapper>
            <Header>
              <BackBtn onClick={() => navigate(-1)}>
                <img src={backArrowIcon} alt="backArrowIcon" />
              </BackBtn>
              <HeaderTitle>Liked Reviews</HeaderTitle>
              <BackBtn></BackBtn>
            </Header>
            <SortContainer>
              <DomainCustomSelect type={type} setType={setType} />
            </SortContainer>
            <ReviewContainer>
              {reviews.map((review, i) => (
                <ReviewCard
                  key={review.reviewContent.domainReviewId + i}
                  review={review}
                  setReviews={setReviews}
                  isMypage={true}
                />
              ))}
              <MoreBtn onClick={handleNextPage} disabled={loadingReviews}>
                {metaData && metaData.hasNext ? (
                  <>
                    <p>Show more</p>
                    <img src={bottomArrowIcon} alt="bottomArrowIcon" />
                  </>
                ) : (
                  <p>Last page</p>
                )}
              </MoreBtn>
            </ReviewContainer>
          </Wrapper>
        </div>
      )}
    </>
  );
};

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MoreBtn = styled.button`
  display: inline-flex;
  padding: 8px 35px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin: 10px 0;

  /* 버튼 쉐이드 */
  border-radius: 15px;
  background: var(--light-gray, #d3d3d3);
  &:hover {
    background: var(--main, #b7b7b7);
    transition: 0.2s;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  img {
    margin-top: 3px;
    rotate: 90deg;
  }
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
`;

const BackBtn = styled.div``;

const HeaderTitle = styled.h1`
  color: #000;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-weight: 700;
`;

const Wrapper = styled.div`
  width: 90vw;
  height: fit-content;
  border-radius: 20px;
  margin: 0 auto 20px auto;
  padding: 0 0px 50px 0px;
  background-color: #ffffff;
`;

const SortContainer = styled.div`
  width: 100%;
  padding: 13px 0 13px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 15px;
`;
