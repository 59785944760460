import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getNickname } from "./modules/user";

// ProtectedLayout: 인증된 사용자만 접근 가능
const ProtectedLayout = () => {
  const nickname = useSelector(getNickname); // 인증 상태를 가져옴

  // nickname이 없으면 로그인 페이지로 리다이렉트
  if (!nickname) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  // nickname이 있으면 자식 컴포넌트 렌더링
  return <Outlet />;
};

export default ProtectedLayout;
