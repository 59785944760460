import React, { useState } from "react";
import styled from "@emotion/styled";
import Loading from "../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";

import closeIcon from "../../assets/icons/closeIcon.svg";
import rigthIcon from "../../assets/icons/right-arrow-white.svg";
import Toast from "../notification-popups/Toast";
import { breakpoints } from "../../styles/breakpoints";

export const CompleteReview = ({ foodName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toastMessage, setToastMessage] = useState("Successfully submitted!");

  const { data } = location.state || {};

  const cutIndex = location.pathname.indexOf("/new");
  const newUrl = location.pathname.substring(0, cutIndex);

  const closeToast = () => {
    setToastMessage("");
  };

  return (
    <div>
      {data ? (
        <Container>
          {toastMessage && (
            <Toast message={toastMessage} onClose={closeToast} />
          )}
          <CloseBtn onClick={() => navigate(newUrl)}>
            <img src={closeIcon} alt="closeIcon" />
          </CloseBtn>
          <Title>The review has been submitted!</Title>
          <SubTitle>
            You’ve posted the {data.reviewCount} review for {data.egName}!
          </SubTitle>
          <ReviewContainer>
            {data.imgLink === "" ? null : (
              <Image>
                <img src={data.imgLink} alt="img" />
              </Image>
            )}
            <CommentContainer>
              <Comment>{data.comment}</Comment>
            </CommentContainer>
            <KeywordContainer>
              {data.memberSelectTags.map((keyword) => {
                return (
                  <KeywordTagContainer>
                    <Emoji>{keyword.emoji}</Emoji>
                    <Text>{keyword.tagValue}</Text>
                  </KeywordTagContainer>
                );
              })}
            </KeywordContainer>
          </ReviewContainer>
          <OtherReviewsBtn onClick={() => navigate(newUrl)}>
            <p>View other reviews for it</p>
            <img src={rigthIcon} alt="rigthIcon" />
          </OtherReviewsBtn>
        </Container>
      ) : (
        <Loading />
      )}
    </div>
  );
};
const KeywordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    flex-wrap: wrap;

    align-items: start;
  }
`;

const KeywordTagContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: start;
  padding: 5px 8px;
  margin: 5px 0px;
  border-radius: 3px;
  background: #e7e7e7;
  cursor: pointer;

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: ${breakpoints.mobile}) {
    max-width: 120px;
    padding: 5px 6px;
    margin: 0px 0px;
  }
`;
const Emoji = styled.div`
  margin-right: 2px;
`;
const Text = styled.div`
  color: var(--tag, #7a7a7a);
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CommentContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  height: fit-content;
  padding: 12px 0;
`;
const Comment = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal; /* Ensures text wraps to the next line */
  word-wrap: break-word; /* Breaks long words to fit within the container */
  word-break: break-all;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;

  @media (max-width: ${breakpoints.mobile}) {
  }
  img {
    width: 15px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 727px;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 54px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    box-sizing: border-box;
    width: 340px;
    height: fit-content;
    max-height: 810px;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-align: center;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;

const SubTitle = styled.h2`
  color: var(--main, #90a9f4);
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

const ReviewContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 0.5px solid var(--LLight-gray, #e7e7e7);
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  @media (max-width: ${breakpoints.mobile}) {
    height: fit-content;
  }
`;

const Image = styled.div`
  width: 178px;
  height: 124px;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 가로세로 비율을 유지하면서 꽉 채우기 */
  }
`;

const OtherReviewsBtn = styled.button`
  display: inline-flex;
  padding: 6px 13px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  background: var(--main, #90a9f4);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 3px;
  }
  &:hover {
    background-color: #7d98e8;
    transition: 0.2s;
  }
`;
