import React, { useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { Global, css } from "@emotion/react";

import backArrowIcon from "../../../assets/icons/back-arrow-black.svg";
import HeaderDiv from "../../../components/HeaderDiv";
import { useNavigate } from "react-router-dom";
import { authInstance } from "../../../shared/Request";
import { SignUpCountrySelector } from "../../../components/SignUpCountrySelector";
import { ProfileEditHeader } from "./ProfileEditHeader";
import { breakpoints } from "../../../styles/breakpoints";

export const NationalityEdit = () => {
  const { nationality } = useParams();
  const navigate = useNavigate();

  const [isSubmissive, setIsSubmissive] = useState(true);
  const [newCountry, setNewCountry] = useState(nationality);

  const handleFinishClick = async (e) => {
    e.preventDefault();
    console.log(newCountry);
    try {
      const response = await authInstance.patch(`/member/profile/nationality`, {
        nationality: newCountry.value,
      });
      console.log("닉네임 서버로 전송", response.data);
      navigate(-1);
    } catch (error) {
      //console.error("Error sending Profile info to server:", error);
    }
  };

  return (
    <div>
      <HeaderDiv />

      <Global styles={GlobalStyles} />

      <ProfileEditHeader headerTitle={"Edit Nationality"} />
      <Wrapper>
        <LoginContainer>
          <Title>Please select your nationality.</Title>

          <InputContainer>
            <SignUpCountrySelector
              country={newCountry}
              setCountry={setNewCountry}
            />
            <Label>※수정할 수 없는 경고문구</Label>
          </InputContainer>
        </LoginContainer>
        {isSubmissive ? (
          <SignUpBtn bg="#90A9F4" type="submit" onClick={handleFinishClick}>
            <BtnText>Finish</BtnText>
          </SignUpBtn>
        ) : (
          <SignUpBtn
            bg="#BDBDBD"
            type="submit"
            onClick={() => alert("There are no changes.")}
          >
            <BtnText>Finish</BtnText>
          </SignUpBtn>
        )}
      </Wrapper>
    </div>
  );
};

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
  @media (max-width: ${breakpoints.mobile}) {
    body {
      background-color: #fff;
      overflow: hidden;
    }
  }
`;
const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #f00;
  font-family: "Wanted Sans";
  font-size: 10px;
  font-weight: 500;
  @media (max-width: ${breakpoints.mobile}) {
    top: 6px;
    left: 10px;
  }
`;
const InputContainer = styled.div`
  position: relative;
  width: 440px;
  height: 70px;
  border-radius: 5px;
  background: #f3f5fb;
  padding: 16px 0 0 8px;
  margin-bottom: 10px;

  transition: 0.2s;
  &:hover {
    background-color: #e2e5ef;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 310px;
    height: 70px;
  }
`;

const LoginContainer = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 300px;
    height: 100px;
    background-color: none;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  width: 95vw;
  height: 300px;
  border-radius: 10px;
  margin: 0 auto 200px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  @media (max-width: ${breakpoints.mobile}) {
    width: 360px;
    height: 610px;
    margin: 0 auto 0px auto;
    align-items: start;
    justify-content: center;
  }
`;

const Title = styled.h1`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;
const SignUpBtn = styled.button`
  display: inline-flex;
  padding: 6px 22.5px 6px 23.5px;
  justify-content: center;
  align-items: center;
  border-radius: 14.99px;
  border: none;
  background-color: ${(props) => props.bg};
  position: absolute;
  bottom: -72px;
  &:hover {
    opacity: 80%;
    transition: 0.2s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    bottom: 150px;
  }
`;

const BtnText = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 17px;
  }
`;
