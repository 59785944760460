import React, { useEffect, useState } from "react";
import HeaderDiv from "../../components/HeaderDiv";
import { authInstance } from "../../shared/Request";
import styled from "@emotion/styled";
import { ProfileEditHeader } from "./profile-edit-components/ProfileEditHeader";
import { css, Global } from "@emotion/react";
import EmailToggle from "../../components/EmailToggle";
import { useMutation, useQuery } from "react-query";
import { getSetting, patchSubscribeSetting } from "../../apis/memberApi";
import Loading from "../../components/Loading";
import { breakpoints } from "../../styles/breakpoints";
import * as Sentry from "@sentry/react";

const Setting = () => {
  const [subscribe, setSubscribe] = useState(false); // Initialize with default value

  const {
    isLoading,
    isError,
    data: settingData,
    error,
  } = useQuery("setting", getSetting, {
    onError: (e) => {
      Sentry.captureException(error);
    },
  });

  // Update subscribe state when data is fetched
  useEffect(() => {
    if (settingData) {
      setSubscribe(settingData.subscribe); // Set subscribe value from fetched data
    }
  }, [settingData]);

  const mutation = useMutation((subscribe) => patchSubscribeSetting(subscribe));

  const handleSubscribeChange = (newSubscribeValue) => {
    setSubscribe(newSubscribeValue); // Update state first
    mutation.mutate(newSubscribeValue); // Then send the mutation request
  };

  return (
    <div>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <ProfileEditHeader headerTitle={"Setting"} />
          <SettingWrapper>
            <Category>
              <CategoryText>Receive updates via email</CategoryText>
              <EmailToggle
                isActive={subscribe}
                setIsActive={handleSubscribeChange}
              />
            </Category>
          </SettingWrapper>
        </div>
      )}
    </div>
  );
};
const CategoryText = styled.p`
  color: #000;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }
`;
const Category = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 84px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    height: 64px;
  }
`;
const SettingWrapper = styled.div`
  width: 1100px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.mobile}) {
    width: 340px;
  }
`;
const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;

export default Setting;
