import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import HeaderDiv from "../../components/HeaderDiv";

import { Global, css } from "@emotion/react";
import { breakpoints } from "../../styles/breakpoints";

import checkedIcon from "../../assets/icons/checkedBox.svg";
import notCheckedIcon from "../../assets/icons/not-checked-box.svg";
import backArrowIcon from "../../assets/icons/right-arrow-white.svg";
import { authInstance } from "../../shared/Request";
import { useDispatch } from "react-redux";
import { setNickname } from "../../modules/user";

export const AccountDelete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  console.log("dd");

  const handleAccountDelete = async () => {
    if (isChecked) {
      try {
        const response = await authInstance.delete(`/member`);
        console.log("멤버 탈퇴 완료");
        dispatch(setNickname(null));
        navigate("/");
      } catch (error) {
        //console.error("Error deleting member:", error);
      }
    } else {
      alert("Please agree to the terms and conditions.");
    }
  };
  return (
    <>
      <HeaderDiv />
      <Global styles={GlobalStyles} />
      <Wrapper>
        <BackHeader>
          <BackContainer>
            <BackBtn onClick={() => navigate(-1)}>
              <img src={backArrowIcon} alt="backArrowIcon" />
            </BackBtn>
            <HeaderTitle>Back</HeaderTitle>
          </BackContainer>
          <DoYouWantToLeave>
            Are you sure you want to delete your account?
          </DoYouWantToLeave>
        </BackHeader>
        <WithdrawalTermsContainer>
          <Title>Account delete policy</Title>
          <SubContainer>
            <Sub>※ Your account will be permanently deleted from our</Sub>
            <SubMargin>system after 3 months.</SubMargin>
            <Sub>※ You will not be able to sign up again with the same</Sub>
            <SubMargin>email or social media account for 3 months.</SubMargin>
            <Sub>※ Account recovery is not possible</Sub>
          </SubContainer>
          <Divider></Divider>
          <CheckBtnContainer onClick={() => setIsChecked((prev) => !prev)}>
            <CheckBtn>
              <img
                src={isChecked ? checkedIcon : notCheckedIcon}
                alt="checked"
              />
            </CheckBtn>
            <p>I agree</p>
          </CheckBtnContainer>
        </WithdrawalTermsContainer>
        <AccountDeleteBtn isChecked={isChecked} onClick={handleAccountDelete}>
          <p>Delete</p>
        </AccountDeleteBtn>
      </Wrapper>
    </>
  );
};
const SubContainer = styled.div`
  width: 350px;
  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 23px;
  }
`;
const CheckBtnContainer = styled.div`
  height: 17px;
  display: flex;
  align-items: start;
  justify-content: center;
  p {
    color: #fff;
    font-family: "Wanted Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2px;
  }
`;

const CheckBtn = styled.button``;
const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Sub = styled.h2`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 11px;
  }
`;
const SubMargin = styled.h2`
  margin-left: 17px;
  margin-bottom: 5px;
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 11px;
  }
`;

const WithdrawalTermsContainer = styled.div`
  width: 531px;
  height: 230px;
  border-radius: 5px;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 326px;
    height: 220px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 350px;
  border-bottom: 1px solid #fff;
  @media (max-width: ${breakpoints.mobile}) {
    width: 280px;
  }
`;

const GlobalStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: rgba(243, 245, 251, 1);
  }
`;

const DoYouWantToLeave = styled.h1`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;

    font-weight: 500;
  }
`;
const Wrapper = styled.div`
  width: 94vw;
  height: 538px;
  border-radius: 10px;
  background: var(--tag, #7a7a7a);
  margin: 20px auto 50px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 27px;

  @media (max-width: ${breakpoints.mobile}) {
    width: 94vw;
    height: 90vh;
    gap: 20px;
    justify-content: start;
    padding-top: 130px;
  }
`;
const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const BackHeader = styled.div`
  box-sizing: border-box;
  width: 531px;
  padding-left: 45px;
  @media (max-width: ${breakpoints.mobile}) {
    padding-left: 10px;
    width: 320px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    padding-left: 0px;
  }
`;

const AccountDeleteBtn = styled.button`
  display: inline-flex;
  padding: 6px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background-color: ${(props) => (props.isChecked ? "#ff7878" : "#D3D3D3")};
  p {
    color: #fff;
    text-align: center;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const BackBtn = styled.div`
  transform: rotate(180deg);
  img {
    width: 9px;
  }
`;

const HeaderTitle = styled.h1`
  color: #fff;
  font-family: "Wanted Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
