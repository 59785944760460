import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints } from "../../styles/breakpoints";

export const Keyword = ({ keyword, selectedKeywords, setSelectedKeywords }) => {
  const [isSelected, setIsSelected] = useState([]);

  useEffect(() => {
    setIsSelected(selectedKeywords.includes(keyword.tagId));
  }, []);

  const handleClickKeyword = () => {
    setSelectedKeywords((prevItems) => {
      const exists = prevItems.includes(keyword.tagId);
      if (exists) {
        // 이미 존재할 때 지우기
        setIsSelected((prevState) => !prevState);
        return prevItems.filter((i) => i !== keyword.tagId);
      } else {
        // 존재하지 않을 때 추가하기
        if (selectedKeywords.length >= 5) {
          alert("You can select up to five options.");
          return [...prevItems];
        } else {
          setIsSelected((prevState) => !prevState);
          return [...prevItems, keyword.tagId];
        }
      }
    });
  };

  return (
    <div>
      {isSelected ? (
        <KeywordContainer onClick={handleClickKeyword} bgColor="#90A9F4">
          <Emoji>{keyword.emoji}</Emoji>
          <Text color="#ffffff">
            <p>{keyword.tagValue}</p>
          </Text>
        </KeywordContainer>
      ) : (
        <KeywordContainer onClick={handleClickKeyword} bgColor="#ffffff">
          <Emoji>{keyword.emoji}</Emoji>
          <Text color="#000000">
            <p>{keyword.tagValue}</p>
          </Text>
        </KeywordContainer>
      )}
    </div>
  );
};

const KeywordContainer = styled.div`
  max-width: 110px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: start;
  padding: 5px 8px;
  margin: 5px 0px;
  border-radius: 3px;
  background: ${(props) => props.bgColor};
  cursor: pointer;

  /* 버튼 쉐이드 */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: ${breakpoints.mobile}) {
    max-width: 120px;
    padding: 5px 6px;
    margin: 5px 3px;
  }
`;
const Emoji = styled.div`
  margin-right: 2px;
`;
const Text = styled.div`
  p {
    color: ${(props) => props.color};

    /* tagbox */
    font-family: "Wanted Sans";
    font-size: 12px;
    font-weight: 500;
  }
`;
