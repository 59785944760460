import React from "react";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { breakpoints } from "../../styles/breakpoints";

export const FeatureCard = ({ featureName, featureDescription, imgSrc }) => {
  return (
    <FeatureCardWrapper imgSrc={imgSrc}>
      <FeatureName>{featureName}</FeatureName>
      <FeatureDescription>{featureDescription}</FeatureDescription>
      {featureName === "K-Food Guide" ? (
        <Link to="/k-food-guide">
          <TogoBtn>
            <p>to go</p>
          </TogoBtn>
        </Link>
      ) : (
        <Link to="/k-place-guide">
          <TogoBtn>
            <p>to go</p>
          </TogoBtn>
        </Link>
      )}
    </FeatureCardWrapper>
  );
};

const FeatureCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 351px;
  box-sizing: border-box;
  padding: 40px 30px 30px 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(${(props) => `${props.imgSrc}`}) lightgray 50% / cover no-repeat;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url(${(props) => `${props.imgSrc}`}) lightgray 50% / cover no-repeat;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    width: 300px;
    height: 144px;
    padding: 23px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TogoBtn = styled.button`
  padding: 8px 14px;
  border-radius: 14.99px;
  border: none;
  background: #fff;
  p {
    color: #000;
    text-align: center;

    /* button bold */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    background: #e2e2e2;
    transition: 0.3s;
  }
  @media (max-width: ${breakpoints.mobile}) {
    padding: 8px 10px;
    p {
      font-size: 12px;
    }
  }
`;
const FeatureDescription = styled.p`
  color: #fff;
  text-align: center;

  /* Card Subtitle */
  font-family: "Wanted Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 70px;
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 25px;
  }
`;
const FeatureName = styled.h2`
  color: #fff;
  text-align: center;

  /* Card Headline */
  font-family: "Wanted Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
