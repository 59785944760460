import React, { useState } from "react";
import styled from "@emotion/styled";

import { fetchKeywords, fetchPlaceKeywords } from "../../apis/foodApi";

/*       Icon        */
import Tooltip from "@mui/material/Tooltip";
import Loading from "../../components/Loading";
import { useQuery } from "react-query";

import leftIcon from "../../assets/icons/left_arrow_black.svg";
import foodtag from "../../assets/icons/foodtag.svg";
import { breakpoints } from "../../styles/breakpoints";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import { convertToId } from "../../utils/convertToId";

export const FoodFilter = ({ mobileFilterClick, setMobileFilterClick }) => {
  const TYPE = "places";
  const [checkedType, setCheckedType] = useState(null);

  //서버로 부터 features data 받아오기
  const { isLoading: keywordLoading, data } = useQuery("placeKeyword", () =>
    fetchPlaceKeywords(TYPE)
  );

  const handleOverlayClick = () => {
    setMobileFilterClick(false);
  };

  const handleFilterClick = (e) => {
    e.stopPropagation(); // 필터 자체를 클릭할 때는 오버레이 클릭 이벤트가 발생하지 않도록 함
  };

  const handleClickPlaceType = (placeType) => {
    setCheckedType(placeType);
    // 해당 textBox로 스크롤 이동
    const elementId = convertToId(placeType);
    const element = document.getElementById(elementId);

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = -100;
      window.scrollTo({
        top: elementPosition + offset,
        behavior: "smooth",
      });
    } else {
      console.warn(`Element with ID ${elementId} not found`);
    }
  };

  return (
    <>
      {keywordLoading ? (
        <Loading />
      ) : (
        <div>
          <Overlay
            mobileFilterClick={mobileFilterClick}
            onClick={handleOverlayClick}
          ></Overlay>
          <FilterContainer
            mobileFilterClick={mobileFilterClick}
            onClick={handleFilterClick}
          >
            <MobileHeader>
              <img
                onClick={() => setMobileFilterClick((prev) => !prev)}
                src={leftIcon}
                alt="back"
              />
              <HeaderDivider></HeaderDivider>
            </MobileHeader>

            {/* 장소 종류 이동 버튼 */}

            <FilterType>
              <img src={foodtag} alt="placetag" />
              <p>Place</p>
              <Tooltip title="Choose the place type for the place">
                <IconButton>
                  <HelpOutlineIcon color="action" sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </FilterType>
            <PlaceTypeConatiner>
              {data.map((placeType, i) => {
                return checkedType === placeType ? (
                  <CheckedPlaceTypeBox
                    key={placeType}
                    onClick={() => handleClickPlaceType(placeType)}
                  >
                    <p>{placeType}</p>
                  </CheckedPlaceTypeBox>
                ) : (
                  <PlaceTypeBox
                    key={placeType}
                    onClick={() => handleClickPlaceType(placeType)}
                  >
                    <p>{placeType}</p>
                  </PlaceTypeBox>
                );
              })}
            </PlaceTypeConatiner>
          </FilterContainer>
        </div>
      )}
    </>
  );
};

const PlaceTypeBox = styled.div`
  width: 233px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 11px;
  }
  .checked {
    border-radius: 5px;
    border: 1.5px solid var(--main, #90a9f4);
    background: #e3e8f8;
    mix-blend-mode: multiply;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }
`;

const CheckedPlaceTypeBox = styled.div`
  width: 233px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 11px;
  }

  border-radius: 5px;
  border: 1.5px solid var(--main, #90a9f4);
  background: #e3e8f8;
  mix-blend-mode: multiply;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
`;
const PlaceTypeConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;
const FilterType = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  img {
    margin: 0 7px 0 3px;
  }
  p {
    color: #000;
    font-family: "Wanted Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const MobileHeader = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    img {
      width: 12px;
    }
  }
`;

const HeaderDivider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 15px auto;
`;

const Overlay = styled.div`
  display: none;
  @media (max-width: ${breakpoints.mobile}) {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    display: ${(props) => (props.mobileFilterClick ? "block" : "none")};
    transition: 0.3s;
  }
`;

const FilterContainer = styled.div`
  box-sizing: border-box;
  width: 260px;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 20px 13px;
  border-radius: 10px;
  background: #fff;
  .container-divider {
    margin: 30px 0;
  }
  /* Sticky behavior */
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px; /* The offset from the top where the element should start sticking */
  z-index: 9; /* Ensures it stays on top of other content */

  @media (max-width: ${breakpoints.mobile}) {
    position: fixed;
    top: 80px;
    right: -30px;
    z-index: 99;
    border-radius: 0px;
    display: ${(props) => (props.mobileFilterClick ? "block" : "none")};

    /* 크기 제한 및 스크롤 활성화 */
    max-height: 77vh; /* top 값에 따른 높이 조정 */

    overflow-y: auto; /* 세로 스크롤만 필요할 경우 */
  }
`;

export default FoodFilter;
